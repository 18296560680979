this["Templates"] = this["Templates"] || {};

this["Templates"]["Bluetooth"] = this["Templates"]["Bluetooth"] || {};

this["Templates"]["ButtonGroup"] = this["Templates"]["ButtonGroup"] || {};

this["Templates"]["Calendar"] = this["Templates"]["Calendar"] || {};

this["Templates"]["ChartjsCustomTooltip"] = this["Templates"]["ChartjsCustomTooltip"] || {};

this["Templates"]["ChatWindow"] = this["Templates"]["ChatWindow"] || {};

this["Templates"]["CodeScanner"] = this["Templates"]["CodeScanner"] || {};

this["Templates"]["CorrectiveActions"] = this["Templates"]["CorrectiveActions"] || {};

this["Templates"]["DropDownList"] = this["Templates"]["DropDownList"] || {};

this["Templates"]["EmbeddedVideo"] = this["Templates"]["EmbeddedVideo"] || {};

this["Templates"]["FileInputWindow"] = this["Templates"]["FileInputWindow"] || {};

this["Templates"]["Files"] = this["Templates"]["Files"] || {};

this["Templates"]["FilterWindow"] = this["Templates"]["FilterWindow"] || {};

this["Templates"]["FormIssuePool"] = this["Templates"]["FormIssuePool"] || {};

this["Templates"]["FullscreenIssueViewer"] = this["Templates"]["FullscreenIssueViewer"] || {};

this["Templates"]["ImageViewer"] = this["Templates"]["ImageViewer"] || {};

this["Templates"]["IndividualViews"] = this["Templates"]["IndividualViews"] || {};

this["Templates"]["InfoWindow"] = this["Templates"]["InfoWindow"] || {};

this["Templates"]["InputWindow"] = this["Templates"]["InputWindow"] || {};

this["Templates"]["IssueInformationSidebar"] = this["Templates"]["IssueInformationSidebar"] || {};

this["Templates"]["IssueViewer"] = this["Templates"]["IssueViewer"] || {};

this["Templates"]["Menus"] = this["Templates"]["Menus"] || {};

this["Templates"]["Menus"]["ContextMenu"] = this["Templates"]["Menus"]["ContextMenu"] || {};

this["Templates"]["Navbar"] = this["Templates"]["Navbar"] || {};

this["Templates"]["Navbar"]["ExpandableNavigationIcons"] = this["Templates"]["Navbar"]["ExpandableNavigationIcons"] || {};

this["Templates"]["ParameterHistoryWindow"] = this["Templates"]["ParameterHistoryWindow"] || {};

this["Templates"]["Parameters"] = this["Templates"]["Parameters"] || {};

this["Templates"]["Parameters"]["Files"] = this["Templates"]["Parameters"]["Files"] || {};

this["Templates"]["PrintWindow"] = this["Templates"]["PrintWindow"] || {};

this["Templates"]["RecorditemEditor"] = this["Templates"]["RecorditemEditor"] || {};

this["Templates"]["Reports"] = this["Templates"]["Reports"] || {};

this["Templates"]["ResponsibilityPicker"] = this["Templates"]["ResponsibilityPicker"] || {};

this["Templates"]["Scale"] = this["Templates"]["Scale"] || {};

this["Templates"]["Selections"] = this["Templates"]["Selections"] || {};

this["Templates"]["Selections"]["IndividualData"] = this["Templates"]["Selections"]["IndividualData"] || {};

this["Templates"]["SelectionWindow"] = this["Templates"]["SelectionWindow"] || {};

this["Templates"]["SelectionWindow"]["Form"] = this["Templates"]["SelectionWindow"]["Form"] || {};

this["Templates"]["SelectionWindow"]["Scheduling"] = this["Templates"]["SelectionWindow"]["Scheduling"] || {};

this["Templates"]["Settings"] = this["Templates"]["Settings"] || {};

this["Templates"]["SyncCenter"] = this["Templates"]["SyncCenter"] || {};

this["Templates"]["SyncCenter"]["EntityType"] = this["Templates"]["SyncCenter"]["EntityType"] || {};

this["Templates"]["SyncCenter"]["SupportMail"] = this["Templates"]["SyncCenter"]["SupportMail"] || {};

this["Templates"]["Toast"] = this["Templates"]["Toast"] || {};

this["Templates"]["Tree"] = this["Templates"]["Tree"] || {};

this["Templates"]["UserPicker"] = this["Templates"]["UserPicker"] || {};

this["Templates"]["VoiceRecorder"] = this["Templates"]["VoiceRecorder"] || {};

this["Templates"]["About"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"about\">\r\n    <img src=\"./img/awenko360_Logo_2c.svg\" />\r\n    <h3>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"About.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":30}}}))
    + "</h3>\r\n\r\n    <p class=\"version\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"About.Version",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":47}}}))
    + ": "
    + alias3(((helper = (helper = lookupProperty(helpers,"VersionString") || (depth0 != null ? lookupProperty(depth0,"VersionString") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"VersionString","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":66}}}) : helper)))
    + "</p>\r\n\r\n    <p>Build: "
    + alias3(((helper = (helper = lookupProperty(helpers,"BuildNumber") || (depth0 != null ? lookupProperty(depth0,"BuildNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"BuildNumber","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":29}}}) : helper)))
    + "</p>\r\n\r\n    <p>Copyright &copy; 2010 - "
    + alias3(((helper = (helper = lookupProperty(helpers,"CurrentYear") || (depth0 != null ? lookupProperty(depth0,"CurrentYear") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"CurrentYear","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":46}}}) : helper)))
    + "</p>\r\n\r\n    <p class=\"address\">\r\n        <strong>AWENKO GmbH & Co. KG - Gesellschaft f&uuml;r anwendbare Konzepte</strong><br />\r\n        Brägeler Straße 98<br />\r\n        49393 Lohne (Oldenburg)\r\n    </p>\r\n\r\n    <h4 class=\"contact\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"About.Contact",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":48}}}))
    + "</h4>\r\n\r\n    <p>\r\n        Tel.: <a href=\"tel:"
    + alias3((lookupProperty(helpers,"FormatPhoneNumber")||(depth0 && lookupProperty(depth0,"FormatPhoneNumber"))||alias2).call(alias1,"+49 4442 808280",{"name":"FormatPhoneNumber","hash":{},"data":data,"loc":{"start":{"line":20,"column":27},"end":{"line":20,"column":66}}}))
    + "\">+49 4442 808280</a><br />\r\n        "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.awenko.de",null,{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":51}}}))
    + "<br />\r\n        <a href=\"mailto:support@awenko.de\">support@awenko.de</a>\r\n    </p>\r\n\r\n    <p>\r\n        "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.awenko.de/datenschutz/",(depth0 != null ? lookupProperty(depth0,"PrivacyLinkText") : depth0),{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":75}}}))
    + "\r\n        <br />\r\n        "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.awenko.de/kontakt/impressum/",(depth0 != null ? lookupProperty(depth0,"Imprint") : depth0),{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":73}}}))
    + "\r\n    </p>\r\n\r\n    <h3 class=\"attribution\">Attribution</h3>\r\n    <p>\r\n        Wir bedanken uns bei folgenden Entwicklern für das zur Verfügung stellen von Grafiken / Software, welche in dieser Anwendung genutzt werden:\r\n\r\n        <ul>\r\n            <li>\r\n                "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.flaticon.com/authors/gregor-colors/lineal","Lineal Icons",{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":101}}}))
    + "\r\n                by "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.flaticon.com/authors/gregor-cresnar","Gregor Cresnar",{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":38,"column":19},"end":{"line":38,"column":100}}}))
    + " under\r\n                "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://file000.flaticon.com/downloads/license/license.pdf","Flaticon License",{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":110}}}))
    + "\r\n            </li>\r\n            <li>\r\n                "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.flaticon.com/authors/special/lineal","Special Lineal",{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":97}}}))
    + " by "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://www.flaticon.com/authors/freepik","Freepik",{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":42,"column":101},"end":{"line":42,"column":168}}}))
    + " under\r\n                "
    + alias3((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,"https://file000.flaticon.com/downloads/license/license.pdf","Flaticon License",{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":110}}}))
    + "\r\n            </li>\r\n        </ul>\r\n    </p>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Bluetooth"]["AddBluetoothDevice"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material\" id=\"addBluetoothDevices\">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.AddNewDevice.Header",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":48}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n        <div class=\"search-devices\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.AddNewDevice.SearchingDevices",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":62}}}))
    + "\r\n        </div>\r\n        <div class=\"scan-indicant icon-loop2\"></div>\r\n\r\n        <ul class=\"available-devices\">\r\n            <li class=\"info no-devices hidden\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.AddNewDevice.NoDevicesFound",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":64}}}))
    + "\r\n            </li>\r\n\r\n            <li class=\"info error-finding-devices hidden\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.ErrorFindingDevices",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":56}}}))
    + "\r\n            </li>\r\n        </ul>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-abort\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":24,"column":89},"end":{"line":24,"column":110}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Bluetooth"]["BluetoothIsDisabled"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bluetooth-configuration\">\r\n    <p class=\"info\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.BluetoothDisabled.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":55}}}))
    + "\r\n    </p>\r\n\r\n    <p>\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.BluetoothDisabled.Text",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":51}}}))
    + "\r\n    </p>\r\n</div>";
},"useData":true});

this["Templates"]["Bluetooth"]["BluetoothPermissionMissing"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bluetooth-configuration\">\r\n    <p class=\"info\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.PermissionMissing.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":55}}}))
    + "\r\n    </p>\r\n\r\n    <p>\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.PermissionMissing.Text",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":51}}}))
    + "\r\n    </p>\r\n    <button class=\"btn btn-default\" id=\"grantLocationPermission\"><span class=\"icon-bluetooth\"></span> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.PermissionMissing.GrantPermission",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":102},"end":{"line":9,"column":156}}}))
    + "</button>\r\n</div>";
},"useData":true});

this["Templates"]["Bluetooth"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " animated";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"BluetoothConfigurationDevice"),depth0,{"name":"BluetoothConfigurationDevice","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":42,"column":23}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bluetooth-configuration\">\r\n    <p class=\"info\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":48}}}))
    + "\r\n    </p>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"TitleClasses":"col-xs-6","CssClasses":"automatic-reconnect-setting row","CbId":"cbBluetoothConfigurationAutomaticallyReconnect","LangKey":"Bluetooth.AutomaticReconnect.Description"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n    <div class=\"connected-devices-header\">\r\n        <h4>\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.ConnectedDevices",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":63}}}))
    + "\r\n        </h4>\r\n\r\n        <div class=\"scan-indicant icon-loop2"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ScanInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":44},"end":{"line":18,"column":82}}})) != null ? stack1 : "")
    + "\"></div>\r\n    </div>\r\n\r\n    <ul class=\"connected-devices\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ConnectedDevices") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":26,"column":17}}})) != null ? stack1 : "")
    + "        <li class=\"info no-devices "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DeviceConnected") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":35},"end":{"line":27,"column":72}}})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.NoConnectedDevicesAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":74}}}))
    + "\r\n        </li>\r\n    </ul>\r\n\r\n    <div class=\"known-devices-header\">\r\n        <h4>\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.AvailableDevices",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":63}}}))
    + "\r\n        </h4>\r\n    </div>\r\n\r\n    <ul class=\"known-devices\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"KnownDevices") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":43,"column":17}}})) != null ? stack1 : "")
    + "\r\n        <li class=\"info no-devices"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DisconnectedKnownDevices") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":34},"end":{"line":45,"column":80}}})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.NoPairedDevicesAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":46,"column":71}}}))
    + "\r\n        </li>\r\n\r\n        <li class=\"add-new-device row\">\r\n            <div class=\"button-wrapper col-xs-12\">\r\n                <button class=\"btn btn-default btn-add-new-device\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.AddNewDevice",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":52,"column":20},"end":{"line":52,"column":67}}}))
    + "\r\n                </button>\r\n            </div>\r\n        </li>\r\n    </ul>\r\n\r\n    <div class=\"test-device"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"DeviceConnected") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":27},"end":{"line":58,"column":72}}})) != null ? stack1 : "")
    + "\">\r\n        <h4>\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.TestDevice",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":60,"column":57}}}))
    + "\r\n        </h4>\r\n\r\n        <button class=\"btn btn-default btn-test-device"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MeasurementInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":54},"end":{"line":63,"column":97}}})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.StartMeasuring",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":64,"column":61}}}))
    + "\r\n        </button>\r\n\r\n        <button class=\"btn btn-default btn-cancel-test-device"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"MeasurementInProgress") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":61},"end":{"line":67,"column":112}}})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Configuration.StopMeasuring",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":68,"column":60}}}))
    + "\r\n        </button>\r\n\r\n        <ul class=\"test-measures\"></ul>\r\n    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["Bluetooth"]["Device"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " connected";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"battery\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Device.Battery",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":83}}}))
    + ": "
    + alias3(((helper = (helper = lookupProperty(helpers,"Battery") || (depth0 != null ? lookupProperty(depth0,"Battery") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Battery","hash":{},"data":data,"loc":{"start":{"line":5,"column":85},"end":{"line":5,"column":96}}}) : helper)))
    + "%</span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"device clearfix row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":66}}})) != null ? stack1 : "")
    + "\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":1,"column":77},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\r\n    <div class=\"information col-xs-8 col-md-9\">\r\n        <span class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":35}}}) : helper)))
    + "</span>\r\n        <span class=\"id\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":31}}}) : helper)))
    + "</span>\r\n        "
    + ((stack1 = (lookupProperty(helpers,"IsSet")||(depth0 && lookupProperty(depth0,"IsSet"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Battery") : depth0),{"name":"IsSet","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":114}}})) != null ? stack1 : "")
    + "\r\n    </div>\r\n\r\n    <div class=\"button-wrapper col-xs-4 col-md-3\">\r\n        <button class=\"btn btn-default btn-connect\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Device.Connect",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":47}}}))
    + "\r\n        </button>\r\n\r\n        <button class=\"btn btn-default btn-disconnect\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.Device.Disconnect",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":50}}}))
    + "\r\n        </button>\r\n    </div>\r\n</li>";
},"useData":true});

this["Templates"]["Bluetooth"]["DeviceEditor"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":40}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Bluetooth.DeviceEditor.NoTypeSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":96}}}));
},"9":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"11":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material bluetooth-device-editor\">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.DeviceEditor.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":47}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.DeviceEditor.Name",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":61}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <input type=\"text\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":35},"end":{"line":13,"column":80}}})) != null ? stack1 : "")
    + " data-id=\"name\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":13,"column":103},"end":{"line":13,"column":111}}}) : helper)))
    + "\" />\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.DeviceEditor.Type",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":23},"end":{"line":19,"column":61}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <button class=\"btn btn-default btn-select-type"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":62},"end":{"line":23,"column":97}}})) != null ? stack1 : "")
    + "\">\r\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":103}}})) != null ? stack1 : "")
    + "\r\n                </button>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <button class=\"btn btn-danger btn-remove-device"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":63},"end":{"line":31,"column":96}}})) != null ? stack1 : "")
    + "\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Bluetooth.DeviceEditor.RemoveDevice",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":66}}}))
    + "\r\n                </button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger pull-left\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":68},"end":{"line":39,"column":89}}}))
    + "</button>\r\n        <button type=\"button\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":37},"end":{"line":40,"column":70}}})) != null ? stack1 : "")
    + "btn flat btn-success\" disabled=\"disabled\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Save",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":40,"column":112},"end":{"line":40,"column":132}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Bluetooth"]["TestMeasure"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\r\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Value") || (depth0 != null ? lookupProperty(depth0,"Value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Value","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":13}}}) : helper)))
    + " °C\r\n</li>";
},"useData":true});

this["Templates"]["ButtonGroup"]["ButtonEntity"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-identifier=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":81}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Value") || (depth0 != null ? lookupProperty(depth0,"Value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Value","hash":{},"data":data,"loc":{"start":{"line":1,"column":133},"end":{"line":1,"column":142}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"color\" style=\"background-color: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Color") || (depth0 != null ? lookupProperty(depth0,"Color") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Color","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":62}}}) : helper)))
    + ";\"></span>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "         <span class=\"icon-lock\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn btn-default\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":89}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"IsNotNullOrUndefined")||(depth0 && lookupProperty(depth0,"IsNotNullOrUndefined"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"IsNotNullOrUndefined","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":89},"end":{"line":1,"column":168}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Color") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":15}}}) : helper))) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsLockedState") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</button>";
},"useData":true});

this["Templates"]["Calendar"]["Calendar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"calendar-wrapper\">\r\n    <div class=\"calendar-header clearfix\">\r\n        <div class=\"period col-xs-12 col-md-6\">\r\n            <i class=\"icon-arrow-left2 btn-previous-period\"></i>\r\n            <i class=\"icon-arrow-right2 btn-next-period\"></i>\r\n            <h4 class=\"date-range\"></h4>\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-md-6\">\r\n            <ul class=\"view-selection pull-right\">\r\n                <li class=\"item"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectedView") : depth0),"===","day",{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":81}}})) != null ? stack1 : "")
    + "\" data-view=\"day\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"DateTime.Day",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":99},"end":{"line":11,"column":122}}}))
    + "</li>\r\n                <li class=\"item"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectedView") : depth0),"===","week",{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":82}}})) != null ? stack1 : "")
    + "\" data-view=\"week\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"DateTime.Week",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":101},"end":{"line":12,"column":125}}}))
    + "</li>\r\n                <li class=\"item"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectedView") : depth0),"===","month",{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":83}}})) != null ? stack1 : "")
    + "\" data-view=\"month\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"DateTime.Month",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":103},"end":{"line":13,"column":128}}}))
    + "</li>\r\n            </ul>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"calendar\"></div>\r\n</div>";
},"useData":true});

this["Templates"]["Calendar"]["CurrentTimeLine"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"current-time-line\"></div>";
},"useData":true});

this["Templates"]["Calendar"]["DayView"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"hour clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Greyed") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":60}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"UserCanCreateTasks") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":117}}})) != null ? stack1 : "")
    + "\" data-timestamp=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":3,"column":135},"end":{"line":3,"column":148}}}) : helper)))
    + "\">\r\n            <div class=\"col-xs-3 col-lg-2\">\r\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"DisplayValue") || (depth0 != null ? lookupProperty(depth0,"DisplayValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DisplayValue","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":32}}}) : helper)))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-9 col-lg-10 issues\">\r\n                <ul class=\"issues\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n            </div>\r\n        </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " faded";
},"4":function(container,depth0,helpers,partials,data) {
    return " enable-task-creation";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"CalendarDayViewIssueItem"),depth0,{"name":"CalendarDayViewIssueItem","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"day-view\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Timestamps") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Calendar"]["DayViewIssueItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"UnescapeHTMLEntities")||(depth0 && lookupProperty(depth0,"UnescapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"UnescapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":55}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"IssueTypeAbbreviation")||(depth0 && lookupProperty(depth0,"IssueTypeAbbreviation"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"IssueTypeAbbreviation","hash":{},"data":data,"loc":{"start":{"line":5,"column":63},"end":{"line":5,"column":93}}}))
    + alias3(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":5,"column":93},"end":{"line":5,"column":99}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"issue\" data-identifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Identifier") || (depth0 != null ? lookupProperty(depth0,"Identifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Identifier","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":49}}}) : helper)))
    + "\" data-timestamp=\""
    + alias4((lookupProperty(helpers,"DateTimestamp")||(depth0 && lookupProperty(depth0,"DateTimestamp"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"DateTimestamp","hash":{},"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":102}}}))
    + "\">\r\n    <a href=\"#issue/"
    + alias4(((helper = (helper = lookupProperty(helpers,"Identifier") || (depth0 != null ? lookupProperty(depth0,"Identifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Identifier","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":34}}}) : helper)))
    + "?type="
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":48}}}) : helper)))
    + "\" class=\"link\">\r\n        <span class=\"issue-id\">"
    + alias4((lookupProperty(helpers,"IssueTypeAbbreviation")||(depth0 && lookupProperty(depth0,"IssueTypeAbbreviation"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"IssueTypeAbbreviation","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":61}}}))
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":67}}}) : helper)))
    + "</span>\r\n        <span class=\"issue-title\">\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":106}}})) != null ? stack1 : "")
    + "\r\n        </span>\r\n    </a>\r\n</li>";
},"useData":true});

this["Templates"]["Calendar"]["MonthView"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <thead>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Headers") : depth0)) != null ? lookupProperty(stack1,"short") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Headers") : depth0)) != null ? lookupProperty(stack1,"long") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "        </thead>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr class=\"screen-max-sm\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Headers") : depth0)) != null ? lookupProperty(stack1,"short") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":17,"column":29}}})) != null ? stack1 : "")
    + "                </tr>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <th"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"classes") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":27},"end":{"line":16,"column":69}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":16,"column":70},"end":{"line":16,"column":78}}}) : helper)))
    + "</th>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":16,"column":50},"end":{"line":16,"column":61}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr class=\"screen-min-md\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Headers") : depth0)) != null ? lookupProperty(stack1,"long") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":25,"column":29}}})) != null ? stack1 : "")
    + "                </tr>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":47,"column":25}}})) != null ? stack1 : "")
    + "            </tr>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <td"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Classes") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":23},"end":{"line":34,"column":65}}})) != null ? stack1 : "")
    + " data-timestamp=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":34,"column":82},"end":{"line":34,"column":95}}}) : helper)))
    + "\">\r\n                        <span class=\"day-of-month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DayOfMonth") || (depth0 != null ? lookupProperty(depth0,"DayOfMonth") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DayOfMonth","hash":{},"data":data,"loc":{"start":{"line":35,"column":51},"end":{"line":35,"column":65}}}) : helper)))
    + "</span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueCounter") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":45,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Classes") || (depth0 != null ? lookupProperty(depth0,"Classes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Classes","hash":{},"data":data,"loc":{"start":{"line":34,"column":46},"end":{"line":34,"column":57}}}) : helper)))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"counters\">\r\n                                <div class=\"counter-item\">\r\n                                    <span class=\"text\">\r\n                                        "
    + alias3(((helper = (helper = lookupProperty(helpers,"IssueCounter") || (depth0 != null ? lookupProperty(depth0,"IssueCounter") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"IssueCounter","hash":{},"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":56}}}) : helper)))
    + " "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"count":(depth0 != null ? lookupProperty(depth0,"IssueCounter") : depth0),"Key":"Misc.Issue"},"data":data,"loc":{"start":{"line":41,"column":57},"end":{"line":41,"column":101}}}))
    + "\r\n                                    </span>\r\n                                </div>\r\n                            </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table\">\r\n    <colgroup>\r\n        <col style=\"width:14.28%;\">\r\n        <col style=\"width:14.28%;\">\r\n        <col style=\"width:14.28%;\">\r\n        <col style=\"width:14.28%;\">\r\n        <col style=\"width:14.28%;\">\r\n        <col style=\"width:14.28%;\">\r\n        <col style=\"width:14.28%;\">\r\n    </colgroup>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Headers") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "    <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Rows") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":49,"column":17}}})) != null ? stack1 : "")
    + "    </tbody>\r\n</table>";
},"useData":true});

this["Templates"]["Calendar"]["WeekView"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsToday") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":111}}})) != null ? stack1 : "")
    + " data-timestamp=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"DayTimestamp") || (depth0 != null ? lookupProperty(depth0,"DayTimestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DayTimestamp","hash":{},"data":data,"loc":{"start":{"line":7,"column":128},"end":{"line":7,"column":144}}}) : helper)))
    + "\">\r\n                        <span class=\"day-of-week-abbr\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DayOfWeekAbbr") || (depth0 != null ? lookupProperty(depth0,"DayOfWeekAbbr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DayOfWeekAbbr","hash":{},"data":data,"loc":{"start":{"line":8,"column":55},"end":{"line":8,"column":72}}}) : helper)))
    + "</span>\r\n                        <span class=\"day-of-month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DayOfMonth") || (depth0 != null ? lookupProperty(depth0,"DayOfMonth") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DayOfMonth","hash":{},"data":data,"loc":{"start":{"line":9,"column":51},"end":{"line":9,"column":65}}}) : helper)))
    + "</span>\r\n                    </th>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " class=\"is-today\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsSunday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":104}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"is-sunday\"";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\r\n                    <td>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"FormattedTime") || (depth0 != null ? lookupProperty(depth0,"FormattedTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"FormattedTime","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":41}}}) : helper)))
    + "</td>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Days") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":27,"column":29}}})) != null ? stack1 : "")
    + "                </tr>\r\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td class=\"hour"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"UserCanCreateTasks") : depths[2]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":99}}})) != null ? stack1 : "")
    + "\" data-timestamp=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":20,"column":117},"end":{"line":20,"column":130}}}) : helper)))
    + "\">\r\n                            <ul class=\"issues\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":24,"column":41}}})) != null ? stack1 : "")
    + "                            </ul>\r\n                        </td>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " enable-task-creation";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"CalendarDayViewIssueItem"),depth0,{"name":"CalendarDayViewIssueItem","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"week-view\">\r\n    <table class=\"table\">\r\n        <thead>\r\n            <tr>\r\n                <th></th>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Headlines") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":11,"column":25}}})) != null ? stack1 : "")
    + "            </tr>\r\n        </thead>\r\n        <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Hours") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":29,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\r\n    </table>\r\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["ChartjsCustomTooltip"]["TimestampRow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":30}}}) : helper)))
    + " combined-icon\">\r\n                <i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"innerIcon") || (depth0 != null ? lookupProperty(depth0,"innerIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"innerIcon","hash":{},"data":data,"loc":{"start":{"line":5,"column":26},"end":{"line":5,"column":39}}}) : helper)))
    + "\"></i>\r\n            </i>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":30}}}) : helper)))
    + "\"></i>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\r\n    <th>\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsNotNullOrUndefined")||(depth0 && lookupProperty(depth0,"IsNotNullOrUndefined"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"innerIcon") : depth0),{"name":"IsNotNullOrUndefined","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":33}}})) != null ? stack1 : "")
    + "        "
    + alias4(((helper = (helper = lookupProperty(helpers,"timestamp") || (depth0 != null ? lookupProperty(depth0,"timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":21}}}) : helper)))
    + " • "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":33}}}) : helper)))
    + "\r\n    </th>\r\n</tr>";
},"useData":true});

this["Templates"]["ChatWindow"]["EditableMessage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li data-identifier=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":32}}}) : helper)))
    + "\" class=\"message-wrapper"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsOwnComment") : depths[1]),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":56},"end":{"line":2,"column":105}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"outer-wrapper\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RepeatedUser") : depth0),(depths[1] != null ? lookupProperty(depths[1],"IsOwnComment") : depths[1]),{"name":"NOr","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":8,"column":20}}})) != null ? stack1 : "")
    + "\r\n            <div class=\"message editor-open"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RepeatedUser") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":43},"end":{"line":10,"column":85}}})) != null ? stack1 : "")
    + "\">\r\n                <div class=\"content input-wrapper\">\r\n                    <div class=\"inner-wrapper\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"AllowModification") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":17,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n\r\n                    <div class=\"toolbar\">\r\n                        <div class=\"btn-cancel\">\r\n                            <span class=\"icon-cancel-circle\"></span>\r\n                        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"AllowDeletion") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":31,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"AllowModification") : depths[1]),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":39,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " other-user";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"user-image\">\r\n                    <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"UserImage")||(depth0 && lookupProperty(depth0,"UserImage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserImage","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":54}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " without-symbol";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <textarea class=\"message-input\" placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ChatWindow.Input.Placeholder",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":73},"end":{"line":14,"column":112}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"UnescapeHTMLEntities")||(depth0 && lookupProperty(depth0,"UnescapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"UnescapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":14,"column":114},"end":{"line":14,"column":143}}}))
    + "</textarea>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Text") || (depth0 != null ? lookupProperty(depth0,"Text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Text","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"flex-dummy\"></div>\r\n\r\n                            <div class=\"btn-delete\">\r\n                                <span class=\"icon-bin2\"></span>\r\n                            </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"flex-dummy\"></div>\r\n\r\n                            <div class=\"btn-send\">\r\n                                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":37,"column":32},"end":{"line":37,"column":52}}}))
    + "\r\n                            </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Message") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":45,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});

this["Templates"]["ChatWindow"]["InfoMessage"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"message-wrapper info-message\">\r\n    <div class=\"message without-symbol\">\r\n        <div class=\"content\">\r\n            <p class=\"text\">\r\n                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ChatWindow.NoMessagesAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":57}}}))
    + "\r\n            </p>\r\n        </div>\r\n    </div>\r\n</li>";
},"useData":true});

this["Templates"]["ChatWindow"]["Message"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " other-user";
},"3":function(container,depth0,helpers,partials,data) {
    return " consecutive-message";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"user-image\">\r\n                <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"UserImage")||(depth0 && lookupProperty(depth0,"UserImage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserImage","hash":{},"data":data,"loc":{"start":{"line":5,"column":26},"end":{"line":5,"column":50}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n            </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " editable";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RepeatedUser") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":28,"column":31}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"user-header\">\r\n                            <div class=\"title-and-text\">\r\n                                <p class=\"user-title\">\r\n                                    "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),"!==",((stack1 = (depth0 != null ? lookupProperty(depth0,"Editor") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"Test","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":36},"end":{"line":20,"column":45}}})) != null ? stack1 : "")
    + "                                </p>\r\n\r\n                                <p class=\"text\">"
    + alias1((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":75}}}))
    + "</p>\r\n                            </div>\r\n                        </div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        &nbsp;<i>("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Editor") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + ")</i>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":27,"column":67}}}))
    + "</p>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":63}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-identifier=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":28}}}) : helper)))
    + "\" class=\"message-wrapper"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ByCurrentUser") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":99}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RepeatedUser") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":146}}})) != null ? stack1 : "")
    + "\">\r\n    <div class=\"outer-wrapper\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RepeatedUser") : depth0),(depth0 != null ? lookupProperty(depth0,"ByCurrentUser") : depth0),{"name":"NOr","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + "\r\n        <div class=\"message"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsEditable") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":61}}})) != null ? stack1 : "")
    + "\">\r\n            <div class=\"content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ByCurrentUser") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <p class=\"timestamp\">"
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":36,"column":25},"end":{"line":36,"column":57}}}))
    + "</p>\r\n</li>\r\n";
},"useData":true});

this["Templates"]["ChatWindow"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ChatWindowMessage"),depth0,{"name":"ChatWindowMessage","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"info-message\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"ChatWindowInfoMessage"),depth0,{"name":"ChatWindowInfoMessage","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </li>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-wrapper new-message\">\r\n            <div class=\"inner-wrapper\">\r\n                <textarea class=\"message-input form-control\" rows=\"3\" placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ChatWindow.Input.Placeholder",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":83},"end":{"line":21,"column":122}}}))
    + "\"></textarea>\r\n            </div>\r\n\r\n            <div class=\"btn-send hidden\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":36}}}))
    + "\r\n            </div>\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"fullscreen\" id=\"chat-window\">\r\n    <div class=\"window-header\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"ChatWindowHeaderContent"),depth0,{"name":"ChatWindowHeaderContent","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"content-wrapper\">\r\n        <ul class=\"chat-history\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Messages") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":14,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn btn-default btn-close flat\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":31,"column":69},"end":{"line":31,"column":90}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["ChatWindow"]["WindowHeaderContent"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h5>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"EntityInfo") || (depth0 != null ? lookupProperty(depth0,"EntityInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EntityInfo","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":22}}}) : helper)))
    + "</h5>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"ChatWindow.Header.Context",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":40}}}))
    + "</h4>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityInfo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["ClearableInput"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"input-group-addon presentation"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultPresentation") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":51},"end":{"line":3,"column":114}}})) != null ? stack1 : "")
    + "\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Searchfield.SearchResultSwitch",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":123},"end":{"line":3,"column":164}}}))
    + "\"></span>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " icon-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DefaultPresentation") || (depth0 != null ? lookupProperty(depth0,"DefaultPresentation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DefaultPresentation","hash":{},"data":data,"loc":{"start":{"line":3,"column":84},"end":{"line":3,"column":107}}}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"input-group-addon filter icon-filter\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Searchfield.SearchFilter",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":66},"end":{"line":7,"column":101}}}))
    + "\"></span>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Placeholder") || (depth0 != null ? lookupProperty(depth0,"Placeholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Placeholder","hash":{},"data":data,"loc":{"start":{"line":10,"column":92},"end":{"line":10,"column":107}}}) : helper)))
    + "\"";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"input-group-addon icon-clear\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Searchfield.Reset",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":58},"end":{"line":13,"column":86}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"clearable-input input-group\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowPresentationButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowFilterButton") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <input type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":10,"column":17},"end":{"line":10,"column":25}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":37}}}) : helper)))
    + "\" class=\"form-control\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Placeholder") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":59},"end":{"line":10,"column":115}}})) != null ? stack1 : "")
    + " />\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowClearButton") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});

this["Templates"]["CodeScanner"]["Content"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"code-scanner-configuration\">\r\n    <div class=\"test-device\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CodeScanner.Trial.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":48}}}))
    + "<br />\r\n        <br />\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CodeScanner.Trial.ScanType",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":45}}}))
    + ": "
    + alias3(((helper = (helper = lookupProperty(helpers,"ScanType") || (depth0 != null ? lookupProperty(depth0,"ScanType") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ScanType","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":59}}}) : helper)))
    + "<br />\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CodeScanner.Trial.Value",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":42}}}))
    + ": "
    + alias3(((helper = (helper = lookupProperty(helpers,"ScanValue") || (depth0 != null ? lookupProperty(depth0,"ScanValue") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ScanValue","hash":{},"data":data,"loc":{"start":{"line":6,"column":44},"end":{"line":6,"column":57}}}) : helper)))
    + "\r\n    </div>\r\n\r\n    <div class=\"configuration\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Configuration") || (depth0 != null ? lookupProperty(depth0,"Configuration") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"Configuration","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":27}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["CodeScanner"]["Newland"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "attention";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <li>"
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel collapsable panel-default collapsed\">\r\n    <div class=\"panel-heading "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ProfileConfigured") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":79}}})) != null ? stack1 : "")
    + "\">\r\n        <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Newland.ConfigurationHeader",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":65}}}))
    + "</span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n        <ol>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Newland.ConfigurationContent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":57}}}),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </ol>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["CodeScanner"]["Speedata"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "attention";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <li>"
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel collapsable panel-default collapsed\">\r\n    <div class=\"panel-heading "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ProfileConfigured") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":79}}})) != null ? stack1 : "")
    + "\">\r\n        <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Speedata.ConfigurationHeader",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":66}}}))
    + "</span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n        <ol>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Speedata.ConfigurationContent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":58}}}),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </ol>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["CodeScanner"]["ZebraDataWedge"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ZebraDataWedge.ConfigurationHint_Check",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":53}}}))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ZebraDataWedge.ConfigurationHint_Manual",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":54}}}))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "attention";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <li>"
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ZebraDataWedge.Version",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":33}}}))
    + ": "
    + alias3(((helper = (helper = lookupProperty(helpers,"VersionString") || (depth0 != null ? lookupProperty(depth0,"VersionString") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"VersionString","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":52}}}) : helper)))
    + "<br />\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ProfileConfigured") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "\r\n<div class=\"panel collapsable panel-default collapsed\">\r\n    <div class=\"panel-heading "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ProfileConfigured") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":79}}})) != null ? stack1 : "")
    + "\">\r\n        <span class=\"text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ZebraDataWedge.ProfileConfigurationHeader",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":27},"end":{"line":10,"column":79}}}))
    + "</span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n        <ol>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ZebraDataWedge.ProfileConfigurationSteps",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":69}}}),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "        </ol>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["CorrectiveActions"]["AdditionalImage"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1), depth0))
    + "\" data-mimetype=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1), depth0))
    + "\">\r\n    "
    + alias2((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"File") : depth0),"thumbnail|additional-file",false,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":64}}}))
    + "\r\n</li>";
},"useData":true});

this["Templates"]["CorrectiveActions"]["AutomatedIssueItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-issueoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"clearfix\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":70}}})) != null ? stack1 : "")
    + " >\r\n    <div class=\"text\">\r\n        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n    </div>\r\n\r\n    <div class=\"button-container\">\r\n        <button type=\"button\" data-action=\"edit-issue\" class=\"btn raised btn-default\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"CorrectiveActions.Buttons.Edit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":86},"end":{"line":7,"column":127}}}))
    + "</button>\r\n    </div>\r\n\r\n    <div class=\"action-state\"></div>\r\n</li>\r\n";
},"useData":true});

this["Templates"]["CorrectiveActions"]["ListItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-type=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":72}}}) : helper)))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-state=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"State") || (depth0 != null ? lookupProperty(depth0,"State") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"State","hash":{},"data":data,"loc":{"start":{"line":1,"column":160},"end":{"line":1,"column":169}}}) : helper)))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"text\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":26,"column":23}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",2,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":41}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p><strong>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":46}}}) : helper))) != null ? stack1 : "")
    + "</strong></p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":11,"column":31}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"Linebreaks")||(depth0 && lookupProperty(depth0,"Linebreaks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"Linebreaks","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":47}}}))
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":16,"column":20}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"Linebreaks")||(depth0 && lookupProperty(depth0,"Linebreaks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"Linebreaks","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":43}}}))
    + "\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.NoText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":59}}}))
    + "\r\n                    ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",3,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":17,"column":26},"end":{"line":17,"column":45}}}),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":23,"column":16}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":101}}})) != null ? stack1 : "")
    + "\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Linebreaks")||(depth0 && lookupProperty(depth0,"Linebreaks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"Linebreaks","hash":{},"data":data,"loc":{"start":{"line":18,"column":32},"end":{"line":18,"column":51}}}));
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.NoText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":59},"end":{"line":18,"column":94}}}));
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",6,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":19,"column":26},"end":{"line":19,"column":45}}}),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":23,"column":16}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":112}}})) != null ? stack1 : "")
    + "\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.ModifyParentIssue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":59},"end":{"line":20,"column":105}}}));
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",1,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":45}}}),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":16}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.IssueType.Form",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":50}}}))
    + ": "
    + alias3((lookupProperty(helpers,"ElementTitle")||(depth0 && lookupProperty(depth0,"ElementTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FormOID") : depth0),{"name":"ElementTitle","hash":{},"data":data,"loc":{"start":{"line":22,"column":52},"end":{"line":22,"column":76}}}))
    + "\r\n                ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "        <ul class=\"image-list\"></ul>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",3,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":34,"column":18},"end":{"line":34,"column":37}}}),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":62,"column":19}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" data-action=\"apply-comment\" class=\"btn raised btn-success\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.Buttons.ApplyComment",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":35,"column":97},"end":{"line":35,"column":146}}}))
    + "</button>\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",6,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":36,"column":22},"end":{"line":36,"column":41}}}),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":62,"column":12}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" data-action=\"apply-modifications\" class=\"btn raised btn-success\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.Buttons.ApplyChanges",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":37,"column":103},"end":{"line":37,"column":152}}}))
    + "</button>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",5,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":38,"column":22},"end":{"line":38,"column":41}}}),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":62,"column":12}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":52,"column":45}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"43":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" data-action=\"select-photo\" class=\"btn raised btn-success\">\r\n                        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CorrectiveActions.Buttons.SelectPhoto",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":72}}}))
    + "\r\n                    </button>\r\n\r\n                    <button type=\"button\" data-action=\"take-photo\" class=\"btn raised btn-success\">\r\n                        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CorrectiveActions.Buttons.TakePhoto",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":70}}}))
    + "\r\n                    </button>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" data-action=\"take-photo\" class=\"btn raised btn-success\">\r\n                    <input type=\"file\" accept=\"image/*\" />\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.Buttons.UploadPhoto",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":68}}}))
    + "\r\n                </button>\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",1,{"name":"Test","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":58,"column":25}}})) != null ? stack1 : "")
    + "\r\n                <button type=\"button\" data-action=\"postpone\" class=\"btn raised btn-default\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CorrectiveActions.Buttons.Postpone",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":60,"column":92},"end":{"line":60,"column":137}}}))
    + "</button>\r\n                <button type=\"button\" data-action=\"unapplicable\" class=\"btn raised btn-danger\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CorrectiveActions.Buttons.Unapplicable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":61,"column":95},"end":{"line":61,"column":144}}}))
    + "</button>\r\n            ";
},"48":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" data-action=\"start-acquisition\" class=\"btn raised btn-success\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.Buttons.StartAcquisation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":55,"column":105},"end":{"line":55,"column":158}}}))
    + "</button>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" data-action=\"done\" class=\"btn raised btn-success\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CorrectiveActions.Buttons.Done",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":57,"column":88},"end":{"line":57,"column":129}}}))
    + "</button>\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"action-state\"></div>\r\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn raised pull-right btn-danger\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.program(57, data, 0),"data":data,"loc":{"start":{"line":69,"column":71},"end":{"line":69,"column":148}}})) != null ? stack1 : "")
    + "</button>\r\n";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Delete",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":69,"column":90},"end":{"line":69,"column":112}}}));
},"57":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"GetCloseIssueText") || (depth0 != null ? lookupProperty(depth0,"GetCloseIssueText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"GetCloseIssueText","hash":{},"data":data,"loc":{"start":{"line":69,"column":120},"end":{"line":69,"column":141}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"clearfix\" data-oid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":38}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":81}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":133}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"State") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":134},"end":{"line":1,"column":178}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",5,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":29}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":30,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"button-container\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":66,"column":4},"end":{"line":70,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"useData":true});

this["Templates"]["CorrectiveActions"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"CorrectiveActionAutomatedIssueItem"),depth0,{"name":"CorrectiveActionAutomatedIssueItem","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"CorrectiveActionListItem"),depth0,{"name":"CorrectiveActionListItem","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal\" id=\"corrective-actions\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":62}}}) : helper))) != null ? stack1 : "")
    + "</h4>\r\n            </div>\r\n            <div class=\"modal-body\">\r\n                <div class=\"list-wrapper\">\r\n                    <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"AutomaticWorkflowIssues") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":12,"column":33}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Actions") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":16,"column":33}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ExistingCorrectiveActionIssues") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":20,"column":33}}})) != null ? stack1 : "")
    + "\r\n                        <li class=\"clearfix\">\r\n                            <div class=\"text\">\r\n                                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CorrectiveActions.Buttons.CreateIssue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":80}}}))
    + "\r\n                            </div>\r\n\r\n                            <div class=\"button-container\">\r\n                                <button type=\"button\" data-action=\"create-issue\" class=\"btn raised btn-default\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CorrectiveActions.Buttons.StartAcquisation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":112},"end":{"line":28,"column":165}}}))
    + "</button>\r\n                            </div>\r\n                        </li>\r\n                    </ul>\r\n                </div>\r\n            </div>\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-success btn-close flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":35,"column":77},"end":{"line":35,"column":97}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["CredentialEditor"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CustomZIndex") || (depth0 != null ? lookupProperty(depth0,"CustomZIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CustomZIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":98}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"AdditionalInformation") || (depth0 != null ? lookupProperty(depth0,"AdditionalInformation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AdditionalInformation","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":64}}}) : helper))) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material credential-editor\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CustomZIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":106}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CredentialEditor.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":41}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalInformation") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":71}}})) != null ? stack1 : "")
    + "\r\n\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CredentialEditor.Username",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":23},"end":{"line":12,"column":59}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <input type=\"text\" data-id=\"username\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Username") || (depth0 != null ? lookupProperty(depth0,"Username") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Username","hash":{},"data":data,"loc":{"start":{"line":16,"column":61},"end":{"line":16,"column":73}}}) : helper)))
    + "\" />\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CredentialEditor.Password",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":23},"end":{"line":22,"column":59}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <input type=\"password\" data-id=\"password\" />\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger pull-left btn-logout hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"CredentialEditor.Logout",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":86},"end":{"line":32,"column":120}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-danger pull-left btn-abort\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":33,"column":78},"end":{"line":33,"column":99}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success\" disabled=\"disabled\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Save",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":34,"column":79},"end":{"line":34,"column":99}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["DropDownList"]["Entity"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-identifier=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":42}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Value") || (depth0 != null ? lookupProperty(depth0,"Value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Value","hash":{},"data":data,"loc":{"start":{"line":2,"column":94},"end":{"line":2,"column":103}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "            &nbsp;<span class=\"icon-lock\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\r\n    <a"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":50}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"IsNotNullOrUndefined")||(depth0 && lookupProperty(depth0,"IsNotNullOrUndefined"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"IsNotNullOrUndefined","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":129}}})) != null ? stack1 : "")
    + ">\r\n        <span class=\"color\" style=\"background-color: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Color") || (depth0 != null ? lookupProperty(depth0,"Color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Color","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":62}}}) : helper)))
    + ";\"></span>\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsLockedState") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </a>\r\n</li>";
},"useData":true});

this["Templates"]["ElementInformation"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Required") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ElementInformation.Required",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":68}}}))
    + "</span>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Unit") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Decimals") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        ("
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"UnitTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"Unit") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),"Key":"ElementInformation.AsUnit"},"data":data,"loc":{"start":{"line":16,"column":25},"end":{"line":16,"column":86}}}))
    + ")\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        - "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Decimals") : stack1), depth0))
    + " "
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"count":((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Decimals") : stack1),"Key":"ElementInformation.Decimal"},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":111}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1),{"name":"And","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":27,"column":28}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        ("
    + container.escapeExpression((lookupProperty(helpers,"IndividualSchemaTitle")||(depth0 && lookupProperty(depth0,"IndividualSchemaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Types") : stack1)) != null ? lookupProperty(stack1,"0") : stack1),{"name":"IndividualSchemaTitle","hash":{},"data":data,"loc":{"start":{"line":26,"column":25},"end":{"line":26,"column":87}}}))
    + ")\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ElementInformation.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":31,"column":23},"end":{"line":31,"column":64}}}))
    + "</p>\r\n                    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Description") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ElementInformation.InfoText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":36,"column":23},"end":{"line":36,"column":61}}}))
    + "</p>\r\n                    <iframe class=\"info-text\" src=\""
    + alias3((lookupProperty(helpers,"CreateHtmlDataBlob")||(depth0 && lookupProperty(depth0,"CreateHtmlDataBlob"))||alias2).call(alias1,(lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"InfoText") : stack1),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":37,"column":72},"end":{"line":37,"column":100}}}),{"name":"CreateHtmlDataBlob","hash":{},"data":data,"loc":{"start":{"line":37,"column":51},"end":{"line":37,"column":102}}}))
    + "\" frameborder=\"0\"></iframe>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Files",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":23},"end":{"line":41,"column":51}}}))
    + "</p>\r\n                    <div class=\"row flex-list files\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Files") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":61,"column":29}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"==",1,{"name":"Test","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":60,"column":33}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"flex-item\" data-url=\""
    + container.escapeExpression((lookupProperty(helpers,"ResolveFileLinkUrl")||(depth0 && lookupProperty(depth0,"ResolveFileLinkUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"URL") : depth0),{"name":"ResolveFileLinkUrl","hash":{},"data":data,"loc":{"start":{"line":45,"column":57},"end":{"line":45,"column":88}}}))
    + "\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,(lookupProperty(helpers,"ResolveFileLinkUrl")||(depth0 && lookupProperty(depth0,"ResolveFileLinkUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"URL") : depth0),{"name":"ResolveFileLinkUrl","hash":{},"data":data,"loc":{"start":{"line":46,"column":42},"end":{"line":46,"column":71}}}),false,{"name":"WebAddress","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":28},"end":{"line":51,"column":43}}})) != null ? stack1 : "")
    + "                        </div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"file\">\r\n                                <div class=\"fileTypeIconsSmall file-link\"></div>\r\n                                <span>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                            </div>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"flex-item\" data-filename=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Filename") : depth0), depth0))
    + "\">\r\n                            <a class=\"file"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"IsVideo") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":42},"end":{"line":55,"column":70}}})) != null ? stack1 : "")
    + "\" data-type=\"file\" title=\""
    + alias2((lookupProperty(helpers,"FileTypeString")||(depth0 && lookupProperty(depth0,"FileTypeString"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeString","hash":{},"data":data,"loc":{"start":{"line":55,"column":96},"end":{"line":55,"column":128}}}))
    + "\">\r\n                                <div class=\"fileTypeIconsSmall "
    + alias2((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":56,"column":63},"end":{"line":56,"column":108}}}))
    + "\"></div>\r\n                                <span>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                            </a>\r\n                        </div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    return " video";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Images",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":66,"column":23},"end":{"line":66,"column":52}}}))
    + "</p>\r\n                    <div class=\"row flex-list images\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Images") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":89,"column":29}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                        <div class=\"flex-item\" data-filename=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Filename") : depth0), depth0))
    + "\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":70,"column":28},"end":{"line":87,"column":57}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsAvailableOffline") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":81,"column":35}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"file image\" data-type=\"image\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "\">\r\n                                <img src=\""
    + alias2((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":73,"column":42},"end":{"line":73,"column":69}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                                <span>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                            </a>\r\n";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"file image online-only\" data-type=\"image\">\r\n                                <div class=\"icon-cloud-download\"></div>\r\n                                <span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "</span>\r\n                            </a>\r\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.EmbeddedVideos",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":94,"column":23},"end":{"line":94,"column":60}}}))
    + "</p>\r\n                    <div class=\"row embedded-videos\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"UserIsOnline") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":96,"column":24},"end":{"line":102,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"EmbeddedVideos") : depth0),{"name":"each","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":28},"end":{"line":99,"column":37}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"EmbeddedVideo")||(depth0 && lookupProperty(depth0,"EmbeddedVideo"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"EmbeddedVideo","hash":{},"data":data,"loc":{"start":{"line":98,"column":32},"end":{"line":98,"column":54}}}))
    + "\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Information.EmbeddedVideosNotAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":101,"column":28},"end":{"line":101,"column":77}}}))
    + "\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":139,"column":27}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"MaximumSelectionCount") : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":110,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),"===",113,{"name":"Test","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":24},"end":{"line":138,"column":33}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            - "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"count":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"MaximumSelectionCount") : stack1),"Key":"ElementInformation.MaximumSelectableItems"},"data":data,"loc":{"start":{"line":109,"column":30},"end":{"line":109,"column":141}}}))
    + "\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":28},"end":{"line":137,"column":35}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1)) != null ? lookupProperty(stack1,"Roles") : stack1),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1)) != null ? lookupProperty(stack1,"Teams") : stack1),{"name":"Or","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":32},"end":{"line":136,"column":39}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"ElementInformation.Filters",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":115,"column":39},"end":{"line":115,"column":76}}}))
    + "</p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1)) != null ? lookupProperty(stack1,"Roles") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":36},"end":{"line":125,"column":43}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1)) != null ? lookupProperty(stack1,"Teams") : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":36},"end":{"line":135,"column":43}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <p class=\"sub-headline\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Role_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":118,"column":64},"end":{"line":118,"column":91}}}))
    + "</p>\r\n\r\n                                        <ul class=\"roles\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1)) != null ? lookupProperty(stack1,"Roles") : stack1),{"name":"each","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":44},"end":{"line":123,"column":53}}})) != null ? stack1 : "")
    + "                                        </ul>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <li>"
    + container.escapeExpression((lookupProperty(helpers,"RoleTitle")||(depth0 && lookupProperty(depth0,"RoleTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"RoleTitle","hash":{},"data":data,"loc":{"start":{"line":122,"column":52},"end":{"line":122,"column":70}}}))
    + "</li>\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <p class=\"sub-headline\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Team_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":128,"column":64},"end":{"line":128,"column":91}}}))
    + "</p>\r\n\r\n                                        <ul class=\"teams\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"AdditionalSettings") : stack1)) != null ? lookupProperty(stack1,"Filters") : stack1)) != null ? lookupProperty(stack1,"Teams") : stack1),{"name":"each","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":44},"end":{"line":133,"column":53}}})) != null ? stack1 : "")
    + "                                        </ul>\r\n";
},"48":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <li>"
    + container.escapeExpression((lookupProperty(helpers,"TeamTitle")||(depth0 && lookupProperty(depth0,"TeamTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"TeamTitle","hash":{},"data":data,"loc":{"start":{"line":132,"column":52},"end":{"line":132,"column":70}}}))
    + "</li>\r\n";
},"50":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||container.hooks.helperMissing).call(alias1,"ShowEvaluationInElementInformation",{"name":"IfIsEnabled","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":20},"end":{"line":157,"column":36}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Actions") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":20},"end":{"line":169,"column":27}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Evaluation") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":24},"end":{"line":156,"column":31}}})) != null ? stack1 : "");
},"52":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"ElementInformation.Categories",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":145,"column":31},"end":{"line":145,"column":71}}}))
    + "</p>\r\n                            <ul class=\"categories\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Evaluation") : depth0),{"name":"each","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":32},"end":{"line":154,"column":41}}})) != null ? stack1 : "")
    + "                            </ul>\r\n";
},"53":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Element") : depths[1])) != null ? lookupProperty(stack1,"Type") : stack1),{"name":"Test","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":36},"end":{"line":153,"column":45}}})) != null ? stack1 : "");
},"54":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <li>\r\n                                            <strong>"
    + alias3((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CategoryOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":150,"column":52},"end":{"line":150,"column":83}}}))
    + "</strong>\r\n                                            <p>"
    + alias3((lookupProperty(helpers,"Evaluation")||(depth0 && lookupProperty(depth0,"Evaluation"))||alias2).call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"Element") : depths[1]),{"name":"Evaluation","hash":{},"data":data,"loc":{"start":{"line":151,"column":47},"end":{"line":151,"column":77}}}))
    + "</p>\r\n                                        </li>\r\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"ElementInformation.Workflow",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":160,"column":27},"end":{"line":160,"column":65}}}))
    + "</p>\r\n                        <ul class=\"workflow\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Actions") : depth0),{"name":"each","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":28},"end":{"line":167,"column":37}}})) != null ? stack1 : "")
    + "                        </ul>\r\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\r\n                                    <strong>"
    + container.escapeExpression((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CategoryOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":164,"column":44},"end":{"line":164,"column":75}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TriggerAutomatically") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":75},"end":{"line":164,"column":156}}})) != null ? stack1 : "")
    + "</strong>\r\n                                    <p>"
    + ((stack1 = (lookupProperty(helpers,"Action")||(depth0 && lookupProperty(depth0,"Action"))||alias2).call(alias1,depth0,{"name":"Action","hash":{},"data":data,"loc":{"start":{"line":165,"column":39},"end":{"line":165,"column":56}}})) != null ? stack1 : "")
    + "</p>\r\n                                </li>\r\n";
},"58":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ElementInformation.Automatically",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":164,"column":105},"end":{"line":164,"column":148}}}))
    + ")";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"ElementInformation.Keywords",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":173,"column":23},"end":{"line":173,"column":61}}}))
    + "</p>\r\n                    <ul class=\"keywords\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Properties") : stack1),{"name":"each","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":24},"end":{"line":179,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\r\n";
},"61":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li>\r\n                                "
    + container.escapeExpression((lookupProperty(helpers,"PropertyTitle")||(depth0 && lookupProperty(depth0,"PropertyTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"PropertyTitle","hash":{},"data":data,"loc":{"start":{"line":177,"column":32},"end":{"line":177,"column":54}}}))
    + "\r\n                            </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal\" id=\"element-information\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</h4>\r\n            </div>\r\n            <div class=\"modal-body\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),">=",100,{"name":"Test","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":12,"column":25}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),">=",100,{"name":"Test","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":22,"column":25}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),"===",114,{"name":"Test","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":28,"column":25}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Description") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"InfoText") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Files") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":63,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Images") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":91,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EmbeddedVideos") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":16},"end":{"line":104,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),"in","113,114",{"name":"Test","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":140,"column":25}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),">=",100,{"name":"Test","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":16},"end":{"line":170,"column":25}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Properties") : stack1),{"name":"if","hash":{},"fn":container.program(60, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":16},"end":{"line":181,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-default flat btn-close\" data-dismiss=\"modal\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":184,"column":98},"end":{"line":184,"column":119}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true,"useDepths":true});

this["Templates"]["EmbeddedVideo"]["Vimeo"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"video-container vimeo-video\" data-identifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Identifier") || (depth0 != null ? lookupProperty(depth0,"Identifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Identifier","hash":{},"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":72}}}) : helper)))
    + "\">\r\n    <iframe src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"URL") || (depth0 != null ? lookupProperty(depth0,"URL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":24}}}) : helper)))
    + "\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>\r\n</div>";
},"useData":true});

this["Templates"]["EmbeddedVideo"]["Youtube"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"video-container youtube-video\" data-identifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Identifier") || (depth0 != null ? lookupProperty(depth0,"Identifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Identifier","hash":{},"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":74}}}) : helper)))
    + "\">\r\n    <iframe src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"URL") || (depth0 != null ? lookupProperty(depth0,"URL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":24}}}) : helper)))
    + "\"></iframe>\r\n</div>";
},"useData":true});

this["Templates"]["FileEditor"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " splitted-row";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"image-container"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"IsImage") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":43},"end":{"line":9,"column":93}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"IsImage") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":14,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " non-image-file";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <img src=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"Base64Content") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":11,"column":34},"end":{"line":11,"column":114}}})) != null ? stack1 : "")
    + "\" crossorigin=\"use-credentials\" />\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"Base64Content") : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"FilePath") : stack1), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"fileTypeIconsSmall "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":13,"column":55},"end":{"line":13,"column":100}}}))
    + "\"></div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":92},"end":{"line":32,"column":113}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":121},"end":{"line":32,"column":142}}}));
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn flat btn-success apply\" disabled=\"disabled\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Apply",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":33,"column":107},"end":{"line":33,"column":128}}}))
    + "</button>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material\" id=\"file-editor\">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FileEditor.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":35}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n        <div class=\"row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"File") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":55}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"File") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "\r\n            <div class=\"title-container\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FileEditor.FileTitle",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":23},"end":{"line":20,"column":54}}}))
    + "</label>\r\n                <input class=\"file-title\" type=\"text\" data-id=\"fileTitle\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":21,"column":81},"end":{"line":21,"column":90}}}) : helper)))
    + "\" maxlength=\"50\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":106},"end":{"line":21,"column":140}}})) != null ? stack1 : "")
    + "/>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row\">\r\n            <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FileEditor.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":26,"column":19},"end":{"line":26,"column":52}}}))
    + "</label>\r\n            <textarea class=\"form-control file-description\" data-id=\"fileDescription\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":85},"end":{"line":27,"column":119}}})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":27,"column":120},"end":{"line":27,"column":135}}}) : helper)))
    + "</textarea>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger pull-left close\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":32,"column":74},"end":{"line":32,"column":149}}})) != null ? stack1 : "")
    + "</button>\r\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":148}}})) != null ? stack1 : "")
    + "\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["FileInputWindow"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Message") || (depth0 != null ? lookupProperty(depth0,"Message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Message","hash":{},"data":data,"loc":{"start":{"line":8,"column":15},"end":{"line":8,"column":26}}}) : helper)))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material file-input-window\">\r\n    <div class=\"header\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Header") || (depth0 != null ? lookupProperty(depth0,"Header") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Header","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <input type=\"file\" id=\"file-input-window-input\" accept=\"image/*\" />\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Files"]["SortPlaceholder"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<li class=\"file placeholder\" data-placeholder=\"false\" style=\"height: 100%; width: 0;\"></li>";
},"useData":true});

this["Templates"]["FilterWindow"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"group\">\r\n                    <p class=\"title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":12,"column":37},"end":{"line":12,"column":46}}}) : helper)))
    + "</p>\r\n\r\n                    <div class=\"items\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":20,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn btn-default is-icon"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":62},"end":{"line":16,"column":112}}})) != null ? stack1 : "")
    + "\" data-key=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"Key") : depth0), depth0))
    + "\">\r\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Icon") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":76}}})) != null ? stack1 : "")
    + "\r\n                            "
    + alias2(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":37}}}) : helper)))
    + "\r\n                        </button>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"icon-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Icon") || (depth0 != null ? lookupProperty(depth0,"Icon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Icon","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":63}}}) : helper)))
    + "\"></i>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"filter-window\" class=\"modal filter-window\" style=\"z-index: "
    + alias3(((helper = (helper = lookupProperty(helpers,"ZIndex") || (depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ZIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":78}}}) : helper)))
    + " !important;\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueReport.SelectFilter",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":55}}}))
    + "\r\n                </h4>\r\n            </div>\r\n            <div class=\"modal-body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Groups") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":23,"column":25}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-danger flat btn-reset pull-left is-icon\">\r\n                    <span class=\"icon-bin2\"></span>\r\n                </button>\r\n\r\n                <button type=\"button\" class=\"btn btn-danger flat btn-abort\" data-dismiss=\"modal\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":41}}}))
    + "\r\n                </button>\r\n\r\n                <button type=\"button\" class=\"btn btn-success flat btn-save\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":40}}}))
    + "\r\n                </button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["FloorPlan"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"floor-plan-container\"></div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":44}}}))
    + "</p>\r\n        <div class=\"no-content\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FloorPlan.NoContent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":62}}}))
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"floor-plan\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"FloorPlanFound") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["Templates"]["FloorPlanInfoWindow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"parent\">@ "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Parent") || (depth0 != null ? lookupProperty(depth0,"Parent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Parent","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":44}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"floor-plan-info-window\" data-locationoid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"LocationOID") || (depth0 != null ? lookupProperty(depth0,"LocationOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LocationOID","hash":{},"data":data,"loc":{"start":{"line":1,"column":54},"end":{"line":1,"column":69}}}) : helper)))
    + "\">\r\n    <div class=\"content\">\r\n        <h4>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":23}}}) : helper))) != null ? stack1 : "")
    + "</h4>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parent") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button class=\"btn bt-default flat pull-left show-location-information is-icon\">\r\n            <i class=\"icon-info\"></i>\r\n        </button>\r\n        <button class=\"btn btn-default pull-right flat goto-location is-icon\">\r\n            <i class=\"icon-download2\"></i>\r\n        </button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["FormIssuePool"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <ul class=\"pools with-type-icons\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Pools") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"FormIssuesPool"),depth0,{"name":"FormIssuesPool","hash":{"LocationOID":(depths[1] != null ? lookupProperty(depths[1],"LocationOID") : depths[1]),"MenuID":(depths[1] != null ? lookupProperty(depths[1],"MenuID") : depths[1]),"IssuesTitle":(depth0 != null ? lookupProperty(depth0,"IssuesTitle") : depth0),"IssueCount":(depth0 != null ? lookupProperty(depth0,"IssueCount") : depth0),"State":(depth0 != null ? lookupProperty(depth0,"State") : depth0),"Form":(depth0 != null ? lookupProperty(depth0,"Form") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssuesNoIssuesAvailable"),depth0,{"name":"IssuesNoIssuesAvailable","hash":{"IssuePlural":(depth0 != null ? lookupProperty(depth0,"IssueType") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"issue-pool\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Pools") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["FormIssuePool"]["Pool"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"pool\">\r\n    <div class=\"type-icon\">\r\n        "
    + alias3((lookupProperty(helpers,"IssueTypeIndicator")||(depth0 && lookupProperty(depth0,"IssueTypeIndicator"))||alias2).call(alias1,3,{"name":"IssueTypeIndicator","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":32}}}))
    + "\r\n    </div>\r\n\r\n    <a href=\"#menu/"
    + alias3(((helper = (helper = lookupProperty(helpers,"MenuID") || (depth0 != null ? lookupProperty(depth0,"MenuID") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"MenuID","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":29}}}) : helper)))
    + "/location/"
    + alias3(((helper = (helper = lookupProperty(helpers,"LocationOID") || (depth0 != null ? lookupProperty(depth0,"LocationOID") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"LocationOID","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":54}}}) : helper)))
    + "?formOID="
    + alias3(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "&stateOID="
    + alias3(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"State") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"clearfix\">\r\n        <strong class=\"title\">"
    + alias3(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</strong>\r\n\r\n        <p class=\"state\">\r\n            "
    + alias3((lookupProperty(helpers,"PropertyBadge")||(depth0 && lookupProperty(depth0,"PropertyBadge"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"State") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"PropertyBadge","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":39}}}))
    + "\r\n        </p>\r\n\r\n        <p class=\"issue-count\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"issuesTitle":(depth0 != null ? lookupProperty(depth0,"IssuesTitle") : depth0),"count":(depth0 != null ? lookupProperty(depth0,"IssueCount") : depth0),"Key":"IssuePools.IssueCount"},"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":108}}}))
    + "</p>\r\n    </a>\r\n</li>";
},"useData":true});

this["Templates"]["FullscreenIssueViewer"]["Comments"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"list-item comment"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ByCurrentUser") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":73}}})) != null ? stack1 : "")
    + "\" data-oid=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":85},"end":{"line":2,"column":92}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ByCurrentUser") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"comment-content"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsEditable") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":68}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsEditable") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ByCurrentUser") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":91}}})) != null ? stack1 : "")
    + "\r\n        <p class=\"text\">"
    + alias3((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":51}}}))
    + "</p>\r\n        <p class=\"timestamp\">"
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":61}}}))
    + "</p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsEditable") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " by-current-user";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"preview\">\r\n        <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"UserImage")||(depth0 && lookupProperty(depth0,"UserImage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserImage","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":42}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n    </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " is-editable";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"content-wrapper\">\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"creator\">"
    + container.escapeExpression((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":14,"column":52},"end":{"line":14,"column":76}}}))
    + "</p>";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            </div>\r\n            <div class=\"button-wrapper\">\r\n                <div class=\"show-comment-options\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"FullscreenIssueViewer.Tabs.Comments.EditComment.ButtonText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":57},"end":{"line":21,"column":126}}}))
    + "\">\r\n                    <i class=\"icon-dots-three-vertical\"></i>\r\n                </div>\r\n            </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Comments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":9}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["FullscreenIssueViewer"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"fullscreen-issue-viewer\">\r\n    <h2 class=\"issue-title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":39}}}) : helper))) != null ? stack1 : "")
    + "</h2>\r\n\r\n    <div class=\"editor-information\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FullscreenIssueViewer.By",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":43}}}))
    + " "
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":6,"column":44},"end":{"line":6,"column":68}}}))
    + " | "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":103}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"organisation-unit\">\r\n        <i class=\"icon-location\"></i> "
    + alias3((lookupProperty(helpers,"ElementTitle")||(depth0 && lookupProperty(depth0,"ElementTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"AssignedElementOID") : depth0),{"name":"ElementTitle","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":73}}}))
    + "\r\n    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Keywords") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"sub-view-toggle\">\r\n        <div class=\"tab-item active\" data-tab-id=\"issues-list\">\r\n            <i class=\"icon-link\"></i> <span class=\"text\">"
    + alias3(alias4(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Headers") : depths[1])) != null ? lookupProperty(stack1,"Issues") : stack1), depth0))
    + "</span>\r\n        </div>\r\n        <div class=\"tab-item\" data-tab-id=\"files-list\">\r\n            <i class=\"icon-attachment\"></i> <span class=\"text\">"
    + alias3(alias4(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Headers") : depths[1])) != null ? lookupProperty(stack1,"Files") : stack1), depth0))
    + "</span>\r\n        </div>\r\n        <div class=\"tab-item\" data-tab-id=\"comment-list\">\r\n            <i class=\"icon-bubble\"></i> <span class=\"text\">"
    + alias3(alias4(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Headers") : depths[1])) != null ? lookupProperty(stack1,"Comments") : stack1), depth0))
    + "</span>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"tabs\">\r\n        <div class=\"tab issue-list\" data-tab=\"issues-list\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FullScreenIssueViewerIssues"),depth0,{"name":"FullScreenIssueViewerIssues","hash":{"Issues":(depth0 != null ? lookupProperty(depth0,"Descendants") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\r\n\r\n        <div class=\"tab files-list hide\" data-tab=\"files-list\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FullScreenIssueViewerFiles"),depth0,{"name":"FullScreenIssueViewerFiles","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\r\n\r\n        <div class=\"tab comment-list hide\" data-tab=\"comment-list\">\r\n            <div class=\"comments-wrapper\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FullScreenIssueViewerComments"),depth0,{"name":"FullScreenIssueViewerComments","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <div class=\"add-comment-wrapper\">\r\n                <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FullscreenIssueViewer.Tabs.Comments.AddCommentHeader",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":52,"column":19},"end":{"line":52,"column":82}}}))
    + "</p>\r\n\r\n                <textarea class=\"form-control comment-input\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FullscreenIssueViewer.Tabs.Comments.AddCommentPlaceholder",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":54,"column":86},"end":{"line":54,"column":154}}}))
    + "\" rows=\"3\"></textarea>\r\n\r\n                <div class=\"button-row\">\r\n                    <input type=\"checkbox\" name=\"internal-comment\">\r\n                    <button type=\"button\" class=\"btn btn-success flat btn-save btn-submit-comment\" disabled>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FullscreenIssueViewer.Tabs.Comments.Submit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":58,"column":108},"end":{"line":58,"column":161}}}))
    + "</button>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <ul class=\"keywords\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Keywords") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"keyword\">"
    + container.escapeExpression((lookupProperty(helpers,"PropertyTitle")||(depth0 && lookupProperty(depth0,"PropertyTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"PropertyTitle","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":54}}}))
    + "</li>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <p class=\"issue-description\"></p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":64,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["FullscreenIssueViewer"]["Files"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"list-item attachment\" data-filename=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":61}}}) : helper)))
    + "\">\r\n    <div class=\"preview\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsImage") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"attachment-information\">\r\n        <p class=\"title\">"
    + alias3((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":53}}}))
    + "</p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"fileTypeIcons "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":5,"column":38},"end":{"line":5,"column":73}}}))
    + "\"></div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"file-image",false,true,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":62}}}))
    + "\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"description\">"
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":69}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Files") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":18,"column":9}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["FullscreenIssueViewer"]["Issues"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"list-item issue\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":88}}})) != null ? stack1 : "")
    + ">\r\n    <a href=\"#issue/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":58}}})) != null ? stack1 : "")
    + "?type="
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":3,"column":64},"end":{"line":3,"column":72}}}) : helper)))
    + "\" class=\"clearfix\">\r\n        <p class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":36}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"in","1,2,3,5,6,7,8",{"name":"Test","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + "\r\n        <p class=\"issue-id\">"
    + alias4((lookupProperty(helpers,"IssueTypeAbbreviation")||(depth0 && lookupProperty(depth0,"IssueTypeAbbreviation"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"IssueTypeAbbreviation","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":58}}}))
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":12,"column":58},"end":{"line":12,"column":64}}}) : helper)))
    + "</p>\r\n    </a>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":54}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-oid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":73},"end":{"line":2,"column":80}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":36}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":3,"column":44},"end":{"line":3,"column":51}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"PropertyBadge")||(depth0 && lookupProperty(depth0,"PropertyBadge"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"PropertyBadge","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":42}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":15,"column":9}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["ImageViewer"]["Manager"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"image-viewer-manager\"></div>";
},"useData":true});

this["Templates"]["ImageViewer"]["MarkSettings"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"tile icon-bubble hidden\"></div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"tile action text\">\r\n                    <span class=\"icon-bubble\"></span>\r\n                </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"mark-settings\">\r\n    <div class=\"settings\">\r\n        <div class=\"color-picker\">\r\n            <div class=\"column\">\r\n                <div class=\"tile color black\" data-color=\"rgba(0,0,0,1)\"></div>\r\n                <div class=\"tile color red active\" data-color=\"rgba(255,0,0,1)\"></div>\r\n                <div class=\"tile color yellow\" data-color=\"rgba(255,255,0,1)\"></div>\r\n            </div>\r\n\r\n            <div class=\"column\">\r\n                <div class=\"tile color blue\" data-color=\"rgba(0,0,255,1)\"></div>\r\n                <div class=\"tile color white\" data-color=\"rgba(255,255,255,1)\"></div>\r\n                <div class=\"tile color silver\" data-color=\"rgba(128,128,128,1)\"></div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"c slider-wrapper\">\r\n            <div class=\"pixel-slider slider\"></div>\r\n        </div>\r\n\r\n        <div class=\"slider-wrapper\">\r\n            <div class=\"opacity-slider slider\"></div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"dropdown\">\r\n        <div class=\"tile icon-dots-three-vertical\"></div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CanWriteComments") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"tile icon-arrow-down-right2 hidden\"></div>\r\n        <div class=\"tile icon-brush\"></div>\r\n    </div>\r\n\r\n    <div class=\"actions actions-dropdown-menu\">\r\n        <div class=\"input-type\">\r\n            <div class=\"tile action brush active\">\r\n                <span class=\"icon-brush\"></span>\r\n            </div>\r\n\r\n            <div class=\"tile action line\">\r\n                <span class=\"icon-arrow-down-right2\"></span>\r\n            </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CanWriteComments") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":49,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n        <div class=\"settings-buttons\">\r\n            <div class=\"tile selected-color\"></div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"options\">\r\n        <div class=\"tile icon-undo2\"></div>\r\n        <div class=\"tile icon-redo2\"></div>\r\n        <div class=\"tile icon-bin2\"></div>\r\n        <div class=\"tile icon-checkmark save\"></div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["ImageViewer"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"previous-image\">\r\n                    <img src=\"./img/prev.png\" />\r\n                </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"next-image\">\r\n                    <img src=\"./img/next.png\" />\r\n                </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button class=\"btn btn-default flat pull-right image-comparison\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ImageViewer.CompareImages",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":85},"end":{"line":39,"column":121}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"image-viewer\">\r\n    <div class=\"content\">\r\n        <div class=\"inner\">\r\n            <div class=\"graphics\">\r\n                <img crossorigin=\"use-credentials\" />\r\n                <svg xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\"></svg>\r\n                <p class=\"error-message hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ImageViewer.NotFound",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":79}}}))
    + "</p>\r\n                <button class=\"btn download-button hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ImageViewer.DownloadNow",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":59},"end":{"line":8,"column":93}}}))
    + "</button>\r\n            </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ImageCount") : depth0),">",1,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":15,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ImageCount") : depth0),">",1,{"name":"Test","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n    <div class=\"footer\">\r\n        <div class=\"footer-content\">\r\n            <div class=\"title-wrapper\">\r\n                <span class=\"title\">\r\n                    "
    + alias3(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":29}}}) : helper)))
    + "\r\n                </span>\r\n\r\n                <span class=\"image-number\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"ImageCount":(depth0 != null ? lookupProperty(depth0,"ImageCount") : depth0),"CurrentImageIndex":(depth0 != null ? lookupProperty(depth0,"CurrentImageIndex") : depth0),"Key":"ImageViewer.Footer"},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":111}}}))
    + "\r\n                </span>\r\n            </div>\r\n\r\n            <div class=\"button-wrapper\">\r\n                <button class=\"btn btn-default flat btn-close pull-right\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":37,"column":74},"end":{"line":37,"column":95}}}))
    + "</button>\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"ComparisonImages") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":40,"column":25}}})) != null ? stack1 : "")
    + "            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["IndividualViews"]["Entity"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\r\n                            <td>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":40}}}) : helper)))
    + ":</td>\r\n                            <td>\r\n                                "
    + ((stack1 = (lookupProperty(helpers,"IndividualValue")||(depth0 && lookupProperty(depth0,"IndividualValue"))||alias2).call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"Item") : depths[1]),{"name":"IndividualValue","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":66}}})) != null ? stack1 : "")
    + "\r\n                            </td>\r\n                        </tr>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"individual-entity\">\r\n    <div class=\"sticky-table\">\r\n        <div class=\"sticky-wrap\">\r\n            <table>\r\n                <col width=\"30%\" />\r\n                <col width=\"70%\" />\r\n                <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Schema") : depth0)) != null ? lookupProperty(stack1,"Properties") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":15,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\r\n            </table>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true,"useDepths":true});

this["Templates"]["IndividualViews"]["File"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"file thumbnail\" data-file-identifier=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"file\" title=\""
    + alias2((lookupProperty(helpers,"FileTypeString")||(depth0 && lookupProperty(depth0,"FileTypeString"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeString","hash":{},"data":data,"loc":{"start":{"line":1,"column":88},"end":{"line":1,"column":120}}}))
    + "\">\r\n    <div class=\"fileTypeIcons "
    + alias2((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":75}}}))
    + "\"></div>\r\n    <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["IndividualViews"]["Image"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"file image thumbnail\" data-type=\"image\">\r\n    <img data-file-identifier=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" src=\""
    + alias1((lookupProperty(helpers,"FixIOSFilepath")||(depth0 && lookupProperty(depth0,"FixIOSFilepath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"FileSource") : depth0),{"name":"FixIOSFilepath","hash":{},"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":79}}}))
    + "\" />\r\n</a>\r\n";
},"useData":true});

this["Templates"]["Information"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",3,{"name":"Test","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":24,"column":17}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"HasVisibleInformation") : depths[1]),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":229,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h4>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":27}}}) : helper))) != null ? stack1 : "")
    + "</h4>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parent") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Version") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ForceParameterOrderWhileRecording") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InitialStateOID") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"hierarchy\">@ "
    + container.escapeExpression((lookupProperty(helpers,"ElementHierarchy")||(depth0 && lookupProperty(depth0,"ElementHierarchy"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0),true,{"name":"ElementHierarchy","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":81}}}))
    + "</p>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Information.Version",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":62}}}))
    + "</p>\r\n                <span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"Version") || (depth0 != null ? lookupProperty(depth0,"Version") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Version","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":33}}}) : helper)))
    + "</span>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":52}}}))
    + "</p>\r\n                <div>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Information.ParametersMustBeRecordedInTheCorrecetOrder",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":21},"end":{"line":17,"column":86}}}))
    + "</div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.State",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":19},"end":{"line":21,"column":40}}}))
    + "</p>\r\n                "
    + alias3((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"InitialStateOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":51}}}))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",3,{"name":"Test","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":30,"column":21}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":28,"column":52}}}))
    + "</p>\r\n                <div class=\"no-content\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Information.NoContent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":40},"end":{"line":29,"column":72}}}))
    + "</div>\r\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowAddress") : stack1),(depth0 != null ? lookupProperty(depth0,"Address") : depth0),{"name":"And","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":58,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowCostCenter") : stack1),(depths[1] != null ? lookupProperty(depths[1],"CostCenter") : depths[1]),{"name":"And","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":63,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowDescription") : stack1),(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"And","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":68,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"AdditionalPropertiesConfiguration") : stack1)) != null ? lookupProperty(stack1,"ShowAdditionalProperties") : stack1),(depths[1] != null ? lookupProperty(depths[1],"AdditionalPropertyGroups") : depths[1]),{"name":"And","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":98,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"MasterDataConfiguration") : stack1)) != null ? lookupProperty(stack1,"ShowMasterData") : stack1),(depths[1] != null ? lookupProperty(depths[1],"MasterData") : depths[1]),{"name":"And","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":102,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowKeywords") : stack1),(depth0 != null ? lookupProperty(depth0,"Properties") : depth0),{"name":"And","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":12},"end":{"line":111,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowTeams") : stack1),(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"And","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":12},"end":{"line":125,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowFiles") : stack1),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":12},"end":{"line":193,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowInfoText") : stack1),(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"And","hash":{},"fn":container.program(70, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":12},"end":{"line":198,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowForms") : stack1),(depth0 != null ? lookupProperty(depth0,"Forms") : depth0),{"name":"And","hash":{},"fn":container.program(72, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":12},"end":{"line":214,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Configuration") : depths[1])) != null ? lookupProperty(stack1,"ShowMeasures") : stack1),(depths[1] != null ? lookupProperty(depths[1],"Measures") : depths[1]),{"name":"And","hash":{},"fn":container.program(76, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":216,"column":12},"end":{"line":228,"column":20}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.ContactInformation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":33,"column":19},"end":{"line":33,"column":60}}}))
    + "</p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"Address") : depth0),{"name":"with","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":57,"column":25}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"text\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Name") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":39,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Street") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":43,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"ZipCode") : depth0),(depth0 != null ? lookupProperty(depth0,"City") : depth0),{"name":"Or","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":47,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Phone") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":51,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"WebAddress") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":55,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":38,"column":44}}}) : helper)))
    + "</strong><br />\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"Street") || (depth0 != null ? lookupProperty(depth0,"Street") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Street","hash":{},"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":42,"column":38}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"StreetNumber") || (depth0 != null ? lookupProperty(depth0,"StreetNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"StreetNumber","hash":{},"data":data,"loc":{"start":{"line":42,"column":39},"end":{"line":42,"column":55}}}) : helper)))
    + "<br />\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"ZipCode") || (depth0 != null ? lookupProperty(depth0,"ZipCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ZipCode","hash":{},"data":data,"loc":{"start":{"line":46,"column":28},"end":{"line":46,"column":39}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"City") || (depth0 != null ? lookupProperty(depth0,"City") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"City","hash":{},"data":data,"loc":{"start":{"line":46,"column":40},"end":{"line":46,"column":48}}}) : helper)))
    + "<br />\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            Tel: <a href=\"tel:"
    + alias3((lookupProperty(helpers,"FormatPhoneNumber")||(depth0 && lookupProperty(depth0,"FormatPhoneNumber"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Phone") : depth0),{"name":"FormatPhoneNumber","hash":{},"data":data,"loc":{"start":{"line":50,"column":46},"end":{"line":50,"column":73}}}))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"Phone") || (depth0 != null ? lookupProperty(depth0,"Phone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Phone","hash":{},"data":data,"loc":{"start":{"line":50,"column":75},"end":{"line":50,"column":84}}}) : helper)))
    + "</a><br />\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"WebAddress") : depth0),null,{"name":"WebAddress","hash":{},"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":63}}}))
    + "\r\n";
},"27":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Information.CostCenter",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":61,"column":19},"end":{"line":61,"column":52}}}))
    + "</p>\r\n                <p class=\"text\">"
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"CostCenter") : depths[1]), depth0))
    + "</p>\r\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Information.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":66,"column":19},"end":{"line":66,"column":53}}}))
    + "</p>\r\n                <p class=\"text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":67,"column":32},"end":{"line":67,"column":49}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n";
},"31":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"AdditionalPropertyGroups") : depths[1]),{"name":"each","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":97,"column":25}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"additional-property-group-header\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\r\n\r\n                    <ul class=\"additional-property-group\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Children") : depth0),{"name":"each","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":95,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"additional-property clearfix\">\r\n                                <div class=\"row\">\r\n                                    <div class=\"col-xs-12 col-sm-6 col-md-6 title-wrapper\">\r\n                                        <strong class=\"title\">\r\n                                            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "\r\n                                        </strong>\r\n                                    </div>\r\n\r\n                                    <div class=\"col-xs-12 col-sm-6 col-md-6 value-wrapper\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",7,{"name":"Test","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":85,"column":40},"end":{"line":91,"column":49}}})) != null ? stack1 : "")
    + "                                    </div>\r\n                                </div>\r\n                            </li>\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <a class=\"additional-property-value\" data-filename=\""
    + alias3((lookupProperty(helpers,"GetFilenameByOID")||(depth0 && lookupProperty(depth0,"GetFilenameByOID"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"GetFilenameByOID","hash":{},"data":data,"loc":{"start":{"line":86,"column":96},"end":{"line":86,"column":127}}}))
    + "\">\r\n                                                <img src=\""
    + alias3((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":87,"column":58},"end":{"line":87,"column":82}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                                            </a>\r\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"Value") : depth0), depth0)) != null ? stack1 : "")
    + "\r\n";
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.lambda((depths[1] != null ? lookupProperty(depths[1],"MasterData") : depths[1]), depth0)) != null ? stack1 : "")
    + "\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Keyword_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":105,"column":19},"end":{"line":105,"column":49}}}))
    + "</p>\r\n                <ul class=\"keywords\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Properties") : depth0),{"name":"each","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":109,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>"
    + container.escapeExpression((lookupProperty(helpers,"PropertyTitle")||(depth0 && lookupProperty(depth0,"PropertyTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"PropertyTitle","hash":{},"data":data,"loc":{"start":{"line":108,"column":28},"end":{"line":108,"column":50}}}))
    + "</li>\r\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Teams",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":114,"column":19},"end":{"line":114,"column":47}}}))
    + "</p>\r\n                <ul class=\"team-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":20},"end":{"line":123,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>\r\n                            <div class=\"team\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),(depth0 != null ? lookupProperty(depth0,"ImagePath") : depth0),{"name":"RoundedImage","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                <span>"
    + container.escapeExpression((lookupProperty(helpers,"TeamTitle")||(depth0 && lookupProperty(depth0,"TeamTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"TeamTitle","hash":{},"data":data,"loc":{"start":{"line":120,"column":38},"end":{"line":120,"column":60}}}))
    + "</span>\r\n                            </div>\r\n                        </li>\r\n";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"Files") : depths[1]),{"name":"if","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":16},"end":{"line":151,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"Images") : depths[1]),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":16},"end":{"line":179,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"EmbeddedVideos") : depths[1]),{"name":"if","hash":{},"fn":container.program(64, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":16},"end":{"line":192,"column":23}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Files",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":129,"column":23},"end":{"line":129,"column":51}}}))
    + "</p>\r\n                    <div class=\"row flex-list files\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"Files") : depths[1]),{"name":"each","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":20},"end":{"line":149,"column":29}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"==",1,{"name":"Test","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(52, data, 0),"data":data,"loc":{"start":{"line":132,"column":24},"end":{"line":148,"column":33}}})) != null ? stack1 : "");
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"flex-item\" data-url=\""
    + container.escapeExpression((lookupProperty(helpers,"ResolveFileLinkUrl")||(depth0 && lookupProperty(depth0,"ResolveFileLinkUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"URL") : depth0),{"name":"ResolveFileLinkUrl","hash":{},"data":data,"loc":{"start":{"line":133,"column":61},"end":{"line":133,"column":92}}}))
    + "\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,(lookupProperty(helpers,"ResolveFileLinkUrl")||(depth0 && lookupProperty(depth0,"ResolveFileLinkUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"URL") : depth0),{"name":"ResolveFileLinkUrl","hash":{},"data":data,"loc":{"start":{"line":134,"column":46},"end":{"line":134,"column":75}}}),false,{"name":"WebAddress","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":32},"end":{"line":139,"column":47}}})) != null ? stack1 : "")
    + "                            </div>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"file\">\r\n                                        <div class=\"fileTypeIconsSmall file-link\"></div>\r\n                                        <span>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                                    </div>\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"flex-item\" data-filename=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Filename") : depth0), depth0))
    + "\">\r\n                                <a class=\"file"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"IsVideo") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":46},"end":{"line":143,"column":74}}})) != null ? stack1 : "")
    + "\" data-type=\"file\" title=\""
    + alias2((lookupProperty(helpers,"FileTypeString")||(depth0 && lookupProperty(depth0,"FileTypeString"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeString","hash":{},"data":data,"loc":{"start":{"line":143,"column":100},"end":{"line":143,"column":132}}}))
    + "\">\r\n                                    <div class=\"fileTypeIconsSmall "
    + alias2((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":144,"column":67},"end":{"line":144,"column":112}}}))
    + "\"></div>\r\n                                    <span>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                                </a>\r\n                            </div>\r\n";
},"53":function(container,depth0,helpers,partials,data) {
    return " video";
},"55":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Images",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":154,"column":23},"end":{"line":154,"column":52}}}))
    + "</p>\r\n                    <div class=\"row flex-list images\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"Images") : depths[1]),{"name":"each","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":20},"end":{"line":177,"column":29}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                        <div class=\"flex-item\" data-filename=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Filename") : depth0), depth0))
    + "\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(57, data, 0),"inverse":container.program(62, data, 0),"data":data,"loc":{"start":{"line":158,"column":28},"end":{"line":175,"column":57}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\r\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsAvailableOffline") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":159,"column":32},"end":{"line":169,"column":39}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <a class=\"file image\" data-type=\"image\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "\">\r\n                                        <img src=\""
    + alias2((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":161,"column":50},"end":{"line":161,"column":77}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                                        <span>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                                    </a>\r\n";
},"60":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <a class=\"file image online-only\" data-type=\"image\">\r\n                                        <div class=\"icon-cloud-download\"></div>\r\n                                        <span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "</span>\r\n                                    </a>\r\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a class=\"file image\" data-type=\"image\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "\">\r\n                                    <img src=\""
    + alias2((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":172,"column":46},"end":{"line":172,"column":73}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                                    <span>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\r\n                                </a>\r\n";
},"64":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.EmbeddedVideos",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":182,"column":23},"end":{"line":182,"column":60}}}))
    + "</p>\r\n                    <div class=\"row embedded-videos\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"UserIsOnline") : depths[1]),{"name":"if","hash":{},"fn":container.program(65, data, 0, blockParams, depths),"inverse":container.program(68, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":184,"column":24},"end":{"line":190,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"65":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"EmbeddedVideos") : depths[1]),{"name":"each","hash":{},"fn":container.program(66, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":28},"end":{"line":187,"column":37}}})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"EmbeddedVideo")||(depth0 && lookupProperty(depth0,"EmbeddedVideo"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"EmbeddedVideo","hash":{},"data":data,"loc":{"start":{"line":186,"column":32},"end":{"line":186,"column":54}}}))
    + "\r\n";
},"68":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Information.EmbeddedVideosNotAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":189,"column":28},"end":{"line":189,"column":77}}}))
    + "\r\n";
},"70":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Information.InfoText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":196,"column":19},"end":{"line":196,"column":50}}}))
    + "</p>\r\n                <iframe class=\"info-text\" src=\""
    + alias3((lookupProperty(helpers,"CreateHtmlDataBlob")||(depth0 && lookupProperty(depth0,"CreateHtmlDataBlob"))||alias2).call(alias1,(lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":197,"column":68},"end":{"line":197,"column":88}}}),{"name":"CreateHtmlDataBlob","hash":{},"data":data,"loc":{"start":{"line":197,"column":47},"end":{"line":197,"column":90}}}))
    + "\" frameborder=\"0\"></iframe>\r\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Forms",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":201,"column":19},"end":{"line":201,"column":47}}}))
    + "</p>\r\n                <div class=\"flex-list forms row\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Forms") : depth0),{"name":"each","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":203,"column":20},"end":{"line":212,"column":29}}})) != null ? stack1 : "")
    + "                </div>\r\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ElementExists")||(depth0 && lookupProperty(depth0,"ElementExists"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ElementExists","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":24},"end":{"line":211,"column":42}}})) != null ? stack1 : "");
},"74":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"flex-item\" data-formoid=\""
    + alias1(container.lambda(depth0, depth0))
    + "\">\r\n                                <a class=\"form\">\r\n                                    <img src=\"./img/form.svg\" />\r\n                                    <span>"
    + alias1((lookupProperty(helpers,"ElementTitle")||(depth0 && lookupProperty(depth0,"ElementTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ElementTitle","hash":{},"data":data,"loc":{"start":{"line":208,"column":42},"end":{"line":208,"column":63}}}))
    + "</span>\r\n                                </a>\r\n                            </div>\r\n";
},"76":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Information.Measures",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":217,"column":19},"end":{"line":217,"column":50}}}))
    + "</p>\r\n                <div class=\"flex-list measures row\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"Measures") : depths[1]),{"name":"each","hash":{},"fn":container.program(77, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":20},"end":{"line":226,"column":29}}})) != null ? stack1 : "")
    + "                </div>\r\n";
},"77":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"flex-item\" data-measure-oid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"OID") : depth0), depth0))
    + "\">\r\n                            <a class=\"form\">\r\n                                <img src=\"./img/form.svg\" />\r\n                                <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "</span>\r\n                            </a>\r\n                        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"information\">\r\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Element") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":230,"column":13}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["InfoWindow"]["BaseWindow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ZIndex") || (depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ZIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":94},"end":{"line":1,"column":104}}}) : helper)))
    + "!important;\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fullscreen info-window\" id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"InfoWindowID") || (depth0 != null ? lookupProperty(depth0,"InfoWindowID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"InfoWindowID","hash":{},"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":62}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":123}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":34}}}))
    + "\r\n                </h4>\r\n            </div>\r\n\r\n            <div class=\"modal-body\">\r\n                <div class=\"content\"></div>\r\n            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-danger btn-close flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":15,"column":76},"end":{"line":15,"column":97}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Init"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-default pull-right support-mail hidden\">\r\n            <i class=\"icon-mail\"></i>\r\n        </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"init-screen\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":33}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    <div class=\"logo-container\">\r\n        <div>\r\n            <img src=\"./img/awenko360_Logo_2c.svg\" />\r\n        </div>\r\n    </div>\r\n    <div class=\"icons-container\">\r\n        <div>\r\n            <div class=\"cssload-container position-left\">\r\n                <div class=\"cssload-whirlpool\"></div>\r\n            </div>\r\n            <table class=\"loading-icons\">\r\n                <tr>\r\n                    <td>\r\n                        <div class=\"icon-init off\"> </div>\r\n                    </td>\r\n                    <td>\r\n                        <div class=\"icon-login off\"> </div>\r\n                    </td>\r\n                    <td>\r\n                        <div class=\"icon-memory-load off\"> </div>\r\n                    </td>\r\n                    <td>\r\n                        <div class=\"icon-download off\"> </div>\r\n                    </td>\r\n                    <td>\r\n                        <div class=\"icon-clear-app off\"> </div>\r\n                    </td>\r\n                </tr>\r\n            </table>\r\n        </div>\r\n    </div>\r\n    <div class=\"progress-container\">\r\n    </div>\r\n    <div class=\"copyright-container\">\r\n        Copyright © 2010 - 2025\r\n    </div>\r\n    <div class=\"version-container\">\r\n        3.41.1\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["InputWindow"]["Input"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"DefaultValue") || (depth0 != null ? lookupProperty(depth0,"DefaultValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DefaultValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":77},"end":{"line":1,"column":95}}}) : helper))) != null ? stack1 : "")
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input class=\"input form-control\" type=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":48}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":103}}})) != null ? stack1 : "")
    + " />";
},"useData":true});

this["Templates"]["InputWindow"]["InputTypeSelect"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DefaultValue") || (depth0 != null ? lookupProperty(depth0,"DefaultValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DefaultValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":90}}}) : helper)))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input class=\"input form-control\" type=\"text\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":99}}})) != null ? stack1 : "")
    + " />\r\n<div>\r\n    <span><input type=\"checkbox\" id=\"select-param-filter\" name=\"select-param\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ParamChecked") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":124}}})) != null ? stack1 : "")
    + " /><label for=\"select-param-filter\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Parameter",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":160},"end":{"line":3,"column":185}}}))
    + "</label></span>\r\n    <span><input type=\"checkbox\" id=\"select-group-filter\" name=\"select-group\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"GroupChecked") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":78},"end":{"line":4,"column":124}}})) != null ? stack1 : "")
    + " /><label for=\"select-group-filter\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Parametergroup",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":160},"end":{"line":4,"column":190}}}))
    + "</label></span>\r\n</div>";
},"useData":true});

this["Templates"]["InputWindow"]["Textarea"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"DefaultValue") || (depth0 != null ? lookupProperty(depth0,"DefaultValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DefaultValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":84}}}) : helper))) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<textarea class=\"input form-control textarea\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"DefaultValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":91}}})) != null ? stack1 : "")
    + "</textarea>";
},"useData":true});

this["Templates"]["InputWindow"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"info-text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Message") || (depth0 != null ? lookupProperty(depth0,"Message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Message","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":48}}}) : helper))) != null ? stack1 : "")
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material input-window\">\r\n    <div class=\"header\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Header") || (depth0 != null ? lookupProperty(depth0,"Header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Header","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "\r\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"InputField") || (depth0 != null ? lookupProperty(depth0,"InputField") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"InputField","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":22}}}) : helper)))
    + "\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-custom\" data-dismiss=\"modal\"></button>\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-abort\" data-dismiss=\"modal\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":89},"end":{"line":16,"column":110}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-ok\" data-dismiss=\"modal\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":87},"end":{"line":17,"column":107}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["IssueInformationSidebar"]["Files"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Files") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + ")";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "      <div class=\"item action-button justify-end add-attachment-file\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":14,"column":40}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"icon-plus\"></span>\r\n          <input type=\"file\" multiple>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "          <i class=\"icon-camera combined-icon\">\r\n            <i class=\"icon-plus\"></i>\r\n          </i>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li data-filename=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":20,"column":25},"end":{"line":20,"column":37}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsImage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":33,"column":14},"end":{"line":33,"column":63}}})) != null ? stack1 : "")
    + "</span>\r\n      </li>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"attachment-thumbnail\">\r\n            <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":23,"column":22},"end":{"line":23,"column":49}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n          </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsVideo") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":26,"column":10},"end":{"line":30,"column":17}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"fileTypeIconsSmall file-video\"></div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"fileTypeIconsSmall "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":78}}}))
    + "\"></div>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":33,"column":27},"end":{"line":33,"column":36}}}) : helper)));
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":33,"column":44},"end":{"line":33,"column":56}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"info-group issue-files"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Files") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + "\">\r\n  <div class=\"header\">\r\n    <i class=\"icon-attachment\"></i> <span class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueView.InfoSidebar.Files.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":56},"end":{"line":3,"column":103}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Files") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":103},"end":{"line":3,"column":154}}})) != null ? stack1 : "")
    + "</span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanEditFiles") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "  </div>\r\n  <ul class=\"content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Files") : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":35,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\r\n</div>";
},"useData":true});

this["Templates"]["IssueInformationSidebar"]["Index"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"chapter pending"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":80}}})) != null ? stack1 : "")
    + "\" data-chapter=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":7,"column":96},"end":{"line":7,"column":103}}}) : helper)))
    + "\">\r\n      "
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":15}}}) : helper)))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "    </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0),"!==",(depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0),{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":17}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          ("
    + alias4(((helper = (helper = lookupProperty(helpers,"RecordedParameterCount") || (depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RecordedParameterCount","hash":{},"data":data,"loc":{"start":{"line":11,"column":11},"end":{"line":11,"column":37}}}) : helper)))
    + " / "
    + alias4(((helper = (helper = lookupProperty(helpers,"RequiredParameterCount") || (depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RequiredParameterCount","hash":{},"data":data,"loc":{"start":{"line":11,"column":40},"end":{"line":11,"column":66}}}) : helper)))
    + ")\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"info-group index\">\r\n  <div class=\"header\">\r\n    <i class=\"icon-list\"></i> <span class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueView.InfoSidebar.Index.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":97}}}))
    + "</span>\r\n  </div>\r\n  <ul class=\"content index\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Chapters") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["IssueInformationSidebar"]["Information"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"icon-pen-angled\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueView.InfoSidebar.Information.EditIssue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":96}}}))
    + "\"></i>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"icon-eye\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueView.InfoSidebar.Information.ShowIssue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":89}}}))
    + "\"></i>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-ou-identifier=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"OrganisationUnit") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"property creation-timestamp\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-clock\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.Creation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":26,"column":39},"end":{"line":26,"column":92}}}))
    + "\">\r\n          </i>\r\n        </div> "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"CreationTimestamp") : stack1),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":28,"column":15},"end":{"line":28,"column":53}}}))
    + "<br/>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Creator") : stack1), depth0))
    + "\r\n      </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"property"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"IsExpired") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":33,"column":60}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-calendar combined-icon\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.Deadline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":35,"column":56},"end":{"line":35,"column":109}}}))
    + "\">\r\n            <i class=\"icon-clock\"></i>\r\n          </i>\r\n        </div> "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"DeadlineTimestamp") : stack1),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":38,"column":15},"end":{"line":38,"column":53}}}))
    + "\r\n      </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " red";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"property"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanEditIssue") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":26},"end":{"line":43,"column":66}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-user\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.Users",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":45,"column":38},"end":{"line":45,"column":88}}}))
    + "\"></i>\r\n        </div> "
    + alias3((lookupProperty(helpers,"UserTitleList")||(depth0 && lookupProperty(depth0,"UserTitleList"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1),{"name":"UserTitleList","hash":{},"data":data,"loc":{"start":{"line":46,"column":15},"end":{"line":46,"column":44}}}))
    + "\r\n      </div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " users";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"property"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanEditIssue") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":26},"end":{"line":51,"column":66}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-users\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.Teams",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":53,"column":39},"end":{"line":53,"column":89}}}))
    + "\"></i>\r\n        </div> "
    + alias3((lookupProperty(helpers,"TeamTitleList")||(depth0 && lookupProperty(depth0,"TeamTitleList"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Teams") : stack1),{"name":"TeamTitleList","hash":{},"data":data,"loc":{"start":{"line":54,"column":15},"end":{"line":54,"column":44}}}))
    + "\r\n      </div>\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    return " teams";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"property form\" data-form-identifier=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-file-text2\" title=\""
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias3).call(alias2,"IssueView.InfoSidebar.Information.Form",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":61,"column":44},"end":{"line":61,"column":93}}}))
    + "\"></i>\r\n        </div> "
    + alias1((lookupProperty(helpers,"UnescapeHTMLEntities")||(depth0 && lookupProperty(depth0,"UnescapeHTMLEntities"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),{"name":"UnescapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":62,"column":15},"end":{"line":62,"column":50}}}))
    + "\r\n      </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"info-group issue-information\">\r\n  <div class=\"header\">\r\n    <i class=\"icon-info\"></i> <span class=\"title\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":103}}}))
    + "</span>\r\n\r\n    <a class=\"action-button size-xl justify-end edit-issue\" href=\"#issue/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":129}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanEditIssue") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "    </a>\r\n  </div>\r\n  <div class=\"content\">\r\n    <div class=\"property\">\r\n      <strong>"
    + alias3((lookupProperty(helpers,"UnescapeHTMLEntities")||(depth0 && lookupProperty(depth0,"UnescapeHTMLEntities"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),{"name":"UnescapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":50}}}))
    + "</strong>\r\n    </div>\r\n    <div class=\"property organisation-unit\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanSeeOUInformation") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":130}}})) != null ? stack1 : "")
    + ">\r\n      <div class=\"icn-wrapper\">\r\n        <i class=\"icon-location\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.OrganisationUnit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":101}}}))
    + "\"></i>\r\n      </div> "
    + alias3((lookupProperty(helpers,"UnescapeHTMLEntities")||(depth0 && lookupProperty(depth0,"UnescapeHTMLEntities"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"OrganisationUnit") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),{"name":"UnescapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":20,"column":13},"end":{"line":20,"column":60}}}))
    + "\r\n    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"ShowCreatorAndTimeInSidebar",{"name":"IfIsEnabled","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":30,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"DeadlineTimestamp") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Teams") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":56,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Form") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":64,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"property form-reference hidden\">\r\n      <div class=\"icn-wrapper\">\r\n        <i class=\"icon-link\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Information.Reference",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":68,"column":36},"end":{"line":68,"column":90}}}))
    + "\"></i>\r\n      </div> <span></span>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});

this["Templates"]["IssueInformationSidebar"]["Sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " inline";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssueInformationSidebarIndex"),depth0,{"name":"IssueInformationSidebarIndex","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"issue-information-sidebar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowInlineMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":73}}})) != null ? stack1 : "")
    + "\">\r\n  <div class=\"content\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueInformationSidebarInformation"),depth0,{"name":"IssueInformationSidebarInformation","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Chapters") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueInformationSidebarTools"),depth0,{"name":"IssueInformationSidebarTools","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueInformationSidebarFiles"),depth0,{"name":"IssueInformationSidebarFiles","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueInformationSidebarStatusQuickSelection"),depth0,{"name":"IssueInformationSidebarStatusQuickSelection","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["IssueInformationSidebar"]["StatusQuickSelection"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"info-group status-quick-selection hidden\">\r\n  <div class=\"header\">\r\n    <i class=\"icon-cogs\"></i> <span class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueView.InfoSidebar.StatusSelection.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":107}}}))
    + "</span>\r\n  </div>\r\n  <ul class=\"content\"></ul>\r\n</div>";
},"useData":true});

this["Templates"]["IssueInformationSidebar"]["Tools"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IsNotRunningOnIOS")||(depth0 && lookupProperty(depth0,"IsNotRunningOnIOS"))||alias2).call(alias1,{"name":"IsNotRunningOnIOS","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":35}}}),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "      <li class=\"scan-barcode\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-barcode\"></i>\r\n        </div> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Tools.ScanBarcode",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":24,"column":15},"end":{"line":24,"column":65}}}))
    + "\r\n      </li>\r\n      <li class=\"search-barcode\">\r\n        <div class=\"icn-wrapper\">\r\n          <i class=\"icon-barcode combined-icon\">\r\n            <i class=\"icon-search\"></i>\r\n          </i>\r\n        </div> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Tools.SearchBarcode",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":31,"column":15},"end":{"line":31,"column":67}}}))
    + "\r\n      </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNfcEnabled") || (depth0 != null ? lookupProperty(depth0,"IsNfcEnabled") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsNfcEnabled","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":19,"column":25}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsNfcEnabled")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"scan-nfc\">\r\n            <div class=\"icn-wrapper\">\r\n              <i class=\"icon-nfc\"></i>\r\n            </div> "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueView.InfoSidebar.Tools.ScanNfc",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":65}}}))
    + "\r\n          </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"info-group tools hidden\">\r\n  <div class=\"header\">\r\n    <i class=\"icon-wrench\"></i> <span class=\"title\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Tools.Headline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":52},"end":{"line":3,"column":99}}}))
    + "</span>\r\n  </div>\r\n  <ul class=\"content\">\r\n    <li class=\"create-action-plan hidden\">\r\n      <div class=\"icn-wrapper\">\r\n        <i class=\"icon-clipboard\"></i>\r\n      </div> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueView.InfoSidebar.Tools.CreateActionPlan",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":68}}}))
    + "\r\n    </li>\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":33,"column":33}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </ul>\r\n</div>";
},"useData":true});

this["Templates"]["IssueViewer"]["ExistingFile"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " has-description";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":54}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"fileTypeIcons "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":73}}}))
    + "\"></div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"file-image",false,true,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":62}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"file-footer clearfix\">\r\n            <span class=\"icon-bin2 delete\"></span>\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"file clearfix\" data-filename=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":53}}}) : helper)))
    + "\" data-mimetype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"MimeType") || (depth0 != null ? lookupProperty(depth0,"MimeType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MimeType","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":82}}}) : helper)))
    + "\">\r\n    <div class=\"file-header clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":78}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"file-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":61}}})) != null ? stack1 : "")
    + "</div>\r\n        <span class=\"icon-info\"></span>\r\n        <span class=\"icon-pen-angled edit-file-information\"></span>\r\n    </div>\r\n\r\n    <div class=\"file-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsImage") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"useData":true});

this["Templates"]["IssueViewer"]["Location"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"secondary-row\">@ "
    + container.escapeExpression((lookupProperty(helpers,"ElementHierarchy")||(depth0 && lookupProperty(depth0,"ElementHierarchy"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Location") : stack1),true,true,{"name":"ElementHierarchy","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":84}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-header"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Properties") : depth0)) != null ? lookupProperty(stack1,"Location") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":77}}})) != null ? stack1 : "")
    + "\">\r\n    <div class=\"row-wrapper\">\r\n        <p class=\"primary-row\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Location") : stack1)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Location") : stack1)) != null ? lookupProperty(stack1,"ParentOID") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Location") : stack1)) != null ? lookupProperty(stack1,"Parent") : stack1),{"name":"And","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>";
},"useData":true});

this["Templates"]["IssueViewer"]["NewFile"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-tmp-uri=\""
    + container.escapeExpression((lookupProperty(helpers,"StripFilenameTimestamp")||(depth0 && lookupProperty(depth0,"StripFilenameTimestamp"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"FileURI") : stack1),{"name":"StripFilenameTimestamp","hash":{},"data":data,"loc":{"start":{"line":1,"column":144},"end":{"line":1,"column":188}}}))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-filename=\""
    + container.escapeExpression((lookupProperty(helpers,"StripFilenameTimestamp")||(depth0 && lookupProperty(depth0,"StripFilenameTimestamp"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),{"name":"StripFilenameTimestamp","hash":{},"data":data,"loc":{"start":{"line":1,"column":238},"end":{"line":1,"column":283}}}))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " has-description";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":82},"end":{"line":3,"column":160}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"StripFilenameTimestamp")||(depth0 && lookupProperty(depth0,"StripFilenameTimestamp"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),{"name":"StripFilenameTimestamp","hash":{},"data":data,"loc":{"start":{"line":3,"column":108},"end":{"line":3,"column":153}}}));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"fileTypeIcons "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"FileURI") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":92}}}))
    + "\"></div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Picture") || (depth0 != null ? lookupProperty(depth0,"Picture") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Picture","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"file unsaved clearfix\" data-mimetype=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1), depth0))
    + "\" data-tmpoid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"FileURI") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":104},"end":{"line":1,"column":196}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":196},"end":{"line":1,"column":291}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"file-header clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Description") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":88}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"file-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"FileInfos") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":167}}})) != null ? stack1 : "")
    + "</div>\r\n        <span class=\"icon-info\"></span>\r\n        <span class=\"icon-pen-angled edit-file-information\"></span>\r\n    </div>\r\n\r\n    <div class=\"file-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"IsImage") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"file-footer clearfix\">\r\n        <span class=\"text\">"
    + alias2((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias3,"IssueViewer.Content.FileUnsaved",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":27},"end":{"line":17,"column":69}}}))
    + "</span>\r\n        <span class=\"icon-bin2 delete\"></span>\r\n    </div>\r\n</li>";
},"useData":true});

this["Templates"]["IssueViewer"]["Responsibility"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),(depth0 != null ? lookupProperty(depth0,"ImagePath") : depth0),{"name":"RoundedImage","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),(lookupProperty(helpers,"GetAlternateImagePath")||(depth0 && lookupProperty(depth0,"GetAlternateImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"GetAlternateImagePath","hash":{},"data":data,"loc":{"start":{"line":6,"column":29},"end":{"line":6,"column":57}}}),{"name":"RoundedImage","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"col-xs-12 col-sm-6 col-lg-4 responsibility\">\r\n    <div class=\"col-sm-3 col-md-4 user-photo-col\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ImagePath") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"col-xs-12 col-sm-9 col-md-8 user-title-col\">\r\n        <span class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":39}}}) : helper))) != null ? stack1 : "")
    + "</span>\r\n    </div>\r\n</li>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["IssueViewer"]["ResponsibilityList"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-xs-12\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Responsible") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Accountable") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Consulted") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Informed") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <u>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Responsible",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":19},"end":{"line":5,"column":46}}}))
    + " ("
    + alias3((lookupProperty(helpers,"Count")||(depth0 && lookupProperty(depth0,"Count"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Responsible") : depth0),{"name":"Count","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":69}}}))
    + "):</u>\r\n                <ul class=\"clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Responsible") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssueResponsibility"),depth0,{"name":"IssueResponsibility","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <u>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Accountable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":19},"end":{"line":13,"column":46}}}))
    + " ("
    + alias3((lookupProperty(helpers,"Count")||(depth0 && lookupProperty(depth0,"Count"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Accountable") : depth0),{"name":"Count","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":69}}}))
    + "):</u>\r\n                <ul class=\"clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Accountable") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":17,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <u>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Consulted",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":19},"end":{"line":21,"column":44}}}))
    + " ("
    + alias3((lookupProperty(helpers,"Count")||(depth0 && lookupProperty(depth0,"Count"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Consulted") : depth0),{"name":"Count","hash":{},"data":data,"loc":{"start":{"line":21,"column":46},"end":{"line":21,"column":65}}}))
    + "):</u>\r\n                <ul class=\"clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Consulted") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":25,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <u>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Informed",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":19},"end":{"line":29,"column":43}}}))
    + " ("
    + alias3((lookupProperty(helpers,"Count")||(depth0 && lookupProperty(depth0,"Count"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Informed") : depth0),{"name":"Count","hash":{},"data":data,"loc":{"start":{"line":29,"column":45},"end":{"line":29,"column":63}}}))
    + "):</u>\r\n                <ul class=\"clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Informed") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":33,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"responsibilities\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["IssueViewer"]["StateText"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsArchived") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.Archived",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":47}}}))
    + "\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.Active",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":45}}}))
    + "\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":36}}}))
    + ((stack1 = (lookupProperty(helpers,"IsLockedState")||(depth0 && lookupProperty(depth0,"IsLockedState"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"IsLockedState","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":113}}})) != null ? stack1 : "")
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " <span class=\"icon-lock\"></span>";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoStateSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":54}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",4,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":9}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["IssueViewer"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssueLocation"),depth0,{"name":"IssueLocation","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"content\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfContainsMany")||(depth0 && lookupProperty(depth0,"IfContainsMany"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),"Title,CustomID,Description,State,Comments","or",{"name":"IfContainsMany","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":79,"column":39}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfContainsMany")||(depth0 && lookupProperty(depth0,"IfContainsMany"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),"Priority,Deadline,Reminding","or",{"name":"IfContainsMany","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":20},"end":{"line":146,"column":39}}})) != null ? stack1 : "")
    + "\r\n                    <div class=\"group clearfix\">\r\n                        <p class=\"header\">\r\n                            <span class=\"icon-users\"></span>\r\n                        </p>\r\n\r\n                        <div class=\"group-content\">\r\n                            <div class=\"row\">\r\n                                <div class=\"item col-xs-12 col-md-6\">\r\n                                    <div class=\"col-xs-12 col-md-4\">\r\n                                        <strong>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Creator",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":157,"column":48},"end":{"line":157,"column":86}}}))
    + "</strong>\r\n                                    </div>\r\n                                    <div class=\"col-xs-12 col-md-8\">\r\n                                        <span>\r\n                                            "
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":161,"column":44},"end":{"line":161,"column":68}}}))
    + "<span class=\"screen-max-md\"><br></span><span class=\"screen-min-lg\"> &bull; </span>"
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":161,"column":150},"end":{"line":161,"column":182}}}))
    + "\r\n                                        </span>\r\n                                    </div>\r\n                                </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Revision") : depth0),">",1,{"name":"Test","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":32},"end":{"line":177,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\r\n\r\n                            <div class=\"row\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Responsibilities","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":181,"column":34},"end":{"line":181,"column":85}}}),(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Contacts","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":181,"column":86},"end":{"line":181,"column":129}}}),(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"ContactGroups","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":181,"column":130},"end":{"line":181,"column":178}}}),{"name":"Or","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":28},"end":{"line":196,"column":35}}})) != null ? stack1 : "")
    + "                            </div>\r\n                        </div>\r\n                    </div>\r\n\r\n                    <div class=\"group clearfix\">\r\n                        <p class=\"header\">\r\n                            <span class=\"icon-price-tags\"></span>\r\n                        </p>\r\n\r\n                        <div class=\"group-content\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfContainsMany")||(depth0 && lookupProperty(depth0,"IfContainsMany"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),"Classifications,Keywords","or",{"name":"IfContainsMany","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":28},"end":{"line":243,"column":47}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"EstimatedEffort","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(64, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":28},"end":{"line":262,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Files","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(69, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":266,"column":20},"end":{"line":318,"column":29}}})) != null ? stack1 : "")
    + "                </div>\r\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"group clearfix\">\r\n                            <p class=\"header\">\r\n                                <span class=\"icon-file-text2\"></span>\r\n                            </p>\r\n\r\n                            <div class=\"group-content\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfContainsMany")||(depth0 && lookupProperty(depth0,"IfContainsMany"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),"Title,CustomID","or",{"name":"IfContainsMany","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":36,"column":51}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Description","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":32},"end":{"line":44,"column":41}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfContainsMany")||(depth0 && lookupProperty(depth0,"IfContainsMany"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),"State,Comments","or",{"name":"IfContainsMany","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":76,"column":51}}})) != null ? stack1 : "")
    + "                            </div>\r\n                        </div>\r\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Title","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":40},"end":{"line":27,"column":49}}})) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"CustomID","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":40},"end":{"line":34,"column":49}}})) != null ? stack1 : "")
    + "                                    </div>\r\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"col-xs-12"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"CustomID","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":129}}})) != null ? stack1 : "")
    + "\">\r\n                                                <input type=\"text\" class=\"form-control issue-title\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":25,"column":107},"end":{"line":25,"column":116}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Title") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":117},"end":{"line":25,"column":179}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":179},"end":{"line":25,"column":227}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsLockedState") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":227},"end":{"line":25,"column":278}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsIssueTitleOverwritten") : depths[1]),{"name":"unless","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":279},"end":{"line":25,"column":438}}})) != null ? stack1 : "")
    + " placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":25,"column":452},"end":{"line":25,"column":488}}}))
    + "\" maxlength=\"100\"/>\r\n                                            </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " col-md-8";
},"11":function(container,depth0,helpers,partials,data) {
    return " readonly=\"readonly\"";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"IsIssueTitleGenerated") : depths[1]),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":317},"end":{"line":25,"column":427}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"AllowOverrideTitleValue") : depths[1]),{"name":"unless","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":350},"end":{"line":25,"column":420}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return " readonly=\"readonly\" ";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"col-xs-12"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Title","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":65},"end":{"line":31,"column":126}}})) != null ? stack1 : "")
    + "\">\r\n                                                <input type=\"text\" class=\"form-control issue-custom-id\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CustomID") : depth0),"!=",(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"Test","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":103},"end":{"line":32,"column":159}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"CustomID") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":159},"end":{"line":32,"column":224}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":224},"end":{"line":32,"column":272}}})) != null ? stack1 : "")
    + " placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.CustomIdent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":286},"end":{"line":32,"column":328}}}))
    + "\" maxlength=\"50\"/>\r\n                                            </div>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    return " col-md-4";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CustomID") || (depth0 != null ? lookupProperty(depth0,"CustomID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CustomID","hash":{},"data":data,"loc":{"start":{"line":32,"column":137},"end":{"line":32,"column":149}}}) : helper)))
    + "\"";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row\">\r\n                                        <div class=\"col-xs-12\">\r\n                                            <textarea class=\"form-control issue-description\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Description") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":92},"end":{"line":41,"column":160}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":160},"end":{"line":41,"column":208}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsLockedState") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":208},"end":{"line":41,"column":259}}})) != null ? stack1 : "")
    + " placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":273},"end":{"line":41,"column":315}}}))
    + "\" rows=\"5\">"
    + alias3((lookupProperty(helpers,"HTMLToNativeLinebreaks")||(depth0 && lookupProperty(depth0,"HTMLToNativeLinebreaks"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"HTMLToNativeLinebreaks","hash":{},"data":data,"loc":{"start":{"line":41,"column":326},"end":{"line":41,"column":364}}}))
    + "</textarea>\r\n                                        </div>\r\n                                    </div>\r\n";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"row\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"State","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":40},"end":{"line":59,"column":49}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Comments","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":40},"end":{"line":74,"column":49}}})) != null ? stack1 : "")
    + "                                    </div>\r\n";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"item col-xs-12 col-md-6\">\r\n                                                <div class=\"col-xs-12 col-md-4\">\r\n                                                    <strong>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueViewer.Content.State",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":51,"column":60},"end":{"line":51,"column":96}}}))
    + "</strong>\r\n                                                </div>\r\n                                                <div class=\"col-xs-12 col-md-8\">\r\n                                                    <button class=\"btn btn-default\" data-property=\"state\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"State") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":125},"end":{"line":54,"column":187}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":187},"end":{"line":54,"column":235}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueViewerStateText"),depth0,{"name":"IssueViewerStateText","data":data,"indent":"                                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                                    </button>\r\n                                                </div>\r\n                                            </div>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"item col-xs-12 col-md-6\">\r\n                                                <div class=\"col-xs-12 col-md-4\">\r\n                                                    <strong>"
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.Comments",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":64,"column":60},"end":{"line":64,"column":99}}}))
    + "</strong>\r\n                                                </div>\r\n                                                <div class=\"item col-xs-12 col-md-8\">\r\n                                                    <div class=\"col-xs-12\">\r\n                                                        <button type=\"button\" class=\"btn btn-default btn-open-chat pull-left is-icon\">\r\n                                                            "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Comments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\r\n                                                        </button>\r\n                                                    </div>\r\n                                                </div>\r\n                                            </div>\r\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"group clearfix\">\r\n                        <p class=\"header\">\r\n                            <span class=\"icon-calendar\"></span>\r\n                        </p>\r\n\r\n                        <div class=\"group-content\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Priority","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":28},"end":{"line":105,"column":37}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfContainsMany")||(depth0 && lookupProperty(depth0,"IfContainsMany"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),"Deadline,Reminding","or",{"name":"IfContainsMany","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":28},"end":{"line":143,"column":47}}})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n";
},"31":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row\">\r\n                                    <div class=\"item col-xs-12 col-md-6\">\r\n                                        <div class=\"col-xs-12 col-md-4\">\r\n                                            <strong>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueViewer.Content.Priority",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":92,"column":52},"end":{"line":92,"column":91}}}))
    + "</strong>\r\n                                        </div>\r\n                                        <div class=\"col-xs-12 col-md-8\">\r\n                                            <button class=\"btn btn-default\" data-property=\"priority\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Priority") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":120},"end":{"line":95,"column":185}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":185},"end":{"line":95,"column":233}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PriorityOID") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.program(34, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":96,"column":48},"end":{"line":100,"column":55}}})) != null ? stack1 : "")
    + "                                            </button>\r\n                                        </div>\r\n                                    </div>\r\n                                </div>\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PriorityOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":97,"column":52},"end":{"line":97,"column":83}}}))
    + "\r\n";
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoPrioritySelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":99,"column":52},"end":{"line":99,"column":101}}}))
    + "\r\n";
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Deadline","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":36},"end":{"line":124,"column":45}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Reminding","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":36},"end":{"line":141,"column":45}}})) != null ? stack1 : "")
    + "                                </div>\r\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"item col-xs-12 col-md-6\">\r\n                                            <div class=\"col-xs-12 col-md-4\">\r\n                                                <strong class=\"deadline-label"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",4,{"name":"Test","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":77},"end":{"line":112,"column":115}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Deadline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":112,"column":117},"end":{"line":112,"column":156}}}))
    + "</strong>\r\n                                            </div>\r\n                                            <div class=\"col-xs-12 col-md-8\">\r\n                                                <button class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",4,{"name":"Test","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":78},"end":{"line":115,"column":116}}})) != null ? stack1 : "")
    + "\" data-property=\"deadline-timestamp\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Deadline") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":172},"end":{"line":115,"column":237}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":237},"end":{"line":115,"column":285}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.program(42, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":116,"column":48},"end":{"line":120,"column":55}}})) != null ? stack1 : "")
    + "                                                </button>\r\n                                            </div>\r\n                                        </div>\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"40":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":117,"column":52},"end":{"line":117,"column":84}}}))
    + "\r\n";
},"42":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoDeadlineSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":119,"column":52},"end":{"line":119,"column":101}}}))
    + "\r\n";
},"44":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"item col-xs-12 col-md-6\">\r\n                                            <div class=\"col-xs-12 col-md-4\">\r\n                                                <strong>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueViewer.Content.RemindingTimestamp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":129,"column":56},"end":{"line":129,"column":105}}}))
    + "</strong>\r\n                                            </div>\r\n                                            <div class=\"col-xs-12 col-md-8\">\r\n                                                <button class=\"btn btn-default\" data-property=\"reminding-timestamp\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Reminding") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":135},"end":{"line":132,"column":201}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":201},"end":{"line":132,"column":249}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RemindingTimestamp") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.program(47, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":133,"column":48},"end":{"line":137,"column":55}}})) != null ? stack1 : "")
    + "                                                </button>\r\n                                            </div>\r\n                                        </div>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RemindingTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":134,"column":52},"end":{"line":134,"column":85}}}))
    + "\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoRemindingTimestampSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":136,"column":52},"end":{"line":136,"column":111}}}))
    + "\r\n";
},"49":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"item col-xs-12 col-md-6\">\r\n                                        <div class=\"col-xs-12 col-md-4\">\r\n                                            <strong>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Editor",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":169,"column":52},"end":{"line":169,"column":89}}}))
    + "</strong>\r\n                                        </div>\r\n                                        <div class=\"col-xs-12 col-md-8\">\r\n                                            <span>\r\n                                                "
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EditorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":173,"column":48},"end":{"line":173,"column":71}}}))
    + "<span class=\"screen-max-md\"><br></span><span class=\"screen-min-lg\"> &bull; </span>"
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":173,"column":153},"end":{"line":173,"column":189}}}))
    + "\r\n                                            </span>\r\n                                        </div>\r\n                                    </div>\r\n";
},"51":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"item user-teams col-xs-12 col-md-6\">\r\n                                    <div class=\"col-xs-12 col-md-4\">\r\n                                        <strong>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Responsibilities",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":184,"column":48},"end":{"line":184,"column":95}}}))
    + "</strong>\r\n                                    </div>\r\n                                    <div class=\"col-xs-12 col-md-8\">\r\n                                        <button class=\"btn btn-default\" data-property=\"responsibilities\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Responsibilities") : stack1),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Contacts") : stack1),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"ContactGroups") : stack1),{"name":"Or","hash":{},"data":data,"loc":{"start":{"line":187,"column":134},"end":{"line":187,"column":220}}}),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":187,"column":124},"end":{"line":187,"column":253}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":187,"column":253},"end":{"line":187,"column":301}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.Modify",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":187,"column":302},"end":{"line":187,"column":339}}}))
    + "</button>\r\n                                    </div>\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueResponsibilityList"),(lookupProperty(helpers,"GetResponsibilities")||(depth0 && lookupProperty(depth0,"GetResponsibilities"))||alias2).call(alias1,depth0,(lookupProperty(helpers,"Array")||(depth0 && lookupProperty(depth0,"Array"))||alias2).call(alias1,"Users","Teams","Contacts","ContactGroups",{"name":"Array","hash":{},"data":data,"loc":{"start":{"line":189,"column":90},"end":{"line":189,"column":140}}}),{"name":"GetResponsibilities","hash":{},"data":data,"loc":{"start":{"line":189,"column":64},"end":{"line":189,"column":142}}}),{"name":"IssueResponsibilityList","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\r\n\r\n                                <div class=\"col-xs-12 col-md-6\">\r\n                                    <div class=\"col-xs-12 col-md-4\"></div>\r\n                                    <div class=\"col-xs-12 col-md-8\"></div>\r\n                                </div>\r\n";
},"53":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"row\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Classifications","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":32},"end":{"line":224,"column":41}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Keywords","in",(depths[1] != null ? lookupProperty(depths[1],"VisibleProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(59, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":226,"column":32},"end":{"line":241,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\r\n";
},"54":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"item col-xs-12 col-md-6\">\r\n                                        <div class=\"col-xs-12 col-md-4\">\r\n                                            <strong>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueViewer.Content.Classifications",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":212,"column":52},"end":{"line":212,"column":98}}}))
    + "</strong>\r\n                                        </div>\r\n                                        <div class=\"col-xs-12 col-md-8\">\r\n                                            <button class=\"btn btn-default\" data-property=\"classifications\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Classifications") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":215,"column":127},"end":{"line":215,"column":199}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":215,"column":199},"end":{"line":215,"column":247}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Classifications") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.program(57, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":216,"column":44},"end":{"line":220,"column":51}}})) != null ? stack1 : "")
    + "                                            </button>\r\n                                        </div>\r\n                                    </div>\r\n";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                "
    + container.escapeExpression((lookupProperty(helpers,"PropertyListString")||(depth0 && lookupProperty(depth0,"PropertyListString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Classifications") : depth0),{"name":"PropertyListString","hash":{},"data":data,"loc":{"start":{"line":217,"column":48},"end":{"line":217,"column":86}}}))
    + "\r\n";
},"57":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoClassificationsSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":219,"column":48},"end":{"line":219,"column":104}}}))
    + "\r\n";
},"59":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"item col-xs-12 col-md-6\">\r\n                                        <div class=\"col-xs-12 col-md-4\">\r\n                                            <strong>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueViewer.Content.Keywords",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":229,"column":52},"end":{"line":229,"column":91}}}))
    + "</strong>\r\n                                        </div>\r\n                                        <div class=\"col-xs-12 col-md-8\">\r\n                                            <button class=\"btn btn-default\" data-property=\"keywords\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Keywords") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":120},"end":{"line":232,"column":185}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":185},"end":{"line":232,"column":233}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Keywords") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0, blockParams, depths),"inverse":container.program(62, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":233,"column":44},"end":{"line":237,"column":51}}})) != null ? stack1 : "")
    + "                                            </button>\r\n                                        </div>\r\n                                    </div>\r\n";
},"60":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                "
    + container.escapeExpression((lookupProperty(helpers,"PropertyListString")||(depth0 && lookupProperty(depth0,"PropertyListString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Keywords") : depth0),{"name":"PropertyListString","hash":{},"data":data,"loc":{"start":{"line":234,"column":48},"end":{"line":234,"column":79}}}))
    + "\r\n";
},"62":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoKeywordsSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":236,"column":48},"end":{"line":236,"column":97}}}))
    + "\r\n";
},"64":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"row\">\r\n                                <div class=\"item col-xs-12 col-md-6\">\r\n                                    <div class=\"col-xs-12 col-md-4\">\r\n                                        <strong>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.EstimatedEffort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":249,"column":48},"end":{"line":249,"column":94}}}))
    + "</strong>\r\n                                    </div>\r\n                                    <div class=\"col-xs-12 col-md-8\">\r\n                                        <button class=\"btn btn-default\" data-property=\"estimatedEffort\" data-action=\"popup\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"EstimatedEffort") : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":252,"column":123},"end":{"line":252,"column":195}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":252,"column":195},"end":{"line":252,"column":243}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsSet")||(depth0 && lookupProperty(depth0,"IsSet"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EstimatedEffort") : depth0),{"name":"IsSet","hash":{},"fn":container.program(65, data, 0, blockParams, depths),"inverse":container.program(67, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":253,"column":40},"end":{"line":257,"column":50}}})) != null ? stack1 : "")
    + "                                        </button>\r\n                                    </div>\r\n                                </div>\r\n                            </div>\r\n";
},"65":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            "
    + container.escapeExpression((lookupProperty(helpers,"EstimatedEffortWithUnit")||(depth0 && lookupProperty(depth0,"EstimatedEffortWithUnit"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"EstimatedEffort") : depth0),{"name":"EstimatedEffortWithUnit","hash":{},"data":data,"loc":{"start":{"line":254,"column":44},"end":{"line":254,"column":87}}}))
    + "\r\n";
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.NoEstimatedEffortSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":256,"column":44},"end":{"line":256,"column":100}}}))
    + "\r\n";
},"69":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"group clearfix no-padding-bottom\">\r\n                            <p class=\"header no-margin-bottom\">\r\n                                <span class=\"icon-attachment\"></span>\r\n                            </p>\r\n\r\n                            <div class=\"group-content\">\r\n                                <div class=\"file-scroller-wrapper\">\r\n                                    <div class=\"file-scroller"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Files") : stack1),"==",null,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":274,"column":81},"end":{"line":274,"column":117}}}),(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Files") : stack1),"===",false,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":274,"column":118},"end":{"line":274,"column":156}}}),{"name":"Or","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":274,"column":61},"end":{"line":274,"column":174}}})) != null ? stack1 : "")
    + "\">\r\n                                        <ul class=\"files\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FileSortPlaceholder"),depth0,{"name":"FileSortPlaceholder","data":data,"indent":"                                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),{"name":"unless","hash":{},"fn":container.program(70, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":278,"column":44},"end":{"line":286,"column":55}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TemporaryFilesMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":288,"column":44},"end":{"line":294,"column":51}}})) != null ? stack1 : "")
    + "                                        </ul>\r\n                                    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"unless","hash":{},"fn":container.program(77, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":298,"column":36},"end":{"line":314,"column":47}}})) != null ? stack1 : "")
    + "                                </div>\r\n                            </div>\r\n                        </div>\r\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Files") : depth0),{"name":"each","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":279,"column":48},"end":{"line":285,"column":57}}})) != null ? stack1 : "");
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsNewTemporaryFile") : depth0),{"name":"unless","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":280,"column":52},"end":{"line":284,"column":63}}})) != null ? stack1 : "");
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssueExistingFile"),depth0,{"name":"IssueExistingFile","data":data,"indent":"                                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FileSortPlaceholder"),depth0,{"name":"FileSortPlaceholder","data":data,"indent":"                                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"74":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"TemporaryFilesMarkup") : depth0),{"name":"each","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":289,"column":48},"end":{"line":293,"column":57}}})) != null ? stack1 : "");
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FileSortPlaceholder"),depth0,{"name":"FileSortPlaceholder","data":data,"indent":"                                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"77":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Properties") : depths[1])) != null ? lookupProperty(stack1,"Files") : stack1),{"name":"if","hash":{},"fn":container.program(78, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":299,"column":40},"end":{"line":313,"column":47}}})) != null ? stack1 : "");
},"78":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(79, data, 0, blockParams, depths),"inverse":container.program(81, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":300,"column":44},"end":{"line":312,"column":73}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"79":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"file-input half-size\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":301,"column":81},"end":{"line":301,"column":129}}})) != null ? stack1 : "")
    + ">\r\n                                                    <span class=\"icon-camera\"></span>\r\n                                                </div>\r\n                                                <div class=\"voice-mail-input\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsReadOnly") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":304,"column":77},"end":{"line":304,"column":125}}})) != null ? stack1 : "")
    + ">\r\n                                                    <span class=\"icon-mic\"></span>\r\n                                                </div>\r\n";
},"81":function(container,depth0,helpers,partials,data) {
    return "                                                <div class=\"file-input\">\r\n                                                    <img class=\"plus-sign\" src=\"./img/plus.svg\" />\r\n                                                    <input type=\"file\" multiple/>\r\n                                                </div>\r\n";
},"83":function(container,depth0,helpers,partials,data) {
    return "                    <li class=\"item-wrapper pull-left hidden\">\r\n                        <div class=\"item quick-select-state\"></div>\r\n                    </li>\r\n";
},"85":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"item-wrapper pull-left\">\r\n                        <button type=\"button\" class=\"btn btn-default flat is-icon btn-show-"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"AssignedFormOID") : stack1),{"name":"if","hash":{},"fn":container.program(86, data, 0),"inverse":container.program(88, data, 0),"data":data,"loc":{"start":{"line":333,"column":91},"end":{"line":333,"column":191}}})) != null ? stack1 : "")
    + "-information\">\r\n                            <span class=\"icon-info\"></span>\r\n                        </button>\r\n                    </li>\r\n";
},"86":function(container,depth0,helpers,partials,data) {
    return "form";
},"88":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"AssignedSchedulingOID") : stack1),{"name":"if","hash":{},"fn":container.program(89, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":333,"column":132},"end":{"line":333,"column":184}}})) != null ? stack1 : "");
},"89":function(container,depth0,helpers,partials,data) {
    return "scheduling";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"issue-viewer-wrapper\">\r\n    <div class=\"fullscreen "
    + alias3(((helper = (helper = lookupProperty(helpers,"WindowClassname") || (depth0 != null ? lookupProperty(depth0,"WindowClassname") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"WindowClassname","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":46}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":80}}})) != null ? stack1 : "")
    + "\" id=\"issue-viewer\">\r\n        <div class=\"window-header\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"IssueViewerHeaderContent"),depth0,{"name":"IssueViewerHeaderContent","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\r\n\r\n        <div class=\"content-wrapper\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,"Location","in",(depth0 != null ? lookupProperty(depth0,"VisibleProperties") : depth0),{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"with","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":320,"column":21}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n        <div class=\"footer\">\r\n            <ul class=\"scroller\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(83, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":325,"column":16},"end":{"line":329,"column":27}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"AssignedFormOID") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"AssignedSchedulingOID") : stack1),{"name":"Or","hash":{},"fn":container.program(85, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":331,"column":16},"end":{"line":337,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <li class=\"item-wrapper\">\r\n                    <button type=\"button\" class=\"btn btn-danger flat btn-abort\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":340,"column":80},"end":{"line":340,"column":101}}}))
    + "</button>\r\n                </li>\r\n                <li class=\"item-wrapper\">\r\n                    <button type=\"button\" class=\"btn btn-success flat btn-save\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":343,"column":80},"end":{"line":343,"column":100}}}))
    + "</button>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["IssueViewer"]["WindowHeaderContent"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"type-abbreviation\">"
    + alias2((lookupProperty(helpers,"IssueTypeAbbreviation")||(depth0 && lookupProperty(depth0,"IssueTypeAbbreviation"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),{"name":"IssueTypeAbbreviation","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":76}}}))
    + "</span>"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "."
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"CustomID") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":114},"end":{"line":3,"column":230}}})) != null ? stack1 : "")
    + "\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"CustomID") : stack1),"!=",((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"Test","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":136},"end":{"line":3,"column":223}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"CustomID") : stack1),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":3,"column":176},"end":{"line":3,"column":213}}}))
    + ")";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Headline") : stack1), depth0)) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"CustomID") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":144}}})) != null ? stack1 : "")
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "</h4>";
},"useData":true});

this["Templates"]["LocationInformation"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal\" id=\"location-information\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"WindowTitle") || (depth0 != null ? lookupProperty(depth0,"WindowTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"WindowTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":68}}}) : helper))) != null ? stack1 : "")
    + "</h4>\r\n            </div>\r\n            <div class=\"modal-body\">\r\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"WindowContent") || (depth0 != null ? lookupProperty(depth0,"WindowContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"WindowContent","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\r\n            </div>\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-default flat btn-close\" data-dismiss=\"modal\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":98},"end":{"line":11,"column":119}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["LoginAdvancedSettings"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"login-advanced-settings\" class=\"center\">\r\n    <div class=\"group\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbLoginSettingsUseAnalytics","LangKey":"Settings.GoogleAnalytics.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbLoginSettingsAdvancedAnalytics","LangKey":"Settings.AdvancedAnalytics.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n        <p class=\"information\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Login.AdvancedSettings.GoogleAnalytics.GuidelineInfo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":94}}}))
    + "</p>\r\n    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["LoginDialog"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\r\n                    <div class=\"col-sm-4\">\r\n                    </div>\r\n\r\n                    <div class=\"col-sm-8\">\r\n                        <label class=\"remember-me pull-left\">\r\n                            <input type=\"checkbox\" id=\"cbRememberMe\"> "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Login.RememberMe",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":64,"column":70},"end":{"line":64,"column":97}}}))
    + "\r\n                        </label>\r\n                    </div>\r\n                </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"office-link\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Login.OfficeLink",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":78,"column":35},"end":{"line":78,"column":62}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"login-container\">\r\n    <div id=\"login\" class=\"center\">\r\n        <img src=\"./img/awenko360_Logo_2c.svg\">\r\n\r\n        <div class=\"row\">\r\n            <div class=\"content\">\r\n                <div class=\"row\">\r\n                    <div class=\"col-sm-4\">\r\n                        <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Login.Username",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":56}}}))
    + ":</label>\r\n                    </div>\r\n\r\n                    <div class=\"col-sm-8\">\r\n                        <input type=\"text\" class=\"form-control\" id=\"txtUsername\" autofocus>\r\n\r\n                        <button class=\"btn flat is-icon pull-right btn-unlock-username hidden\">\r\n                            <span class=\"icon-pen-angled\"></span>\r\n                        </button>\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"row\">\r\n                    <div class=\"col-sm-4\">\r\n                        <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Login.Password",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":31},"end":{"line":23,"column":56}}}))
    + ":</label>\r\n                    </div>\r\n\r\n                    <div class=\"col-sm-8\">\r\n                        <input type=\"password\" class=\"form-control\" id=\"txtPassword\" autofocus>\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"row client-name hidden\">\r\n                    <div class=\"col-sm-4\">\r\n                        <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Login.LoginAt",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":33,"column":31},"end":{"line":33,"column":55}}}))
    + ":</label>\r\n                    </div>\r\n\r\n                    <div class=\"col-sm-8\">\r\n                        <label class=\"client-name\"></label>\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"row baseuri-row hidden\">\r\n                    <div class=\"col-sm-4\">\r\n                        <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Login.BaseURI",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":31},"end":{"line":43,"column":55}}}))
    + ":</label>\r\n                    </div>\r\n\r\n                    <div class=\"col-sm-8\">\r\n                        <button class=\"btn flat is-icon pull-right\">\r\n                            <span class=\"icon-qrcode\"></span>\r\n                        </button>\r\n\r\n                        <button class=\"btn btn-set-baseuri flat is-icon pull-right\">\r\n                            <span class=\"icon-i-cursor\"></span>\r\n                        </button>\r\n                    </div>\r\n                </div>\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":68,"column":48}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n                <button class=\"btn lg-h btn-primary raised btn-block btn-login\" type=\"submit\">\r\n                    <div class=\"status\"></div>\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Login.Login",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":72,"column":20},"end":{"line":72,"column":42}}}))
    + "\r\n                </button>\r\n            </div>\r\n        </div>\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":8},"end":{"line":79,"column":40}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n        <p class=\"show-advanced-settings\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Login.AdvancedSettings.Link",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":81,"column":42},"end":{"line":81,"column":80}}}))
    + "</p>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Menus"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"menu-with-preview flex-container\">\r\n    <div class=\"flex-child-fixed flex-child-scrolling preview\">\r\n        <div class=\"flex-container title-row\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowPreviewSwitch") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"flex-child-dynamic title-container\">\r\n                <span class=\"menu-title\">\r\n                </span>\r\n            </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowPreviewSwitch") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n        <div class=\"preview-container\"></div>\r\n    </div>\r\n    <div class=\"flex-child-dynamic flex-child-scrolling menu-items\">\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"flex-child-fixed switch previous\">\r\n                    <i class=\"arrow left\"></i>\r\n                </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"flex-child-fixed switch next\">\r\n                    <i class=\"arrow right\"></i>\r\n                </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":44}}}))
    + "</p>\r\n        <p class=\"no-content\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":33,"column":41}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </p>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.NoMenuItemsAvailableApp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":55}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.NoMenuItemsAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":52}}}))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"MenuItems") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":56,"column":17}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <li class=\"tasks"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ItemMarker") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":37,"column":68}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"HasFilterActive") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":68},"end":{"line":37,"column":113}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":115},"end":{"line":37,"column":152}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ActionAttribute") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":153},"end":{"line":37,"column":218}}})) != null ? stack1 : "")
    + "\" style=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BackgrountColor") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":227},"end":{"line":37,"column":287}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TextColor") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":287},"end":{"line":37,"column":331}}})) != null ? stack1 : "")
    + "\">\r\n                <div class=\"col-xs-1 icon\" style=\"background-image:url("
    + container.escapeExpression((lookupProperty(helpers,"GetIconUrl")||(depth0 && lookupProperty(depth0,"GetIconUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Icon") : depth0),{"name":"GetIconUrl","hash":{},"data":data,"loc":{"start":{"line":38,"column":71},"end":{"line":38,"column":90}}}))
    + ")\"></div>\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"TiledMainMenuIsEnabled") || (depth0 != null ? lookupProperty(depth0,"TiledMainMenuIsEnabled") : depth0)) != null ? helper : alias2),(options={"name":"TiledMainMenuIsEnabled","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":50,"column":43}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"TiledMainMenuIsEnabled")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowAddButton") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":52,"column":28},"end":{"line":52,"column":99}}})) != null ? stack1 : "")
    + " title\">\r\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":53,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\r\n                </div>\r\n            </li>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ItemMarker") || (depth0 != null ? lookupProperty(depth0,"ItemMarker") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ItemMarker","hash":{},"data":data,"loc":{"start":{"line":37,"column":47},"end":{"line":37,"column":61}}}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    return " filters-active";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-id=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":37,"column":135},"end":{"line":37,"column":143}}}) : helper))) != null ? stack1 : "")
    + "\" ";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-action=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ActionAttribute") || (depth0 != null ? lookupProperty(depth0,"ActionAttribute") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ActionAttribute","hash":{},"data":data,"loc":{"start":{"line":37,"column":190},"end":{"line":37,"column":211}}}) : helper))) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "background:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"BackgrountColor") || (depth0 != null ? lookupProperty(depth0,"BackgrountColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"BackgrountColor","hash":{},"data":data,"loc":{"start":{"line":37,"column":261},"end":{"line":37,"column":280}}}) : helper)));
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ";color:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TextColor") || (depth0 != null ? lookupProperty(depth0,"TextColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TextColor","hash":{},"data":data,"loc":{"start":{"line":37,"column":311},"end":{"line":37,"column":324}}}) : helper)));
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CounterText") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":45,"column":27}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-xs-3 col-lg-1 badge-wrapper"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BadgeCounterAutoWidth") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":67},"end":{"line":42,"column":114}}})) != null ? stack1 : "")
    + "\">\r\n                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CounterText") || (depth0 != null ? lookupProperty(depth0,"CounterText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"CounterText","hash":{},"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":43}}}) : helper)))
    + "\r\n                        </div>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    return " auto-width";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-xs-3 col-lg-1 badge-wrapper"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BadgeCounterAutoWidth") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":63},"end":{"line":47,"column":110}}})) != null ? stack1 : "")
    + "\">\r\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CounterText") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":75}}})) != null ? stack1 : "")
    + "\r\n                    </div>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CounterText") || (depth0 != null ? lookupProperty(depth0,"CounterText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CounterText","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":54}}}) : helper)));
},"32":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"34":function(container,depth0,helpers,partials,data) {
    return "col-xs-6 col-lg-9";
},"36":function(container,depth0,helpers,partials,data) {
    return "col-xs-7 col-lg-10";
},"38":function(container,depth0,helpers,partials,data) {
    return "    </div>\r\n</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EnablePreviewWindow") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":23,"column":7}}})) != null ? stack1 : "")
    + "\r\n<ul class=\"content-selection\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(alias1,depth0,(depth0 != null ? lookupProperty(depth0,"MenuItems") : depth0),{"name":"Or","hash":{},"data":data,"loc":{"start":{"line":26,"column":14},"end":{"line":26,"column":33}}}),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "</ul>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EnablePreviewWindow") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":0},"end":{"line":63,"column":7}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["Menus"]["ContextMenu"]["Menu"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"context-menu\">\r\n    <ul class=\"menu\"></ul>\r\n</div>";
},"useData":true});

this["Templates"]["Menus"]["ContextMenu"]["MenuItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"background-image: url(./img/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"BackgroundImage") || (depth0 != null ? lookupProperty(depth0,"BackgroundImage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"BackgroundImage","hash":{},"data":data,"loc":{"start":{"line":4,"column":63},"end":{"line":4,"column":82}}}) : helper)))
    + ")\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li\r\n    class=\"item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":15},"end":{"line":2,"column":49}}})) != null ? stack1 : "")
    + "\"\r\n    data-cnt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Counter") || (depth0 != null ? lookupProperty(depth0,"Counter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Counter","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":25}}}) : helper)))
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BackgroundImage") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":91}}})) != null ? stack1 : "")
    + "\r\n>"
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":5,"column":1},"end":{"line":5,"column":10}}}) : helper)))
    + "</li>";
},"useData":true});

this["Templates"]["Menus"]["ContextMenu"]["Separator"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<li><hr /></li>";
},"useData":true});

this["Templates"]["Menus"]["DefaultContentHeader"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"secondary-row\">@ "
    + container.escapeExpression((lookupProperty(helpers,"ElementHierarchy")||(depth0 && lookupProperty(depth0,"ElementHierarchy"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,true,true,{"name":"ElementHierarchy","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":74}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-header\">\r\n    <div class=\"row-wrapper\">\r\n        <p class=\"primary-row\"><span class=\"colon\">:</span> "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":71}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ParentOID") : depth0),(depth0 != null ? lookupProperty(depth0,"Parent") : depth0),{"name":"And","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Menus"]["FooterToolbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AlignToContentContainer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + " align-items-left";
},"2":function(container,depth0,helpers,partials,data) {
    return " align-to-content-container";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ReducedView") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "\r\n            <li class=\"item-wrapper state-buttons"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"CanModifyIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":93}}})) != null ? stack1 : "")
    + "\">\r\n                <div class=\"item quick-select-state\"></div>\r\n            </li>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsInspection") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":62,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                <li class=\"item-wrapper\">\r\n                    <div class=\"item show-issue-sidebar\">\r\n                        <span class=\"icon-info\"></span>\r\n                    </div>\r\n                </li>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":53,"column":45}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n                <li class=\"item-wrapper screen-min-sm\">\r\n                    <div class=\"item show-issues\">\r\n                        <span class=\"icon-drawer\"></span>\r\n                        <span class=\"badge\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SubIssueCounter") || (depth0 != null ? lookupProperty(depth0,"SubIssueCounter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SubIssueCounter","hash":{},"data":data,"loc":{"start":{"line":58,"column":44},"end":{"line":58,"column":63}}}) : helper)))
    + "</span>\r\n                        <a></a>\r\n                    </div>\r\n                </li>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"item-wrapper issue-actions"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"CanModifyIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":57},"end":{"line":18,"column":101}}})) != null ? stack1 : "")
    + "\">\r\n                        <div class=\"item drop-up\">\r\n                            <i class=\"icon-hammer combined-icon\">\r\n                                <i class=\"icon-plus\"></i>\r\n                            </i>\r\n\r\n                            <ul class=\"drop-up-content\">\r\n                                <li data-action=\"take-photo\">\r\n                                    <i class=\"icon-camera\"></i>\r\n                                    <span>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Inspections.Toolbar.TakePhoto",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":42},"end":{"line":27,"column":82}}}))
    + "</span>\r\n                                </li>\r\n                                <li data-action=\"record-voice-mail\">\r\n                                    <i class=\"icon-mic\"></i>\r\n                                    <span>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Inspections.Toolbar.RecordVoiceMail",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":31,"column":42},"end":{"line":31,"column":88}}}))
    + "</span>\r\n                                </li>\r\n                                <li data-action=\"create-task\">\r\n                                    <i class=\"icon-hammer\"></i>\r\n                                    <span>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Inspections.Toolbar.CreateTask",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":35,"column":42},"end":{"line":35,"column":83}}}))
    + "</span>\r\n                                </li>\r\n                            </ul>\r\n                        </div>\r\n                    </li>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"item-wrapper add-issue"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"CanModifyIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":53},"end":{"line":41,"column":97}}})) != null ? stack1 : "")
    + "\">\r\n                        <div class=\"item\">\r\n                            <i class=\"icon-hammer combined-icon screen-max-xs\">\r\n                                <i class=\"icon-plus\"></i>\r\n                            </i>\r\n\r\n                            <div class=\"inline-button screen-min-sm\">\r\n                                <i class=\"button-icon icon-plus\"></i>\r\n                                <span class=\"button-caption\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueReport.AddIssue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":49,"column":61},"end":{"line":49,"column":92}}}))
    + "</span>\r\n                            </div>\r\n                        </div>\r\n                    </li>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"item-wrapper switch-view screen-max-xs\">\r\n                <div class=\"item drop-up switch-inspection-view\">\r\n                    <span class=\"icon-file-text2\"></span>\r\n                    <span class=\"badge\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"SubIssueCounter") || (depth0 != null ? lookupProperty(depth0,"SubIssueCounter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SubIssueCounter","hash":{},"data":data,"loc":{"start":{"line":69,"column":40},"end":{"line":69,"column":59}}}) : helper)))
    + "</span>\r\n\r\n                    <ul class=\"drop-up-content\">\r\n                        <li data-view=\"covering-page\">\r\n                            <a>\r\n                                <i class=\"icon-file-text2\"></i>\r\n                                <span>"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Inspections.Toolbar.CoveringPage",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":75,"column":38},"end":{"line":75,"column":81}}}))
    + "</span>\r\n                            </a>\r\n                        </li>\r\n                        <li data-view=\"issues\">\r\n                            <a>\r\n                                <i class=\"icon-drawer\"></i>\r\n                                <span>"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Inspections.Toolbar.Issues",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":81,"column":38},"end":{"line":81,"column":75}}}))
    + "</span>\r\n                                <span class=\"badge\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"SubIssueCounter") || (depth0 != null ? lookupProperty(depth0,"SubIssueCounter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SubIssueCounter","hash":{},"data":data,"loc":{"start":{"line":82,"column":52},"end":{"line":82,"column":71}}}) : helper)))
    + "</span>\r\n                            </a>\r\n                        </li>\r\n                    </ul>\r\n                </div>\r\n            </li>\r\n\r\n            <li class=\"item-wrapper screen-min-sm\">\r\n                <div class=\"item show-covering-page active\">\r\n                    <span class=\"icon-file-text2\"></span>\r\n                    <a></a>\r\n                </div>\r\n            </li>\r\n\r\n            <li class=\"item-wrapper screen-min-sm separator"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowIssueReportButtons") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":59},"end":{"line":96,"column":111}}})) != null ? stack1 : "")
    + "\">\r\n                <div class=\"vertical-line\"></div>\r\n            </li>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ShowIssueReportButtons") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":12},"end":{"line":115,"column":19}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"item-wrapper issue-related add-issue"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowCreateIssue") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":63},"end":{"line":103,"column":108}}})) != null ? stack1 : "")
    + "\">\r\n                    <div class=\"item\">\r\n                        <i class=\"icon-hammer combined-icon screen-max-xs\">\r\n                            <i class=\"icon-plus\"></i>\r\n                        </i>\r\n\r\n                        <div class=\"inline-button screen-min-sm\">\r\n                            <i class=\"button-icon icon-plus\"></i>\r\n                            <span class=\"button-caption\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueReport.AddIssue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":111,"column":57},"end":{"line":111,"column":88}}}))
    + "</span>\r\n                        </div>\r\n                    </div>\r\n                </li>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "            <li class=\"flex-dummy screen-min-sm\"></li>\r\n            <li class=\"item-wrapper cp-filtered-item hidden\">\r\n                <div class=\"item filtered-parameter\">\r\n                    <span class=\"counter\"></span>\r\n                    <sup><span class=\"icon-filter\"></span></sup>\r\n                </div>\r\n            </li>\r\n\r\n            <li class=\"item-wrapper cp-counter-item\">\r\n                <div class=\"item parameter-counter\"></div>\r\n            </li>\r\n\r\n            <li class=\"item-wrapper\">\r\n                <div class=\"item close-view\">\r\n                    <span class=\"icon-cancel-circle\"></span>\r\n                </div>\r\n            </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"footer-toolbar"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssueView") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":142}}})) != null ? stack1 : "")
    + "\">\r\n    <ul class=\"scroller\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssueView") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsInspection") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":99,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssueView") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":8},"end":{"line":116,"column":19}}})) != null ? stack1 : "")
    + "\r\n        <li class=\"item-wrapper issue-related sorting"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowIssueReportButtons") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":53},"end":{"line":118,"column":105}}})) != null ? stack1 : "")
    + "\">\r\n            <div class=\"item screen-max-xs placeholder\">\r\n                <span class=\"icon-sort-alpha-asc\"></span>\r\n            </div>\r\n            <div class=\"item screen-min-sm\">\r\n                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"IssueReport.LastModification",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":123,"column":16},"end":{"line":123,"column":55}}}))
    + " <span class=\"icon-arrow-down\"></span>\r\n            </div>\r\n        </li>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssueView") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":145,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Menus"]["FormContentHeader"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "0";
},"7":function(container,depth0,helpers,partials,data) {
    return " <span class=\"icon-lock\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-header full-width\">\r\n    <a href=\"#issue/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":76}}})) != null ? stack1 : "")
    + "\">\r\n        <p class=\"primary-row issue-info\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.IssueType.Abbreviation.Task",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":55}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":99}}})) != null ? stack1 : "")
    + "."
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + " - "
    + ((stack1 = alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"IsLocked") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":138},"end":{"line":4,"column":199}}})) != null ? stack1 : "")
    + "\r\n        </p>\r\n\r\n        <p class=\"secondary-row\">\r\n            @ "
    + alias3((lookupProperty(helpers,"ElementTitle")||(depth0 && lookupProperty(depth0,"ElementTitle"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"AssignedElementOID") : stack1),{"name":"ElementTitle","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":55}}}))
    + "\r\n        </p>\r\n    </a>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Menus"]["FormSwitchButton"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"form-switcher pull-right\">\r\n    <div class=\"icon-wrapper\" data-direction=\"prev\">\r\n        <span class=\"icon-arrow-left2\"></span>\r\n    </div>\r\n\r\n    <div class=\"text-wrapper\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currentFormIndex") || (depth0 != null ? lookupProperty(depth0,"currentFormIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currentFormIndex","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":50}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"totalFormCount") || (depth0 != null ? lookupProperty(depth0,"totalFormCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalFormCount","hash":{},"data":data,"loc":{"start":{"line":6,"column":51},"end":{"line":6,"column":69}}}) : helper)))
    + "</div>\r\n\r\n    <div class=\"icon-wrapper\" data-direction=\"next\">\r\n        <span class=\"icon-arrow-right2\"></span>\r\n    </div>\r\n</button>";
},"useData":true});

this["Templates"]["Menus"]["IndividualDataContentHeader"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-header\">\r\n    <div class=\"row-wrapper\">\r\n        <p class=\"primary-row\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\r\n        <p class=\"secondary-row\">@ "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Schema") : depth0)) != null ? lookupProperty(stack1,"NamePlural") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Menus"]["InspectionContentHeader"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"secondary-row\">@ "
    + container.escapeExpression((lookupProperty(helpers,"ElementHierarchy")||(depth0 && lookupProperty(depth0,"ElementHierarchy"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Location") : depth0),true,true,{"name":"ElementHierarchy","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":78}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-header inspection-content-header\">\r\n    <div class=\"row-wrapper\">\r\n        <p class=\"primary-row\"><span class=\"colon\">:</span> "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"ParentOID") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Parent") : stack1),{"name":"And","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Menus"]["Pagination"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <nav class=\"pagination\">\r\n        <ul>\r\n"
    + ((stack1 = (lookupProperty(helpers,"For")||(depth0 && lookupProperty(depth0,"For"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),1,(depth0 != null ? lookupProperty(depth0,"Maximum") : depth0),1,{"name":"For","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":20}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </nav>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li data-page=\""
    + alias2(alias1(depth0, depth0))
    + "\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"===",(depths[1] != null ? lookupProperty(depths[1],"CurrentPage") : depths[1]),{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":99}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(depth0, depth0))
    + "</li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " class=\"active\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Maximum") : depth0),">",1,{"name":"Test","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});

this["Templates"]["Menus"]["SyncCenterFooter"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"footer-toolbar flex sync-center\">\r\n    <div class=\"item show-unsynced-entities\">\r\n        <a href=\"#sync-center\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.UnsyncedEntities",true,{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":55}}}))
    + "\r\n        </a>\r\n    </div>\r\n    <div class=\"item show-response-timestamps\">\r\n        <a href=\"#sync-center/timestamps\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.ResponseTimestamps",true,{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":57}}}))
    + "\r\n        </a>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Messagebox"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material\" id=\"message\">\r\n    <div class=\"header\"></div>\r\n\r\n    <div class=\"main-content\"></div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-send-email pull-left icon-mail hidden\" data-dismiss=\"modal\"></button>\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-clear hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Delete",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":96},"end":{"line":8,"column":118}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-abort hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":96},"end":{"line":9,"column":117}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-no hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.No",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":93},"end":{"line":10,"column":111}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-close hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":85},"end":{"line":11,"column":106}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-save hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Save",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":96},"end":{"line":12,"column":116}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-ok hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":94},"end":{"line":13,"column":114}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-yes hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Yes",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":95},"end":{"line":14,"column":114}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Navbar"]["ExpandableNavigation"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"expandable-navigation\">\r\n    <ul class=\"navigation\">\r\n        <li class=\"active main-link\"><a href=\"#main\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ExpandableNavigation.Recording",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":94}}}))
    + "</a></li>\r\n        <li class=\"hidden\"><a href=\"#bluetooth-configuration\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ExpandableNavigation.BluetoothConfiguration",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":116}}}))
    + "</a></li>\r\n        <li class=\"hidden\"><a href=\"#scale-configuration\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ExpandableNavigation.ScaleConfiguration",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":108}}}))
    + "</a></li>\r\n        <li class=\"about-awenko\"><a href=\"#about\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ExpandableNavigation.About",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":87}}}))
    + "</a></li>\r\n        <li><a href=\"#logout\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ExpandableNavigation.Logout",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":68}}}))
    + "</a></li>\r\n    </ul>\r\n</nav>\r\n";
},"useData":true});

this["Templates"]["Navbar"]["ExpandableNavigationIcons"]["HelpLink"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\r\n    <a class=\"navbar-help-link\" href=\"https://www.awenko.de/downloadbereich\" target=\"_blank\">\r\n        "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ExpandableNavigation.HelpLink",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":48}}}))
    + "\r\n    </a>\r\n</li>";
},"useData":true});

this["Templates"]["Navbar"]["ExpandableNavigationIcons"]["ProcessingButton"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\r\n    <div class=\"issue-processing-indicator\">\r\n        <a href=\"#issue-report/progress\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ExpandableNavigation.IssueProcessingState",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":64}}}))
    + "\r\n        </a>\r\n    </div>\r\n</li>\r\n";
},"useData":true});

this["Templates"]["Navbar"]["Sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <a class=\"navbar-help-link\" href=\"https://www.awenko.de/downloadbereich\" target=\"_blank\">\r\n            <img src=\"img/menu/question.svg\" height=\"24\" width=\"24\" />\r\n        </a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"synchronisation default\">\r\n            <a>\r\n                <div class=\"icon-loop2\"></div>\r\n            </a>\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"navbar-content sidebar\">\r\n    <div class=\"user-image\">\r\n        <a href=\"#settings\">\r\n            <img class=\"rounded-image\" />\r\n        </a>\r\n    </div>\r\n\r\n    <a class=\"btn-home\" href=\"#main\">\r\n        <img src=\"img/menu/house.svg\" height=\"24\" width=\"24\" />\r\n    </a>\r\n\r\n    <a class=\"btn-issue-filters\" href=\"#\">\r\n        <img src=\"img/menu/filter.svg\" height=\"24\" width=\"24\" />\r\n    </a>\r\n\r\n    <div class=\"issue-processing-indicator\">\r\n        <a href=\"#issue-report/progress\">\r\n            <img class=\"rounded-image\" src=\"./img/smiley_yellow.svg\" />\r\n        </a>\r\n    </div>\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":26,"column":36}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":34,"column":33}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n    <div class=\"toggle-expandable-navigation\">\r\n        <span class=\"icon-dots-three-vertical\"></span>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Navbar"]["Topbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"synchronisation default\">\r\n            <a>\r\n                <div class=\"icon-loop2\"></div>\r\n            </a>\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <a class=\"navbar-help-link\" href=\"https://www.awenko.de/downloadbereich\" target=\"_blank\">\r\n            <img src=\"img/menu/question.svg\" height=\"24\" width=\"24\" />\r\n        </a>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"navbar-content topbar\">\r\n    <a class=\"btn-home\" href=\"#main\">\r\n        <img src=\"img/menu/house.svg\" height=\"24\" width=\"24\" />\r\n    </a>\r\n\r\n    <a class=\"btn-issue-filters\" href=\"#\">\r\n        <img src=\"img/menu/filter.svg\" height=\"24\" width=\"24\" />\r\n    </a>\r\n\r\n    <div class=\"issue-processing-indicator\">\r\n        <a href=\"#issue-report/progress\">\r\n            <img class=\"rounded-image\" src=\"./img/smiley_yellow.svg\" />\r\n        </a>\r\n    </div>\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":22,"column":33}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":28,"column":36}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n    <div class=\"user-image\">\r\n        <a href=\"#settings\">\r\n            <img class=\"rounded-image\" />\r\n        </a>\r\n    </div>\r\n\r\n    <div class=\"toggle-expandable-navigation\">\r\n        <span class=\"icon-dots-three-vertical\"></span>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["OutdatedVersionWindow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MinAppVersion.PlayStore",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":114},"end":{"line":7,"column":148}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MinAppVersion.AppStore",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":156},"end":{"line":7,"column":189}}}));
},"5":function(container,depth0,helpers,partials,data) {
    return " animated";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material\" id=\"outdatedVersionMessageBox\">\r\n    <div class=\"header\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"MinAppVersion.Outdated.MessageHeader",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":71}}}))
    + "</div>\r\n\r\n    <div class=\"main-content\"></div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-store pull-left\" data-dismiss=\"modal\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsRunningOnAndroid") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":88},"end":{"line":7,"column":196}}})) != null ? stack1 : "")
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-sync icon-loop2"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SyncInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":8,"column":103}}})) != null ? stack1 : "")
    + "\" data-dismiss=\"modal\"></button>\r\n        <button type=\"button\" class=\"btn flat btn-close hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":106}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["ParameterHistoryWindow"]["Recorditem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " category-indicator";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\""
    + container.escapeExpression((lookupProperty(helpers,"RecorditemCategoryColor")||(depth0 && lookupProperty(depth0,"RecorditemCategoryColor"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CategoryOID") : depth0),false,{"name":"RecorditemCategoryColor","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":75}}}))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"recording-deadline-timestamp\">\r\n                    <i class=\"icon-calendar combined-icon\">\r\n                        <i class=\"icon-clock\"></i>\r\n                    </i>\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":52}}}))
    + "\r\n                </span>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " icon-clock ";
},"9":function(container,depth0,helpers,partials,data) {
    return " icon-pen-angled ";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <br /> <span>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Subsample",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":53}}}))
    + ": "
    + alias3(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":22,"column":55},"end":{"line":22,"column":62}}}) : helper)))
    + "</span>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ShowFiles") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"files\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AdditionalFiles") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":43,"column":25}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"file"
    + ((stack1 = (lookupProperty(helpers,"IsImage")||(depth0 && lookupProperty(depth0,"IsImage"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"IsImage","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":34,"column":76}}})) != null ? stack1 : "")
    + "\" data-filename=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":34,"column":93},"end":{"line":34,"column":105}}}) : helper)))
    + "\" data-mimetype=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"MimeType") : depth0), depth0))
    + "\">\r\n                    <span>"
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0))
    + "</span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsImage")||(depth0 && lookupProperty(depth0,"IsImage"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"IsImage","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":41,"column":32}}})) != null ? stack1 : "")
    + "                </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    return " image";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":38,"column":34},"end":{"line":38,"column":61}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"fileTypeIconsSmall "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":40,"column":55},"end":{"line":40,"column":100}}}))
    + "\"></div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"box attachment-icon\">\r\n                <span class=\"icon-attachment\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.AttachmentsAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":47,"column":54},"end":{"line":47,"column":99}}}))
    + "\r\n            </div>\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"comments\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Comments") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":57,"column":21}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemComment"),depth0,{"name":"RecorditemComment","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"workflow-information\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"WorkflowInformation") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalWorkflowInformation") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":72,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"WorkflowInformation") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":64,"column":16},"end":{"line":64,"column":58}}}))
    + "\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"WorkflowInformation") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":70,"column":23}}})) != null ? stack1 : "")
    + "                "
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalWorkflowInformation") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":71,"column":68}}}))
    + "\r\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "                    <br/><br/>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li\r\n    data-recorditemoid=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":31}}}) : helper)))
    + "\"\r\n    class=\"recorditem"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CategoryOID") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":66}}})) != null ? stack1 : "")
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CategoryOID") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":83}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"row record-information clearfix\">\r\n        <div class=\"col-xs-12 user-information col-sm-4\">\r\n            <strong>"
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EditorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":43}}}))
    + "</strong>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsNotNullOrUndefined")||(depth0 && lookupProperty(depth0,"IsNotNullOrUndefined"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"IsNotNullOrUndefined","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":16,"column":37}}})) != null ? stack1 : "")
    + "                <span class=\"recording-modification-timestamp\">\r\n                    <span class=\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(lookupProperty(helpers,"DateTimestamp")||(depth0 && lookupProperty(depth0,"DateTimestamp"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0),{"name":"DateTimestamp","hash":{},"data":data,"loc":{"start":{"line":18,"column":41},"end":{"line":18,"column":78}}}),"===",(lookupProperty(helpers,"DateTimestamp")||(depth0 && lookupProperty(depth0,"DateTimestamp"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),{"name":"DateTimestamp","hash":{},"data":data,"loc":{"start":{"line":18,"column":85},"end":{"line":18,"column":118}}}),{"name":"Test","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":18,"column":33},"end":{"line":18,"column":166}}})) != null ? stack1 : "")
    + "\"></span>\r\n                    "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":56}}}))
    + "\r\n                </span>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTabularSubsample") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n        <div class=\"col-xs-12 col-sm-8 value\">\r\n            "
    + alias3((lookupProperty(helpers,"HistoricalParameterValue")||(depth0 && lookupProperty(depth0,"HistoricalParameterValue"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ElementOID") : depth0),(depth0 != null ? lookupProperty(depth0,"ElementRevisionOID") : depth0),(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"HistoricalParameterValue","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":76}}}))
    + "\r\n        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalFiles") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":50,"column":11}}})) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Comments") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"WorkflowInformation") : depth0),(depth0 != null ? lookupProperty(depth0,"AdditionalWorkflowInformation") : depth0),{"name":"Or","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ParameterHistoryWindow"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li data-tab=\"chart\" class=\"selected\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterHistory.TabControl.HistoryChart",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":66},"end":{"line":12,"column":117}}}))
    + "</li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " class=\"selected\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <ul class=\"chart-mode-button\">\r\n                            <div class=\"dropdown open\">\r\n                                <button class=\"btn btn-default dropdown-toggle\" type=\"button\" id=\"timestamp-dropdown-menu-button\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"true\">\r\n                                    <span data-timestamp=\"creation-time\"><span class=\"icon-clock\"></span>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.CreationTimestamp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":105},"end":{"line":20,"column":153}}}))
    + " </span>\r\n                                    <span class=\"hidden\" data-timestamp=\"deadline-time\"><i class=\"icon-calendar combined-icon\"><i class=\"icon-clock\"></i></i>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Deadline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":157},"end":{"line":21,"column":181}}}))
    + " </span>\r\n                                    <span class=\"caret\"></span>\r\n                                </button>\r\n                                <ul class=\"dropdown-menu dropdown-menu-right chart-mode-list\" aria-labelledby=\"dropdownMenuButton\">\r\n                                    <li class=\"chart-mode-item\" data-timestamp=\"creation-time\">\r\n                                        <a class=\"dropdown-item\"><span class=\"icon-clock\"></span>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.CreationTimestamp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":26,"column":97},"end":{"line":26,"column":145}}}))
    + "</a>\r\n                                    </li>\r\n                                    <li class=\"chart-mode-item\" data-timestamp=\"deadline-time\">\r\n                                        <a class=\"dropdown-item\"><i class=\"icon-calendar combined-icon\"><i class=\"icon-clock\"></i></i>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Deadline",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":134},"end":{"line":29,"column":158}}}))
    + "</a>\r\n                                    </li>\r\n                                </ul>\r\n                            </div>\r\n                        </ul>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <div data-tab=\"chart\">\r\n                                <canvas></canvas>\r\n                        </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " class=\"hidden\" ";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ParameterHistoryWindowRecorditem"),depth0,{"name":"ParameterHistoryWindowRecorditem","hash":{"ShowDeadlineTimestamp":false,"IsTabularSubsample":(depths[1] != null ? lookupProperty(depths[1],"IsTabularSubsample") : depths[1]),"ShowFiles":(depths[1] != null ? lookupProperty(depths[1],"ShowFiles") : depths[1]),"ElementRevisionOID":((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Element") : depths[1])) != null ? lookupProperty(stack1,"RevisionOID") : stack1)},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "                            <div data-timestamp=\"deadline-time\" class=\"hidden\">\r\n                                <ul></ul>\r\n                            </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\"chartjs-tooltip\" class=\"text-nowrap hidden\">\r\n            <table>\r\n                <thead>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":20},"end":{"line":77,"column":29}}})) != null ? stack1 : "")
    + "                </thead>\r\n                <tbody>\r\n                    <tr>\r\n                        <th>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"lines") : stack1), depth0))
    + "</th>\r\n                    </tr>\r\n                </tbody>\r\n            </table>\r\n        </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ChartjsCustomTooltipTimestampRow"),depth0,{"name":"ChartjsCustomTooltipTimestampRow","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fullscreen\" id=\"parameter-history\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + alias3((lookupProperty(helpers,"ElementHierarchy")||(depth0 && lookupProperty(depth0,"ElementHierarchy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Element") : depth0),false,false,2,{"name":"ElementHierarchy","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":93}}}))
    + "</h4>\r\n            </div>\r\n\r\n            <div class=\"modal-body\">\r\n                <div class=\"tab-control\">\r\n                    <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowChart") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":13,"column":31}}})) != null ? stack1 : "")
    + "                        <li data-tab=\"values\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowChart") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":96}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ParameterHistory.TabControl.Values",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":97},"end":{"line":14,"column":142}}}))
    + "</li>\r\n                    </ul>\r\n"
    + ((stack1 = (lookupProperty(helpers,"ArrayContainsProperty")||(depth0 && lookupProperty(depth0,"ArrayContainsProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Recorditems") : depth0),"DeadlineTimestamp",{"name":"ArrayContainsProperty","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":34,"column":46}}})) != null ? stack1 : "")
    + "                </div>\r\n\r\n                <div class=\"content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowChart") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":42,"column":27}}})) != null ? stack1 : "")
    + "\r\n                    <div data-tab=\"values\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowChart") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":43},"end":{"line":44,"column":83}}})) != null ? stack1 : "")
    + ">\r\n                        <div data-timestamp=\"creation-time\">\r\n                            <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Recorditems") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":54,"column":41}}})) != null ? stack1 : "")
    + "                            </ul>\r\n                        </div>\r\n"
    + ((stack1 = (lookupProperty(helpers,"ArrayContainsProperty")||(depth0 && lookupProperty(depth0,"ArrayContainsProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Recorditems") : depth0),"DeadlineTimestamp",{"name":"ArrayContainsProperty","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":61,"column":50}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-default flat btn-close\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":67,"column":98},"end":{"line":67,"column":119}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowChart") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":4},"end":{"line":86,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["BoolSelection"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "_unselected";
},"3":function(container,depth0,helpers,partials,data) {
    return " class=\"desaturate disabled\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"desaturate\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!=",null,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":101}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "desaturate";
},"11":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"13":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",null,{"name":"Test","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":9},"end":{"line":8,"column":136}}})) != null ? stack1 : "")
    + "\r\n          ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\"desaturate "
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"EnableTwoStateCheckbox",{"name":"IfIsEnabled","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":62},"end":{"line":8,"column":125}}})) != null ? stack1 : "")
    + "\" ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\"desaturate"
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"EnableTwoStateCheckbox",{"name":"IfIsEnabled","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":100}}})) != null ? stack1 : "")
    + "\"\r\n        ";
},"19":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\r\n    <img src=\"./img/checked"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",true,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":81}}})) != null ? stack1 : "")
    + ".svg\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Disabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":87},"end":{"line":2,"column":139}}})) != null ? stack1 : "")
    + " width=\"60\" data-value=\"true\" "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",true,{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":169},"end":{"line":2,"column":232}}})) != null ? stack1 : "")
    + " />\r\n    <img src=\"./img/crosschecked"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",false,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":87}}})) != null ? stack1 : "")
    + ".svg\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Disabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":93},"end":{"line":3,"column":145}}})) != null ? stack1 : "")
    + " width=\"60\" data-value=\"false\" "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",false,{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":176},"end":{"line":3,"column":240}}})) != null ? stack1 : "")
    + " />\r\n    <img src=\"./img/unchecked"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsDummy") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":131}}})) != null ? stack1 : "")
    + ".svg\"\r\n     class=\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",null,{"name":"Test","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":65}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"EnableTwoStateCheckbox",{"name":"IfIsEnabled","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":129}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Disabled") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":130},"end":{"line":5,"column":161}}})) != null ? stack1 : "")
    + "\"\r\n        width=\"60\" data-value=\"null\"\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsDummy") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + " />\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Parameters"]["Cell"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":116}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-recorditem-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ParentOID") || (depth0 != null ? lookupProperty(depth0,"ParentOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ParentOID","hash":{},"data":data,"loc":{"start":{"line":5,"column":39},"end":{"line":5,"column":52}}}) : helper)))
    + "\" data-revisionoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RevisionOID") || (depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RevisionOID","hash":{},"data":data,"loc":{"start":{"line":5,"column":72},"end":{"line":5,"column":87}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-row=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":33}}}) : helper)))
    + "\"";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":73}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return " data-isrequired=\"true\"";
},"11":function(container,depth0,helpers,partials,data) {
    return " data-isrecorded=\"true\"";
},"13":function(container,depth0,helpers,partials,data) {
    return " data-hasinformation=\"true\"";
},"15":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"17":function(container,depth0,helpers,partials,data) {
    return " is-historical";
},"19":function(container,depth0,helpers,partials,data) {
    return " category-indicator";
},"21":function(container,depth0,helpers,partials,data) {
    return " temporary-indicator";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\""
    + container.escapeExpression((lookupProperty(helpers,"RecorditemCategoryColor")||(depth0 && lookupProperty(depth0,"RecorditemCategoryColor"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"CategoryOID") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"RecorditemCategoryColor","hash":{},"data":data,"loc":{"start":{"line":11,"column":45},"end":{"line":11,"column":122}}}))
    + "\"";
},"25":function(container,depth0,helpers,partials,data) {
    return " col-sm-6 col-md-6";
},"27":function(container,depth0,helpers,partials,data) {
    return " style=\"border-right:none;\"";
},"29":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"p-2\"><strong class=\"icon-lock\"></strong></div>\r\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"p-2\"><strong class=\"icon-info info-button\"></strong></div>\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":29,"column":47},"end":{"line":29,"column":64}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"PrioritizedParameterFile"),depth0,{"name":"PrioritizedParameterFile","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <iframe class=\"info-text\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"CreateHtmlDataBlob")||(depth0 && lookupProperty(depth0,"CreateHtmlDataBlob"))||alias2).call(alias1,(lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":39,"column":68},"end":{"line":39,"column":88}}}),{"name":"CreateHtmlDataBlob","hash":{},"data":data,"loc":{"start":{"line":39,"column":47},"end":{"line":39,"column":90}}}))
    + "\" frameborder=\"0\"></iframe>\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-xs-11 col-sm-6 col-md-6 value-wrapper\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"ModificationTimestamp") : stack1),{"name":"And","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":47,"column":24}}})) != null ? stack1 : "")
    + "\r\n                <div class=\"box\">\r\n                    <div class=\"value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":50,"column":38},"end":{"line":50,"column":162}}})) != null ? stack1 : "")
    + "\">\r\n                        "
    + ((stack1 = (lookupProperty(helpers,"ParameterCellValue")||(depth0 && lookupProperty(depth0,"ParameterCellValue"))||alias2).call(alias1,depth0,false,(depth0 != null ? lookupProperty(depth0,"IsMasterdata") : depth0),{"name":"ParameterCellValue","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":72}}})) != null ? stack1 : "")
    + "\r\n                    </div>\r\n                </div>\r\n            </div>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"modification-timestamp\">"
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"ModificationTimestamp") : stack1),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":46,"column":54},"end":{"line":46,"column":105}}}))
    + "</p>\r\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Value") : stack1),"!=",null,{"name":"Test","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":50,"column":60},"end":{"line":50,"column":137}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    return "text-right";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":79,"column":15}}})) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"if","hash":{},"fn":container.program(62, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":94,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),(depth0 != null ? lookupProperty(depth0,"AdditionalWorkflowInformation") : depth0),{"name":"Or","hash":{},"fn":container.program(69, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":8},"end":{"line":109,"column":15}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ShowFiles") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":78,"column":19}}})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"files\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"each","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":72,"column":29}}})) != null ? stack1 : "")
    + "                </div>\r\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"file"
    + ((stack1 = (lookupProperty(helpers,"IsImage")||(depth0 && lookupProperty(depth0,"IsImage"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"IsImage","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":36},"end":{"line":63,"column":80}}})) != null ? stack1 : "")
    + "\" data-filename=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":63,"column":97},"end":{"line":63,"column":109}}}) : helper)))
    + "\" data-mimetype=\""
    + alias3(container.lambda((depth0 != null ? lookupProperty(depth0,"MimeType") : depth0), depth0))
    + "\">\r\n                        <span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":64,"column":30},"end":{"line":64,"column":94}}})) != null ? stack1 : "")
    + "</span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsImage")||(depth0 && lookupProperty(depth0,"IsImage"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"IsImage","hash":{},"fn":container.program(56, data, 0),"inverse":container.program(58, data, 0),"data":data,"loc":{"start":{"line":66,"column":24},"end":{"line":70,"column":36}}})) != null ? stack1 : "")
    + "                    </div>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    return " image";
},"52":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Title") : depth0), depth0));
},"54":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Filename") : depth0), depth0));
},"56":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":67,"column":38},"end":{"line":67,"column":65}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n";
},"58":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"fileTypeIconsSmall "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":69,"column":59},"end":{"line":69,"column":104}}}))
    + "\"></div>\r\n";
},"60":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"box attachment-icon\">\r\n                    <span class=\"icon-attachment\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.AttachmentsAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":76,"column":58},"end":{"line":76,"column":103}}}))
    + "\r\n                </div>\r\n";
},"62":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"comments\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"each","hash":{},"fn":container.program(63, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":16},"end":{"line":92,"column":25}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"63":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",3,{"name":"Test","hash":{},"fn":container.program(64, data, 0, blockParams, depths),"inverse":container.program(66, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":85,"column":20},"end":{"line":91,"column":29}}})) != null ? stack1 : "");
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemComment"),depth0,{"name":"RecorditemComment","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AssignmentOID") : depth0),"===",((stack1 = (depths[1] != null ? lookupProperty(depths[1],"LastRecorditem") : depths[1])) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"Test","hash":{},"fn":container.program(67, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":24},"end":{"line":90,"column":33}}})) != null ? stack1 : "");
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemComment"),depth0,{"name":"RecorditemComment","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"workflow-information\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":16},"end":{"line":100,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalWorkflowInformation") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":16},"end":{"line":107,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":99,"column":20},"end":{"line":99,"column":77}}}))
    + "\r\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":105,"column":27}}})) != null ? stack1 : "")
    + "                    "
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalWorkflowInformation") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":106,"column":20},"end":{"line":106,"column":72}}}))
    + "\r\n";
},"73":function(container,depth0,helpers,partials,data) {
    return "                        <br/><br/>\r\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":8},"end":{"line":115,"column":17}}})) != null ? stack1 : "");
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ParameterCellToolbar"),depth0,{"name":"ParameterCellToolbar","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li\r\n    data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":21}}}) : helper)))
    + "\"\r\n    data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":23}}}) : helper)))
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":123}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":95}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":41}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Required") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":80}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsRecorded") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":52}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"HasElementInformation")||(depth0 && lookupProperty(depth0,"HasElementInformation"))||alias2).call(alias1,depth0,{"name":"HasElementInformation","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":38}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":74}}})) != null ? stack1 : "")
    + "\r\n    class=\"checkpoint clearfix"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":91}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsHistorical") : stack1),{"name":"And","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":91},"end":{"line":10,"column":157}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowColorIndicator") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":157},"end":{"line":10,"column":209}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":209},"end":{"line":10,"column":266}}})) != null ? stack1 : "")
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"CategoryOID") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":130}}})) != null ? stack1 : "")
    + "\r\n>\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 parameter-description"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":102}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",111,{"name":"Test","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":103},"end":{"line":14,"column":163}}})) != null ? stack1 : "")
    + ">\r\n            <div class=\"col-xs-12 title-and-description\">\r\n\r\n                <div class=\"title-and-info\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DisableInAppEditing") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":20,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"p-2\"><strong class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":21,"column":59},"end":{"line":21,"column":70}}}) : helper))) != null ? stack1 : "")
    + "</strong></div>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowElementInfoButton") : depth0),(lookupProperty(helpers,"HasElementInformation")||(depth0 && lookupProperty(depth0,"HasElementInformation"))||alias2).call(alias1,depth0,{"name":"HasElementInformation","hash":{},"data":data,"loc":{"start":{"line":22,"column":49},"end":{"line":22,"column":77}}}),{"name":"And","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":28}}})) != null ? stack1 : "")
    + "                </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"HideDescription") : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"ShowPrioritizedFiles",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":34,"column":19},"end":{"line":34,"column":55}}}),(depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0),{"name":"And","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":36,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"ShowInfoInParameterList") : stack1),(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"And","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":40,"column":20}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":55,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":110,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CreateToolbar") : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":4},"end":{"line":116,"column":11}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["Comment"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"selection\">\r\n            <input type=\"checkbox\"\r\n                   class=\"parameter-comment-selection\"\r\n                   data-identifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":43}}}) : helper)))
    + "\"\r\n                   data-parentidentifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SelectionIdentifier") || (depth0 != null ? lookupProperty(depth0,"SelectionIdentifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SelectionIdentifier","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":65}}}) : helper)))
    + "\"\r\n                   checked>\r\n        </div>\r\n        <div class=\"content\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"comment-type\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.ChangeComment",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":70}}}))
    + "</p>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"author\">\r\n            "
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EditorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":35}}}))
    + " &bull; "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0),false,true,{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":90}}}))
    + "\r\n        </p>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"author\">\r\n            "
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":36}}}))
    + " &bull; "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),false,true,{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":23,"column":44},"end":{"line":23,"column":87}}}))
    + "\r\n        </p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),"!==",(depth0 != null ? lookupProperty(depth0,"EditorOID") : depth0),{"name":"Test","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":30,"column":17}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"author editor\">\r\n                "
    + alias3((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EditorOID") : depth0),{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":39}}}))
    + " &bull; "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0),false,true,{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":28,"column":47},"end":{"line":28,"column":94}}}))
    + "\r\n            </p>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"comment\" data-oid=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":38}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsSet")||(depth0 && lookupProperty(depth0,"IsSet"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectionIdentifier") : depth0),{"name":"IsSet","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":11,"column":14}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",3,{"name":"Test","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CreatorOID") : depth0),"===",(depth0 != null ? lookupProperty(depth0,"EditorOID") : depth0),{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":31,"column":13}}})) != null ? stack1 : "")
    + "\r\n    <p class=\"text\">\r\n        "
    + alias3((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Text") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":34,"column":35}}}))
    + "\r\n    </p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsSet")||(depth0 && lookupProperty(depth0,"IsSet"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectionIdentifier") : depth0),{"name":"IsSet","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":39,"column":14}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["Templates"]["Parameters"]["Dummy"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "7";
},"3":function(container,depth0,helpers,partials,data) {
    return "12";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":36}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <iframe class=\"info-text\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"CreateHtmlDataBlob")||(depth0 && lookupProperty(depth0,"CreateHtmlDataBlob"))||alias2).call(alias1,(lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":9,"column":68},"end":{"line":9,"column":88}}}),{"name":"CreateHtmlDataBlob","hash":{},"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":90}}}))
    + "\" frameborder=\"0\"></iframe>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-xs-11 col-sm-11 col-sm-offset-1 col-md-7 col-md-offset-0 col-lg-5 col-lg-offset-0 value-wrapper\">\r\n                <div class=\"box\">\r\n                    <div class=\"value text-right\">\r\n                        "
    + ((stack1 = (lookupProperty(helpers,"ParameterCellValue")||(depth0 && lookupProperty(depth0,"ParameterCellValue"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,true,{"name":"ParameterCellValue","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":58}}})) != null ? stack1 : "")
    + "\r\n                    </div>\r\n                </div>\r\n            </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"checkpoint\">\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-11 col-lg-"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":81}}})) != null ? stack1 : "")
    + " parameter-description\">\r\n            <strong class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":45}}}) : helper))) != null ? stack1 : "")
    + "</strong>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"ShowInfoInParameterList") : stack1),{"name":"And","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":20}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":21,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n</li>\r\n";
},"useData":true});

this["Templates"]["Parameters"]["Files"]["Default"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"file prioritized-file\" data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1), depth0))
    + "\" data-type=\"file\" title=\""
    + alias2((lookupProperty(helpers,"FileTypeString")||(depth0 && lookupProperty(depth0,"FileTypeString"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeString","hash":{},"data":data,"loc":{"start":{"line":1,"column":104},"end":{"line":1,"column":147}}}))
    + "\">\r\n    <div class=\"fileTypeIconsSmall "
    + alias2((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":102}}}))
    + "\"></div>\r\n    <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n</div>";
},"useData":true});

this["Templates"]["Parameters"]["Files"]["Image"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"IsAvailableOffline") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"file image prioritized-file\" data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1), depth0))
    + "\" data-type=\"image\">\r\n            <img src=\""
    + alias2((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":60}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n            <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n        </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"file prioritized-file image online-only\" data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1), depth0))
    + "\" data-type=\"image\">\r\n            <div class=\"icon-cloud-download\"></div>\r\n            <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n        </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"file image prioritized-file\" data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1), depth0))
    + "\" data-type=\"image\">\r\n        <img src=\""
    + alias2((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":56}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n        <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":18,"column":29}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { return stack1; }
  else { return ''; }
},"useData":true});

this["Templates"]["Parameters"]["Files"]["Link"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"fileTypeIconsSmall file-link\"></div>\r\n        <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"file prioritized-file\" data-url=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"URL") : stack1), depth0))
    + "\" data-type=\"file\" title=\"Link\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"WebAddress")||(depth0 && lookupProperty(depth0,"WebAddress"))||alias2).call(alias1,(lookupProperty(helpers,"ResolveFileLinkUrl")||(depth0 && lookupProperty(depth0,"ResolveFileLinkUrl"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"URL") : stack1),{"name":"ResolveFileLinkUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":58}}}),false,{"name":"WebAddress","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":5,"column":19}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["Templates"]["Parameters"]["Files"]["Video"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"file video embedded-video prioritized-file\" data-type=\"file\" data-identifier=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\">\r\n        <div class=\"fileTypeIconsSmall file-video\"></div>\r\n        <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"file video prioritized-file\" data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1), depth0))
    + "\" title=\""
    + alias2((lookupProperty(helpers,"FileTypeString")||(depth0 && lookupProperty(depth0,"FileTypeString"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeString","hash":{},"data":data,"loc":{"start":{"line":7,"column":97},"end":{"line":7,"column":140}}}))
    + "\" data-type=\"file\">\r\n        <div class=\"fileTypeIconsSmall "
    + alias2((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Filename") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"MimeType") : stack1),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":106}}}))
    + "\"></div>\r\n        <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"IsEmbeddedVideo") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["Parameters"]["Files"]["Wrapper"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),"==",1,{"name":"Test","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":15,"column":17}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"PrioritizedLink"),depth0,{"name":"PrioritizedLink","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"IsImage") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"PrioritizedImageFile"),depth0,{"name":"PrioritizedImageFile","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PrioritizedFile") : depth0)) != null ? lookupProperty(stack1,"IsVideo") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"PrioritizedVideoFile"),depth0,{"name":"PrioritizedVideoFile","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"PrioritizedOtherFile"),depth0,{"name":"PrioritizedOtherFile","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ShowPrioritizedFiles",{"name":"IfIsEnabled","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":16}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["Templates"]["Parameters"]["List"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "no-border";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":14,"column":17}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"VisibleGroupsCount") : depth0),"===",0,{"name":"Test","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":19,"column":17}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ParameterGroup"),depth0,{"name":"ParameterGroup","hash":{"ShowElementInfoButton":(depths[1] != null ? lookupProperty(depths[1],"ShowElementInfoButton") : depths[1]),"IssueIsLocked":(depths[1] != null ? lookupProperty(depths[1],"IssueIsLocked") : depths[1]),"Rights":(depths[1] != null ? lookupProperty(depths[1],"Rights") : depths[1]),"ShowFiles":(depths[1] != null ? lookupProperty(depths[1],"ShowFiles") : depths[1]),"Filters":(depths[1] != null ? lookupProperty(depths[1],"Filters") : depths[1]),"NoContentInfo":(depths[1] != null ? lookupProperty(depths[1],"NoContentInfo") : depths[1]),"VisibleGroupsCount":(depths[1] != null ? lookupProperty(depths[1],"VisibleGroupsCount") : depths[1])},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":48}}}))
    + "</p>\r\n            <div class=\"no-content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"NoContentInfo") || (depth0 != null ? lookupProperty(depth0,"NoContentInfo") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"NoContentInfo","hash":{},"data":data,"loc":{"start":{"line":18,"column":36},"end":{"line":18,"column":55}}}) : helper))) != null ? stack1 : "")
    + "</div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":21,"column":44}}}))
    + "</p>\r\n        <div class=\"no-content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"NoContentInfo") || (depth0 != null ? lookupProperty(depth0,"NoContentInfo") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"NoContentInfo","hash":{},"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":22,"column":51}}}) : helper))) != null ? stack1 : "")
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"parameter-list "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"VisibleGroupsCount") : depth0),"===",0,{"name":"Test","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":81}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["ParameterCellToolbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",114,{"name":"Test","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":204}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":1,"column":85},"end":{"line":1,"column":195}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IssueIsLocked") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"Or","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":126},"end":{"line":1,"column":184}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":36,"column":23}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanCreateFiles") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanCreateComments") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":27,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Rights") : depth0)) != null ? lookupProperty(stack1,"CanCreateCorrectiveActions") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                    <div class=\"tool add-picture\" title=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":257}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":17,"column":56}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.Toolbar.AddAdditionalFile.ButtonCaptionApp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":86},"end":{"line":6,"column":153}}}));
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.Toolbar.AddAdditionalFile.ButtonCaptionWeb",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":161},"end":{"line":6,"column":228}}}));
},"14":function(container,depth0,helpers,partials,data) {
    return "                            <i class=\"icon-attachment combined-icon\">\r\n                                <i class=\"icon-plus\"></i>\r\n                            </i>\r\n\r\n                            <input type=\"file\" multiple>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                            <i class=\"icon-camera combined-icon\">\r\n                                <i class=\"icon-plus\"></i>\r\n                            </i>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tool add-comment\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.Toolbar.AddComment.ButtonCaption",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":57},"end":{"line":22,"column":114}}}))
    + "\">\r\n                        <i class=\"icon-bubble combined-icon\">\r\n                            <i class=\"icon-plus\"></i>\r\n                        </i>\r\n                    </div>\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tool add-corrective-action\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.Toolbar.AddCorrectiveAction.ButtonCaption",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":67},"end":{"line":30,"column":133}}}))
    + "\">\r\n                        <i class=\"icon-hammer combined-icon\">\r\n                            <i class=\"icon-plus\"></i>\r\n                        </i>\r\n                    </div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",114,{"name":"Test","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":44,"column":21}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tool scan-code\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ParameterList.Toolbar.ScanCode.ButtonCaption",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":51},"end":{"line":41,"column":106}}}))
    + "\">\r\n                    <i class=\"icon-barcode\"></i>\r\n                </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"toolbar"
    + ((stack1 = (lookupProperty(helpers,"RecordingIsLocked")||(depth0 && lookupProperty(depth0,"RecordingIsLocked"))||alias2).call(alias1,depth0,{"name":"RecordingIsLocked","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":226}}})) != null ? stack1 : "")
    + "\">\r\n    <div class=\"wrapper\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":45,"column":37}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Parameters"]["ParameterGroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "       "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":7},"end":{"line":5,"column":101}}})) != null ? stack1 : "")
    + "\r\n       "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":7},"end":{"line":6,"column":121}}})) != null ? stack1 : "")
    + "\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-requiredparametercount=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"RequiredParameterCount") || (depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"RequiredParameterCount","hash":{},"data":data,"loc":{"start":{"line":5,"column":67},"end":{"line":5,"column":93}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-recordedparametercount=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"RecordedParameterCount") || (depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"RecordedParameterCount","hash":{},"data":data,"loc":{"start":{"line":6,"column":85},"end":{"line":6,"column":111}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    return " data-hasinformation=\"true\"";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-row=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":32}}}) : helper)))
    + "\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"SubsampleCanBeDeleted") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":62},"end":{"line":10,"column":132}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return " can-be-deleted";
},"13":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"15":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"17":function(container,depth0,helpers,partials,data) {
    return " tabular";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":15,"column":39},"end":{"line":15,"column":50}}}) : helper))) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":30}}}) : helper)))
    + ". ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0),"!==",(depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0),{"name":"Test","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":25}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    ("
    + alias4(((helper = (helper = lookupProperty(helpers,"RecordedParameterCount") || (depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RecordedParameterCount","hash":{},"data":data,"loc":{"start":{"line":19,"column":21},"end":{"line":19,"column":47}}}) : helper)))
    + " / "
    + alias4(((helper = (helper = lookupProperty(helpers,"RequiredParameterCount") || (depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RequiredParameterCount","hash":{},"data":data,"loc":{"start":{"line":19,"column":50},"end":{"line":19,"column":76}}}) : helper)))
    + ")\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "            (<span class=\"icon-filter\" style=\"color: red;\"></span>)\r\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsRecordingLockable") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsRecordingLocked") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":33,"column":21}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"recording-lockable "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsRecordingLocked") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":32,"column":48},"end":{"line":32,"column":111}}})) != null ? stack1 : "")
    + "\"></span>\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "icon-lock";
},"34":function(container,depth0,helpers,partials,data) {
    return "icon-lock-open";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"toggle-tabular "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":38,"column":81}}})) != null ? stack1 : "")
    + "\"></span>\r\n\r\n        "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":42,"column":24}}})) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"AlwaysShowAdvancedRecorditemEditor",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":44,"column":18},"end":{"line":44,"column":68}}}),{"name":"unless","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"SubsampleLimitReached") : depth0),{"name":"unless","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":52,"column":19}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    return "icon-table";
},"39":function(container,depth0,helpers,partials,data) {
    return "icon-list";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"SubsampleCanBeDeleted") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":52},"end":{"line":42,"column":15}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    return "\r\n            <span class=\"icon-bin2 delete-subsample\"></span>\r\n        ";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"unless","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":47,"column":23}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"toggle-force-advanced-editor icon-pen-angled"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ForceAdvancedEditor") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":73},"end":{"line":46,"column":114}}})) != null ? stack1 : "")
    + "\"></span>\r\n";
},"46":function(container,depth0,helpers,partials,data) {
    return " active";
},"48":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"icon-plus add-subsample\" data-groupoid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":51,"column":65},"end":{"line":51,"column":72}}}) : helper)))
    + "\"></span>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"icon-info info-button\"></span>\r\n";
},"52":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"TabularSubsample"),depth0,{"name":"TabularSubsample","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"56":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":67,"column":17}}})) != null ? stack1 : "");
},"57":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Enabled") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":66,"column":19}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ParameterCell"),depth0,{"name":"ParameterCell","hash":{"ShowElementInfoButton":(depths[1] != null ? lookupProperty(depths[1],"ShowElementInfoButton") : depths[1]),"IssueIsLocked":(depths[1] != null ? lookupProperty(depths[1],"IssueIsLocked") : depths[1]),"Rights":(depths[1] != null ? lookupProperty(depths[1],"Rights") : depths[1]),"ShowFiles":(depths[1] != null ? lookupProperty(depths[1],"ShowFiles") : depths[1])},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":25}}}) : helper)))
    + "\"\r\n   data-revisionoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RevisionOID") || (depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RevisionOID","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":36}}}) : helper)))
    + "\"\r\n   data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":22}}}) : helper)))
    + "\"\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RecordedParameterCount") : depth0),"!==",(depth0 != null ? lookupProperty(depth0,"RequiredParameterCount") : depth0),{"name":"Test","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":7,"column":12}}})) != null ? stack1 : "")
    + "   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"HasElementInformation")||(depth0 && lookupProperty(depth0,"HasElementInformation"))||alias2).call(alias1,depth0,{"name":"HasElementInformation","hash":{},"data":data,"loc":{"start":{"line":8,"column":9},"end":{"line":8,"column":37}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":3},"end":{"line":8,"column":73}}})) != null ? stack1 : "")
    + "\r\n   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":3},"end":{"line":9,"column":40}}})) != null ? stack1 : "")
    + "\r\n   class=\"groupRow"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":141}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":141},"end":{"line":10,"column":202}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"IfFoldParametergroups")||(depth0 && lookupProperty(depth0,"IfFoldParametergroups"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0),{"name":"IfFoldParametergroups","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":202},"end":{"line":10,"column":283}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTabularSubsample") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":283},"end":{"line":10,"column":324}}})) != null ? stack1 : "")
    + "\">\r\n    <span class=\"title\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTabularSubsample") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Filters") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),(depth0 != null ? lookupProperty(depth0,"IsTabularSubsample") : depth0),{"name":"Or","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowElementInfoButton") : depth0),(lookupProperty(helpers,"HasElementInformation")||(depth0 && lookupProperty(depth0,"HasElementInformation"))||alias2).call(alias1,depth0,{"name":"HasElementInformation","hash":{},"data":data,"loc":{"start":{"line":55,"column":33},"end":{"line":55,"column":61}}}),{"name":"And","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":57,"column":12}}})) != null ? stack1 : "")
    + "</p>\r\n<ul class=\"parameterList"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":59,"column":85}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsRecordingLocked") : depth0),"===",true,{"name":"Test","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":85},"end":{"line":59,"column":141}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTabularSubsample") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.program(56, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":60,"column":4},"end":{"line":68,"column":11}}})) != null ? stack1 : "")
    + "</ul>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["PrintButton"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\r\n    <button type=\"button\" class=\"btn btn-default\" "
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(lookupProperty(helpers,"IsNotSmartDeviceApplication")||(depth0 && lookupProperty(depth0,"IsNotSmartDeviceApplication"))||alias2).call(alias1,{"name":"IsNotSmartDeviceApplication","hash":{},"data":data,"loc":{"start":{"line":2,"column":56},"end":{"line":2,"column":85}}}),(depth0 != null ? lookupProperty(depth0,"Disabled") : depth0),{"name":"Or","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":50},"end":{"line":2,"column":122}}})) != null ? stack1 : "")
    + ">\r\n        "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ParameterList.Print.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":44}}}))
    + "\r\n    </button>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Parameters"]["Review"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":119,"column":33}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"VisibleGroupsCount") : depth0),"===",0,{"name":"Test","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":24},"end":{"line":124,"column":33}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),"!=",true,{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":118,"column":37}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <table>\r\n                                <colgroup>\r\n                                    <col style=\"width: 3rem;\">\r\n                                </colgroup>\r\n                                <tbody>\r\n                                    <tr class=\"group\" data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":48,"column":69},"end":{"line":48,"column":76}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"AnySelectable") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":40},"end":{"line":58,"column":47}}})) != null ? stack1 : "")
    + "                                        <td class=\"title\" colspan=\"2\">\r\n                                            <span class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":60,"column":64},"end":{"line":60,"column":73}}}) : helper)))
    + "</span>\r\n                                        </td>\r\n                                    </tr>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":36},"end":{"line":115,"column":45}}})) != null ? stack1 : "")
    + "                                </tbody>\r\n                            </table>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <td>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsSelectable") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":44},"end":{"line":56,"column":51}}})) != null ? stack1 : "")
    + "                                        </td>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <input type=\"checkbox\"\r\n                                                   class=\"group-selection\"\r\n                                                   data-identifier=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":54,"column":68},"end":{"line":54,"column":75}}}) : helper)))
    + "_"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":54,"column":76},"end":{"line":54,"column":110}}})) != null ? stack1 : "")
    + "\"\r\n                                                   checked>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":54,"column":87},"end":{"line":54,"column":94}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    return "0";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Enabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":40},"end":{"line":114,"column":47}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),"!=",true,{"name":"Test","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":44},"end":{"line":113,"column":53}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <tr data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":67,"column":58},"end":{"line":67,"column":65}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"AnySelectable") : depths[2]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":48},"end":{"line":78,"column":55}}})) != null ? stack1 : "")
    + "                                                <td class=\"title\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"ParameterTitleAndDescription"),depth0,{"name":"ParameterTitleAndDescription","data":data,"indent":"                                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"ShowInfoInParameterList") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":52},"end":{"line":88,"column":59}}})) != null ? stack1 : "")
    + "                                                </td>\r\n                                                <td class=\"value\" data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":90,"column":76},"end":{"line":90,"column":83}}}) : helper)))
    + "\">\r\n                                                    "
    + ((stack1 = (lookupProperty(helpers,"ParameterCellValue")||(depth0 && lookupProperty(depth0,"ParameterCellValue"))||alias2).call(alias1,depth0,false,true,true,{"name":"ParameterCellValue","hash":{},"data":data,"loc":{"start":{"line":91,"column":52},"end":{"line":91,"column":97}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":98},"end":{"line":96,"column":59}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":60},"end":{"line":103,"column":59}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":60},"end":{"line":110,"column":59}}})) != null ? stack1 : "")
    + "                                                </td>\r\n                                            </tr>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <td class=\"selection-cell\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsSelectable") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":52},"end":{"line":76,"column":59}}})) != null ? stack1 : "")
    + "                                                </td>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <input type=\"checkbox\"\r\n                                                           class=\"parameter-selection\"\r\n                                                           data-identifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SelectionIdentifier") || (depth0 != null ? lookupProperty(depth0,"SelectionIdentifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SelectionIdentifier","hash":{},"data":data,"loc":{"start":{"line":73,"column":76},"end":{"line":73,"column":99}}}) : helper)))
    + "\"\r\n                                                           data-parentidentifier=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ParentOID") || (depth0 != null ? lookupProperty(depth0,"ParentOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ParentOID","hash":{},"data":data,"loc":{"start":{"line":74,"column":82},"end":{"line":74,"column":95}}}) : helper)))
    + "_"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":74,"column":96},"end":{"line":74,"column":130}}})) != null ? stack1 : "")
    + "\"\r\n                                                           checked>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":56},"end":{"line":87,"column":63}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                            <div class=\"row\">\r\n                                                                <iframe class=\"info-text\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"CreateHtmlDataBlob")||(depth0 && lookupProperty(depth0,"CreateHtmlDataBlob"))||alias2).call(alias1,(lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":85,"column":116},"end":{"line":85,"column":136}}}),{"name":"CreateHtmlDataBlob","hash":{},"data":data,"loc":{"start":{"line":85,"column":95},"end":{"line":85,"column":138}}}))
    + "\" frameborder=\"0\"></iframe>\r\n                                                            </div>\r\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n                                                    <div class=\"recorditem-value-wrapper\">\r\n                                                        <br /> "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":63},"end":{"line":94,"column":173}}})) != null ? stack1 : "")
    + "\r\n                                                    </div>\r\n                                                    ";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemComment"),depth0,{"name":"RecorditemComment","hash":{"SelectionIdentifier":(depths[1] != null ? lookupProperty(depths[1],"SelectionIdentifier") : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n                                                    <div class=\"recorditem-value-wrapper\">\r\n                                                        <br />\r\n                                                        <div class=\"row workflow-information\">\r\n                                                            "
    + container.escapeExpression((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":100,"column":60},"end":{"line":100,"column":117}}}))
    + "\r\n                                                        </div>\r\n                                                    </div>\r\n                                                    ";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n                                                    <div class=\"recorditem-value-wrapper\">\r\n                                                        <br /> "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"each","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":63},"end":{"line":108,"column":103}}})) != null ? stack1 : "")
    + "\r\n                                                    </div>\r\n";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = (lookupProperty(helpers,"IsImage")||(depth0 && lookupProperty(depth0,"IsImage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"IsImage","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":104},"end":{"line":108,"column":93}}})) != null ? stack1 : "")
    + " ";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"file|image|thumbnail|additional-file",true,false,(depths[1] != null ? lookupProperty(depths[1],"IsSelectable") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"SelectionIdentifier") : depths[1]),{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":106,"column":72},"end":{"line":108,"column":80}}}))
    + " ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":122,"column":44},"end":{"line":122,"column":64}}}))
    + "</p>\r\n                            <div class=\"no-content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"NoContentInfo") || (depth0 != null ? lookupProperty(depth0,"NoContentInfo") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"NoContentInfo","hash":{},"data":data,"loc":{"start":{"line":123,"column":52},"end":{"line":123,"column":71}}}) : helper))) != null ? stack1 : "")
    + "</div>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":126,"column":40},"end":{"line":126,"column":60}}}))
    + "</p>\r\n                        <div class=\"no-content\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"NoContentInfo") || (depth0 != null ? lookupProperty(depth0,"NoContentInfo") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"NoContentInfo","hash":{},"data":data,"loc":{"start":{"line":127,"column":48},"end":{"line":127,"column":67}}}) : helper))) != null ? stack1 : "")
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"issue-review-wrapper\">\r\n    <div class=\"issue-review\">\r\n        <div class=\"header\">\r\n            <span class=\"id\">\r\n                "
    + alias3((lookupProperty(helpers,"IssueTypeAbbreviation")||(depth0 && lookupProperty(depth0,"IssueTypeAbbreviation"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Type") : stack1),{"name":"IssueTypeAbbreviation","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":52}}}))
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "."
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + "\r\n            </span>\r\n            <span class=\"icon cancel icon-cancel-circle\"></span>\r\n        </div>\r\n        <div class=\"scroll-container\">\r\n            <div>\r\n                <span class=\"archived-info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.LastArchived",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":44},"end":{"line":12,"column":72}}}))
    + "</span> \r\n                <span class=\"title\">"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span> \r\n                <span class=\"room\">@ "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Room") : stack1)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n                <br />\r\n                <div class=\"issue-details\">\r\n                    <table>\r\n                        <tbody>\r\n                            <tr>\r\n                                <td>\r\n                                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.CreationTimestamp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":84}}}))
    + ":\r\n                                </td>\r\n                                <td>\r\n                                    "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"CreationTimestamp") : stack1),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":24,"column":36},"end":{"line":24,"column":74}}}))
    + "\r\n                                </td>\r\n                            </tr>\r\n                            <tr>\r\n                                <td>\r\n                                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"IssueViewer.Content.ModificationTimestamp",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":36},"end":{"line":29,"column":88}}}))
    + ":\r\n                                </td>\r\n                                <td>\r\n                                    "
    + alias3((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ModificationTimestamp") : stack1),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":32,"column":78}}}))
    + "\r\n                                </td>\r\n                            </tr>\r\n                        </tbody>\r\n                    </table>\r\n                </div>\r\n                <br />\r\n                <div class=\"parameter-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(30, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":128,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </div>\r\n        </div>\r\n        <div class=\"footer\">\r\n            <span class=\"icon icon-zoom-in\"></span>\r\n            <span class=\"icon icon-zoom-out\"></span>\r\n            <span class=\"flex-dummy\"></span>\r\n            <div class=\"copy-values\">\r\n                <span class=\"icon icon-paste\"></span>\r\n                <span class=\"text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"FormReference.CopyValues",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":138,"column":35},"end":{"line":138,"column":70}}}))
    + "</span>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["TabularCell"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":25}}}) : helper)))
    + "\"\r\n        data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":3,"column":19},"end":{"line":3,"column":27}}}) : helper)))
    + "\"\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":99}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":45}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Required") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":84}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":105}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"HasElementInformation")||(depth0 && lookupProperty(depth0,"HasElementInformation"))||alias2).call(alias1,depth0,{"name":"HasElementInformation","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":42}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":78}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsHistorical") : stack1),{"name":"And","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":17,"column":16}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"===",111,{"name":"Test","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":62}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":57,"column":17}}})) != null ? stack1 : "")
    + "\r\n    </td>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ParentOID") || (depth0 != null ? lookupProperty(depth0,"ParentOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ParentOID","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":56}}}) : helper)))
    + "\" data-revisionoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RevisionOID") || (depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RevisionOID","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":91}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-row=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":37}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":77}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return " data-isrequired=\"true\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":98}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return " data-isrecorded=\"true\"";
},"12":function(container,depth0,helpers,partials,data) {
    return " data-hasinformation=\"true\"";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        class=\"checkpoint is-historical"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),{"name":"Or","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":108}}})) != null ? stack1 : "")
    + "\"\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"17":function(container,depth0,helpers,partials,data) {
    return "";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),{"name":"Or","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        class=\"checkpoint"
    + container.escapeExpression((lookupProperty(helpers,"GetTabularCellVisibilityClass")||(depth0 && lookupProperty(depth0,"GetTabularCellVisibilityClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depth0 != null ? lookupProperty(depth0,"Subsamples") : depth0),{"name":"GetTabularCellVisibilityClass","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":74}}}))
    + "\"\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "        class=\"checkpoint\"\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "rowspan=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Rowspan") || (depth0 != null ? lookupProperty(depth0,"Rowspan") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Rowspan","hash":{},"data":data,"loc":{"start":{"line":18,"column":41},"end":{"line":18,"column":52}}}) : helper)))
    + "\"";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"value-wrapper"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowColorIndicator") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":89}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":89},"end":{"line":20,"column":146}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"CategoryOID") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":147},"end":{"line":20,"column":274}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsAdhoc") : depth0),(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"And","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":24}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"AlwaysShowAdvancedRecorditemEditor",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":25,"column":26},"end":{"line":25,"column":76}}}),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":27,"column":27}}})) != null ? stack1 : "")
    + "\r\n                <div class=\"box\">\r\n                    <div class=\"value text-right\">\r\n                        "
    + ((stack1 = (lookupProperty(helpers,"ParameterCellValue")||(depth0 && lookupProperty(depth0,"ParameterCellValue"))||alias2).call(alias1,depth0,false,(depth0 != null ? lookupProperty(depth0,"IsMasterdata") : depth0),{"name":"ParameterCellValue","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":72}}})) != null ? stack1 : "")
    + "\r\n                    </div>\r\n                </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"AlwaysShowAdvancedRecorditemEditor",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":76}}}),{"name":"unless","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":53,"column":27}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    return " category-indicator";
},"29":function(container,depth0,helpers,partials,data) {
    return " temporary-indicator";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\""
    + container.escapeExpression((lookupProperty(helpers,"RecorditemCategoryColor")||(depth0 && lookupProperty(depth0,"RecorditemCategoryColor"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"CategoryOID") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"IsDummy") : stack1),{"name":"RecorditemCategoryColor","hash":{},"data":data,"loc":{"start":{"line":20,"column":189},"end":{"line":20,"column":266}}}))
    + "\"";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"modification-timestamp\">"
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"ModificationTimestamp") : stack1),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":22,"column":54},"end":{"line":22,"column":105}}}))
    + "</p>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"recorditem-value-wrapper\">\r\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":50,"column":27}}})) != null ? stack1 : "")
    + "\r\n                    </div>\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"Or","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":49,"column":31}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"row edit\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":32},"end":{"line":41,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":44,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"LastRecorditem") : depth0)) != null ? lookupProperty(stack1,"WorkflowInformation") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":47,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "                                    <span class=\"icon-attachment\"></span>\r\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "                                    <span class=\"icon-bubble show-comments\"></span>\r\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "                                    <span class=\"icon-hammer\"></span>\r\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":56,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"!==",111,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":27}}}),(lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),"===",1,{"name":"Test","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":46}}}),{"name":"Or","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":60,"column":7}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["Parameters"]["TabularSubsample"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <col style=\"width: 100px;\" data-width=\"100\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"ColumnWidth") : stack1),{"name":"And","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":13,"column":24}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <col style=\"width: "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"ColumnWidth") : stack1), depth0))
    + "cm;\" class=\"parameterHeader fixed-column-width "
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":120},"end":{"line":10,"column":181}}})) != null ? stack1 : "")
    + "\" data-parameterOID=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":10,"column":202},"end":{"line":10,"column":209}}}) : helper)))
    + "\">\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <col style=\"width: 280px;\" data-width=\"280\" data-originalwidth=\"280\" class=\"parameterHeader"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":111},"end":{"line":12,"column":172}}})) != null ? stack1 : "")
    + "\" data-parameterOID=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":12,"column":193},"end":{"line":12,"column":200}}}) : helper)))
    + "\">\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ShowActionColumn") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <col style=\"width: 40px;\" data-width=\"40\" class=\"table-action-column\">\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"first-column header\"><div class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Subsample",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":25,"column":71},"end":{"line":25,"column":96}}}))
    + "</div></th>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"parameterHeader"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":46},"end":{"line":29,"column":107}}})) != null ? stack1 : "")
    + "\" data-parameterOID=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":29,"column":128},"end":{"line":29,"column":135}}}) : helper)))
    + "\" data-revisionoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RevisionOID") || (depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RevisionOID","hash":{},"data":data,"loc":{"start":{"line":29,"column":155},"end":{"line":29,"column":170}}}) : helper)))
    + "\">\r\n                        <div class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":30,"column":43},"end":{"line":30,"column":54}}}) : helper))) != null ? stack1 : "")
    + "</div>\r\n                    </th>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ShowActionColumn") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":37,"column":27}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "                        <th class=\"table-action-header\"></th>\r\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"TabularSubsampleRow"),depth0,{"name":"TabularSubsampleRow","hash":{"ParentShowActionColumn":(depths[1] != null ? lookupProperty(depths[1],"ShowActionColumn") : depths[1]),"MergedParameters":(depths[1] != null ? lookupProperty(depths[1],"Parameters") : depths[1]),"Parametergroups":(depths[1] != null ? lookupProperty(depths[1],"Parametergroups") : depths[1])},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"subsample-add-footer\" data-groupoid=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||container.hooks.helperMissing).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":49,"column":22},"end":{"line":49,"column":64}}}),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":60,"column":23}}})) != null ? stack1 : "")
    + "\r\n            <span class=\"icon-plus add-subsample\"/>\r\n        </div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0),{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":59,"column":25}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":58,"column":27}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"SubsampleCanBeDeleted") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":57,"column":31}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"delete-subsample\" data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":53,"column":73},"end":{"line":53,"column":80}}}) : helper)))
    + "\" data-row=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":53,"column":92},"end":{"line":53,"column":99}}}) : helper)))
    + "\">\r\n                                <div class=\"icon-bin2\"></div>\r\n                                <div class=\"icon-redo2\"></div>\r\n                            </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sticky-table\">\r\n    <table>\r\n        <colgroup>\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":28}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":14,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":64}}}),{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "        </colgroup>\r\n        <thead>\r\n            <tr>\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":26,"column":32}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":32,"column":25}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":68}}}),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":38,"column":27}}})) != null ? stack1 : "")
    + "            </tr>\r\n        </thead>\r\n        <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parametergroups") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":44,"column":17}}})) != null ? stack1 : "")
    + "        </tbody>\r\n    </table>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"SubsampleLimitReached") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["TabularSubsampleRow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"SubsampleCanBeDeleted") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":124},"end":{"line":1,"column":194}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return " can-be-deleted";
},"6":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-row=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":1,"column":367},"end":{"line":1,"column":374}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <th class=\"first-column\">\r\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Row") || (depth0 != null ? lookupProperty(depth0,"Row") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Row","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":19}}}) : helper)))
    + "<br />\r\n            <div class=\"tabularGroupControls\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsRecordingLockable") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":15,"column":25}}})) != null ? stack1 : "")
    + "            </div>\r\n        </th>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsRecordingLocked") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":29}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"recording-lockable"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsRecordingLocked") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":8,"column":55},"end":{"line":8,"column":120}}})) != null ? stack1 : "")
    + "\"></span>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " icon-lock";
},"15":function(container,depth0,helpers,partials,data) {
    return " icon-lock-open";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"SubsampleCanBeDeleted") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":14,"column":27}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"icon-bin2 delete-subsample\"></div>\r\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"TabularParameterCell"),depth0,{"name":"TabularParameterCell","hash":{"Subsamples":(depths[1] != null ? lookupProperty(depths[1],"MergedParameters") : depths[1]),"Rowspan":((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Parametergroups") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ParentShowActionColumn") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td class=\"table-action-content\">\r\n                <div class=\"tabularGroupControls\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsRecordingLockable") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0)) != null ? lookupProperty(stack1,"SubsampleCanBeDeleted") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":33,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </td>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"subsample-row"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsRecordingLocked") : depth0),"===",true,{"name":"Test","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":80}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalSettings") : depth0),"!==",undefined,{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":80},"end":{"line":1,"column":203}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsFiltered") : depth0),(depth0 != null ? lookupProperty(depth0,"IsHidden") : depth0),(depth0 != null ? lookupProperty(depth0,"MissingRequirements") : depth0),{"name":"Or","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":204},"end":{"line":1,"column":264}}})) != null ? stack1 : "")
    + "\" data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":281},"end":{"line":1,"column":288}}}) : helper)))
    + "\" data-revisionoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RevisionOID") || (depth0 != null ? lookupProperty(depth0,"RevisionOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RevisionOID","hash":{},"data":data,"loc":{"start":{"line":1,"column":308},"end":{"line":1,"column":323}}}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":1,"column":336},"end":{"line":1,"column":344}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Row") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":346},"end":{"line":1,"column":381}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":18,"column":20}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"TabularSubsampleShowNumber",{"name":"IfIsEnabled","hash":{},"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":24,"column":56}}}),{"name":"unless","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "</tr>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Parameters"]["TitleAndDescription"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":48}}}) : helper))) != null ? stack1 : "")
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":1,"column":33}}}) : helper))) != null ? stack1 : "")
    + "</strong>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"HideDescription") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":7,"column":11}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["PasswordEditor"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"success\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material password-editor\">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":39}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.CurrentPassword",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":64}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <input type=\"password\" data-id=\"current-password\" />\r\n            </div>\r\n        </div>\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Password",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":23},"end":{"line":18,"column":57}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <input type=\"password\" data-id=\"password\" />\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n                <label>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.PasswordConfirmation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":23},"end":{"line":28,"column":69}}}))
    + "</label>\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <input type=\"password\" data-id=\"password-confirmation\" />\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row clearfix\">\r\n            <div class=\"col-xs-12 col-md-5\">\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-md-7\">\r\n                <span class=\"strength-indicator\" style=\"background-color: #fff;\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Complexity.NoPassword",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":67}}}))
    + "\r\n                </span>\r\n            </div>\r\n        </div>\r\n\r\n        <ul class=\"policies\">\r\n            <li data-policy=\"does-not-contain-each-other\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"DoesNotContainEachOther") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":57},"end":{"line":48,"column":120}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.DoesNotContainEachOther",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":48,"column":121},"end":{"line":48,"column":179}}}))
    + "</li>\r\n            <li data-policy=\"matches-confirmation\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"MatchesConfirmation") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":50},"end":{"line":49,"column":109}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.MatchesConfirmation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":49,"column":110},"end":{"line":49,"column":164}}}))
    + "</li>\r\n            <li data-policy=\"is-length-sufficient\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"IsLengthSufficent") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":50},"end":{"line":50,"column":107}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.LengthIsSufficient",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":50,"column":108},"end":{"line":50,"column":161}}}))
    + "</li>\r\n            <li data-policy=\"contains-lowercase-letters\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"ContainsLowerCaseLetters") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":56},"end":{"line":51,"column":120}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.ContainsLowerCaseLetters",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":51,"column":121},"end":{"line":51,"column":180}}}))
    + "</li>\r\n            <li data-policy=\"contains-uppercase-letters\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"ContainsUpperCaseLetters") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":56},"end":{"line":52,"column":120}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.ContainsUpperCaseLetters",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":52,"column":121},"end":{"line":52,"column":180}}}))
    + "</li>\r\n            <li data-policy=\"contains-digits\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"ContainsDigits") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":45},"end":{"line":53,"column":99}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.ContainsDigits",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":53,"column":100},"end":{"line":53,"column":149}}}))
    + "</li>\r\n            <li data-policy=\"contains-special-characters\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Policies") : depth0)) != null ? lookupProperty(stack1,"ContainsSpecialCharacters") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":57},"end":{"line":54,"column":122}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"PasswordEditor.Policies.ContainsSpecialCharacters",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":54,"column":123},"end":{"line":54,"column":183}}}))
    + "</li>\r\n        </ul>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-success\" disabled=\"disabled\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Save",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":59,"column":79},"end":{"line":59,"column":99}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["PrintWindow"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":39}}}) : helper)))
    + "\">"
    + alias1(container.lambda(depth0, depth0))
    + "</option>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material print-window\">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Printer.PrintLabel",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":37}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content view-1\">\r\n        <div class=\"info-text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Printer.PrinterSelection",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":66}}}))
    + "</div>\r\n        <select class=\"form-control printer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Printer") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":11,"column":21}}})) != null ? stack1 : "")
    + "        </select>\r\n        <br />\r\n        <div class=\"info-text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Printer.LabelCount",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":31},"end":{"line":14,"column":60}}}))
    + "</div>\r\n\r\n        <div class=\"input-group\">\r\n            <span class=\"input-group-addon sub\">-</span>\r\n            <input type=\"number\" inputmode=\"numeric\" class=\"form-control input label-count\" value=\"1\" step=\"1\">\r\n            <span class=\"input-group-addon add\">+</span>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"main-content view-2 hidden\">\r\n        <div class=\"info-text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Printer.PrinterSelection",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":66}}}))
    + " <span class=\"printer\"></span></div>\r\n        <br />\r\n        <div class=\"calculatedvalues\"></div>\r\n        <br />\r\n        <div class=\"info-text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Printer.RemainingLabels",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":31},"end":{"line":28,"column":65}}}))
    + " <b class=\"remaining-labels\">0</b></div>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-abort\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":89},"end":{"line":32,"column":110}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-ok\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":33,"column":87},"end":{"line":33,"column":107}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-print hidden\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Print",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":34,"column":97},"end":{"line":34,"column":118}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["RecorditemEditor"]["AdditionalFile"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " temporary-file";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-filename=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":48}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-tmpoid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":36}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-mimetype=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"MimeType") || (depth0 != null ? lookupProperty(depth0,"MimeType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"MimeType","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":48}}}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    return " has-description";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":54}}}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":62},"end":{"line":8,"column":97}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":8,"column":78},"end":{"line":8,"column":90}}}) : helper)));
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"file-image",false,true,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":62}}}))
    + "\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"fileTypeIcons "
    + container.escapeExpression((lookupProperty(helpers,"FileTypeClass")||(depth0 && lookupProperty(depth0,"FileTypeClass"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"FileTypeClass","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":73}}}))
    + "\"></div>\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueViewer.Content.FileUnsaved",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":31},"end":{"line":23,"column":73}}}))
    + "</span>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"icon-bin2 pull-right delete\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li\r\n    class=\"file"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":15},"end":{"line":2,"column":56}}})) != null ? stack1 : "")
    + " clearfix\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":56}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":44}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MimeType") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":56}}})) != null ? stack1 : "")
    + "\r\n>\r\n    <div class=\"file-header clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":36},"end":{"line":7,"column":78}}})) != null ? stack1 : "")
    + "\">\r\n        <div class=\"file-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":104}}})) != null ? stack1 : "")
    + "</div>\r\n        <span class=\"icon-info\"></span>\r\n        <span class=\"icon-pen-angled edit-file-information\"></span>\r\n    </div>\r\n\r\n    <div class=\"file-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsImage") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"file-footer clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"UserHasRight")||(depth0 && lookupProperty(depth0,"UserHasRight"))||container.hooks.helperMissing).call(alias1,"DRF",true,{"name":"UserHasRight","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":28,"column":25}}})) != null ? stack1 : "")
    + "    </div>\r\n</li>";
},"useData":true});

this["Templates"]["RecorditemEditor"]["AdditionalFileRow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":95},"end":{"line":11,"column":177}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { return stack1; }
  else { return ''; }
},"8":function(container,depth0,helpers,partials,data) {
    return " no-pointer-events";
},"10":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":25,"column":33}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":22,"column":35}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FileSortPlaceholder"),depth0,{"name":"FileSortPlaceholder","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemAdditionalFile"),depth0,{"name":"RecorditemAdditionalFile","hash":{"IsBase64":(depth0 != null ? lookupProperty(depth0,"IsBase64") : depth0),"IsTemporary":(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),"FileContent":(depth0 != null ? lookupProperty(depth0,"Content") : depth0),"Type":(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"OID":(depth0 != null ? lookupProperty(depth0,"OID") : depth0)},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemAdditionalFile"),depth0,{"name":"RecorditemAdditionalFile","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":37,"column":27}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "                    <input type=\"file\" multiple/>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"file-row clearfix\">\r\n    <div class=\"input-group additional-files"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":44},"end":{"line":2,"column":78}}})) != null ? stack1 : "")
    + "\">\r\n        <span class=\"input-group-addon\">\r\n            <i class=\"icon-attachment\"></i>\r\n\r\n            <span class=\"badge"
    + ((stack1 = (lookupProperty(helpers,"NAnd")||(depth0 && lookupProperty(depth0,"NAnd"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"NAnd","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":109}}})) != null ? stack1 : "")
    + "\">\r\n                "
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0),{"name":"And","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":66}}})) != null ? stack1 : "")
    + "\r\n            </span>\r\n        </span>\r\n\r\n        <div class=\"form-control"
    + ((stack1 = (lookupProperty(helpers,"NAnd")||(depth0 && lookupProperty(depth0,"NAnd"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),{"name":"NAnd","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":186}}})) != null ? stack1 : "")
    + "\">\r\n            <div class=\"file-scroller"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":37},"end":{"line":12,"column":71}}})) != null ? stack1 : "")
    + "\">\r\n                <ul class=\"files\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FileSortPlaceholder"),depth0,{"name":"FileSortPlaceholder","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"And","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":26,"column":28}}})) != null ? stack1 : "")
    + "                </ul>\r\n            </div>\r\n        </div>\r\n\r\n        <span class=\"input-group-addon clickable\">\r\n            <img src=\"./img/plus.svg\">\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":38,"column":44}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </span>\r\n    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["RecorditemEditor"]["AdditionalInformation"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.ShowComments",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":60}}}))
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.AddNewComment",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":61}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"CorrectiveActions") : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":45,"column":37}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemCorrectiveAction"),depth0,{"name":"RecorditemCorrectiveAction","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AdditionalCorrectiveActions") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":51,"column":39}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"info empty\">\r\n                                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.AddNewCorrectiveAction",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":49,"column":32},"end":{"line":49,"column":82}}}))
    + "\r\n                            </li>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AdditionalCorrectiveActions") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":57,"column":37}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"AdditionalFileRow"),depth0,{"name":"AdditionalFileRow","hash":{"SortedFiles":(depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n<div class=\"clearfix\">\r\n    <div class=\"col-xs-12 col-md-6 comments-wrapper\">\r\n        <div class=\"input-group comment-section\">\r\n            <span class=\"input-group-addon\">\r\n                <span class=\"icon-bubble\"></span>\r\n\r\n                <span class=\"badge"
    + ((stack1 = (lookupProperty(helpers,"NAnd")||(depth0 && lookupProperty(depth0,"NAnd"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"NAnd","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":106}}})) != null ? stack1 : "")
    + "\">\r\n                    "
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"And","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":121}}})) != null ? stack1 : "")
    + "\r\n                </span>\r\n            </span>\r\n\r\n            <div class=\"form-control\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),{"name":"And","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":19,"column":24}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <span class=\"input-group-addon clickable\">\r\n                <img src=\"./img/plus.svg\" />\r\n            </span>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"col-xs-12 col-md-6 actions-wrapper\">\r\n        <div class=\"input-group corrective-actions"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":50},"end":{"line":29,"column":84}}})) != null ? stack1 : "")
    + "\">\r\n            <span class=\"input-group-addon\">\r\n                <i class=\"icon-hammer\"></i>\r\n\r\n                <span class=\"badge"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"CorrectiveActionsCount") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":34},"end":{"line":33,"column":86}}})) != null ? stack1 : "")
    + "\">\r\n                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CorrectiveActionsCount") || (depth0 != null ? lookupProperty(depth0,"CorrectiveActionsCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"CorrectiveActionsCount","hash":{},"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":46}}}) : helper)))
    + "\r\n                </span>\r\n            </span>\r\n\r\n            <div class=\"form-control\">\r\n                <div class=\"row\">\r\n                    <div class=\"col-xs-12\">\r\n                        <ul class=\"corrective-action-list\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"CorrectiveActions") : stack1),{"name":"And","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":52,"column":32}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalCorrectiveActions") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":24},"end":{"line":58,"column":31}}})) != null ? stack1 : "")
    + "                        </ul>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n\r\n            <span class=\"input-group-addon clickable\">\r\n                <img src=\"./img/plus.svg\" />\r\n            </span>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["RecorditemEditor"]["AdditionalInformationSmall"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":14,"column":31}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                        <input type=\"file\" multiple/>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"small-information file-row clearfix\">\r\n    <div class=\"files-wrapper col-xs-4 col-md-2 col-md-offset-3\">\r\n        <div class=\"input-group additional-files"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":82}}})) != null ? stack1 : "")
    + "\">\r\n            <span class=\"input-group-addon\">\r\n                <i class=\"icon-attachment\"></i>\r\n            </span>\r\n\r\n            <span class=\"input-group-addon clickable\">\r\n                <img src=\"./img/plus.svg\" />\r\n\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":15,"column":48}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </span>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"comments-wrapper small col-xs-4 col-md-2\">\r\n        <div class=\"input-group comment-section\">\r\n            <span class=\"input-group-addon\">\r\n                <span class=\"icon-bubble\"></span>\r\n            </span>\r\n\r\n            <span class=\"input-group-addon clickable\">\r\n                <img src=\"./img/plus.svg\" />\r\n            </span>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"actions-wrapper small col-xs-4 col-md-2\">\r\n        <div class=\"input-group corrective-actions"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":50},"end":{"line":33,"column":84}}})) != null ? stack1 : "")
    + "\">\r\n            <span class=\"input-group-addon\">\r\n                <i class=\"icon-hammer\"></i>\r\n            </span>\r\n\r\n            <span class=\"input-group-addon clickable\">\r\n                <img src=\"./img/plus.svg\" />\r\n            </span>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["RecorditemEditor"]["AddNewPhotoHint"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"info\">\r\n    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.AddNewPhoto",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":43}}}))
    + "\r\n</li>";
},"useData":true});

this["Templates"]["RecorditemEditor"]["Bool"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "desaturate";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data) {
    return "_unselected";
},"7":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\r\n    <div data-value=\"true\" class=\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",true,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":87}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":87},"end":{"line":2,"column":121}}})) != null ? stack1 : "")
    + "\"><img src=\"./img/checked"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",true,{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":146},"end":{"line":2,"column":200}}})) != null ? stack1 : "")
    + ".svg\" width=\"60\" /></div>\r\n    <div data-value=\"false\" class=\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",false,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":89}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":89},"end":{"line":3,"column":123}}})) != null ? stack1 : "")
    + "\"><img src=\"./img/crosschecked"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!==",false,{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":153},"end":{"line":3,"column":208}}})) != null ? stack1 : "")
    + ".svg\" width=\"60\" /></div>\r\n    <div data-value=\"null\" class=\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!=",null,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":86}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"IfIsEnabled")||(depth0 && lookupProperty(depth0,"IfIsEnabled"))||alias2).call(alias1,"EnableTwoStateCheckbox",{"name":"IfIsEnabled","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":87},"end":{"line":4,"column":150}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":150},"end":{"line":4,"column":184}}})) != null ? stack1 : "")
    + "\"><img src=\"./img/unchecked"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),"!=",null,{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":211},"end":{"line":4,"column":264}}})) != null ? stack1 : "")
    + ".svg\" width=\"60\" /></div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["RecorditemEditor"]["CorrectiveAction"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":29}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-oid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":66}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"is-temporary\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\"#issue/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":62}}})) != null ? stack1 : "")
    + "?type="
    + alias3(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":76}}}) : helper)))
    + "\" class=\"start-corrective-action-recording\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RecorditemEditor.JumpToIssueRecording",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":127},"end":{"line":5,"column":175}}}))
    + "\">\r\n            <i class=\"icon-clipboard combined-icon\">\r\n                <i class=\"icon-pen-angled\"></i>\r\n            </i>\r\n        </a>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":40}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":55}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsTemporary") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":121}}})) != null ? stack1 : "")
    + ">\r\n    <span class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"UnescapeHTMLEntities")||(depth0 && lookupProperty(depth0,"UnescapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"UnescapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":54}}}))
    + "</span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"in","2,3,6,7,8",{"name":"Test","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "</li>";
},"useData":true});

this["Templates"]["RecorditemEditor"]["CorrectiveActionInfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"AdditionalClasses") || (depth0 != null ? lookupProperty(depth0,"AdditionalClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AdditionalClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":62}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"info"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":69}}})) != null ? stack1 : "")
    + "\">\r\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Text") || (depth0 != null ? lookupProperty(depth0,"Text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Text","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":12}}}) : helper)))
    + "\r\n</li> ";
},"useData":true});

this["Templates"]["RecorditemEditor"]["DateTime"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"FormattedPreviousValue") || (depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"FormattedPreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":77},"end":{"line":1,"column":103}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-timestamp=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":1,"column":145},"end":{"line":1,"column":158}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"7":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"text\" class=\"form-control\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":111}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":166}}})) != null ? stack1 : "")
    + " placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":180},"end":{"line":1,"column":206}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":1,"column":208},"end":{"line":1,"column":270}}})) != null ? stack1 : "")
    + " />";
},"useData":true});

this["Templates"]["RecorditemEditor"]["Default"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":109},"end":{"line":1,"column":126}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"text\" class=\"form-control\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":79}}}))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":80},"end":{"line":1,"column":134}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":134},"end":{"line":1,"column":179}}})) != null ? stack1 : "")
    + " />";
},"useData":true});

this["Templates"]["RecorditemEditor"]["EMailAddresses"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"value\" data-value=\""
    + alias2(alias1(depth0, depth0))
    + "\">\r\n            "
    + alias2(alias1(depth0, depth0))
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"IsReadonly") : depths[1]),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":23}}})) != null ? stack1 : "")
    + "        </li>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"delete-value\">&times;</div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"value-input\">\r\n            <input type=\"text\" autocomplete=\"off\" spellcheck=\"false\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.EMailAddresses.Placeholder",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":82},"end":{"line":14,"column":136}}}))
    + "\" />\r\n        </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"multi-value-input form-control"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":75}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "</ul>";
},"useData":true,"useDepths":true});

this["Templates"]["RecorditemEditor"]["IndividualData"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-val=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":80},"end":{"line":1,"column":97}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"FormattedPreviousValue") || (depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FormattedPreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":106},"end":{"line":1,"column":132}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"text\" class=\"form-control\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":140}}})) != null ? stack1 : "")
    + " placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":154},"end":{"line":1,"column":180}}}))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":1,"column":181},"end":{"line":1,"column":243}}})) != null ? stack1 : "")
    + "/>";
},"useData":true});

this["Templates"]["RecorditemEditor"]["Line"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input\r\n        type=\"text\"\r\n        class=\"form-control\"\r\n        placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":47}}}))
    + "\"\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":62}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":53}}})) != null ? stack1 : "")
    + "\r\n    />\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":54}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input\r\n            type=\"text\"\r\n            class=\"form-control\"\r\n            placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":25},"end":{"line":14,"column":51}}}))
    + "\"\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":66}}})) != null ? stack1 : "")
    + "\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":57}}})) != null ? stack1 : "")
    + "\r\n        />\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group\">\r\n            <input\r\n                    type=\"text\"\r\n                    class=\"form-control\"\r\n                    placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":59}}}))
    + "\"\r\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":74}}})) != null ? stack1 : "")
    + "\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":57}}})) != null ? stack1 : "")
    + "\r\n            />\r\n\r\n            <span class=\"input-group-addon is-button\">\r\n                    <div class=\"icn-wrapper\">\r\n                        <button type=\"button\" class=\"btn btn-default flat btn-select-from-suggested-values is-icon\">\r\n                            <span class=\"icon-list\"></span>\r\n                        </button>\r\n                    </div>\r\n                </span>\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"SuggestedValues") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":37,"column":11}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["RecorditemEditor"]["ListBox"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " inline";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"MapStructureToImages") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li data-value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":40}}}) : helper)))
    + "\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"PreviousValue") : depths[1]),"===",(data && lookupProperty(data,"key")),{"name":"Test","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":104}}})) != null ? stack1 : "")
    + ">\r\n                    <img src=\""
    + alias3((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||alias2).call(alias1,depth0,{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":48}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                </li>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " class=\"selected\"";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li data-value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":40}}}) : helper)))
    + "\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"PreviousValue") : depths[1]),"===",(data && lookupProperty(data,"key")),{"name":"Test","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":41},"end":{"line":9,"column":104}}})) != null ? stack1 : "")
    + ">"
    + alias3(container.lambda(depth0, depth0))
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"selection"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MapStructureToImages") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":62}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + "\" data-multi=\"false\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Structure") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "</ul>";
},"useData":true,"useDepths":true});

this["Templates"]["RecorditemEditor"]["LocationCode"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-raw-value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":119},"end":{"line":1,"column":136}}}) : helper)))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"FormattedPreviousValue") || (depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"FormattedPreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":184},"end":{"line":1,"column":210}}}) : helper)))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"\r\n    ";
},"7":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":20,"column":33}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    <br />\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-default raised btn-start-scanner\">\r\n            <i class=\"icon-qrcode\"></i>&nbsp;"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RecorditemEditor.StartScanner",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":85}}}))
    + "\r\n        </button>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IsNotRunningOnIOS")||(depth0 && lookupProperty(depth0,"IsNotRunningOnIOS"))||alias2).call(alias1,{"name":"IsNotRunningOnIOS","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":37}}}),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":15,"column":19}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNfcEnabled") || (depth0 != null ? lookupProperty(depth0,"IsNfcEnabled") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsNfcEnabled","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":29}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsNfcEnabled")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-default raised btn-start-nfc-scanner\">\r\n                    <i class=\"icon-nfc\"></i>&nbsp;"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.StartScanner",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":50},"end":{"line":12,"column":90}}}))
    + "\r\n                </button>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-default raised btn-start-scanner\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.SelectLocation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":54}}}))
    + "\r\n        </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"text\" class=\"form-control\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":79}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":145}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":146},"end":{"line":1,"column":219}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":1,"column":220},"end":{"line":2,"column":28}}})) != null ? stack1 : "")
    + " /><br>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":22,"column":11}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["RecorditemEditor"]["Memo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <textarea\r\n        class=\"form-control\"\r\n        placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":47}}}))
    + "\"\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":53}}})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":5,"column":71}}}) : helper)))
    + "</textarea>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <textarea\r\n            class=\"form-control\"\r\n            placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":25},"end":{"line":10,"column":51}}}))
    + "\"\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":57}}})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":11,"column":58},"end":{"line":11,"column":75}}}) : helper)))
    + "</textarea>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group\">\r\n            <textarea\r\n                class=\"form-control\"\r\n                placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":55}}}))
    + "\"\r\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":61}}})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":17,"column":62},"end":{"line":17,"column":79}}}) : helper)))
    + "</textarea>\r\n\r\n            <span class=\"input-group-addon is-button\">\r\n                <div class=\"icn-wrapper\">\r\n                    <button type=\"button\" class=\"btn btn-default flat btn-select-from-suggested-values is-icon\">\r\n                        <span class=\"icon-list\"></span>\r\n                    </button>\r\n                </div>\r\n            </span>\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"SuggestedValues") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":11}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["RecorditemEditor"]["MultiListBox"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " inline";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"MapStructureToImages") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li data-value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":40}}}) : helper)))
    + "\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(data && lookupProperty(data,"key")),"in",(depths[1] != null ? lookupProperty(depths[1],"PreviousValue") : depths[1]),{"name":"Test","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":103}}})) != null ? stack1 : "")
    + ">\r\n                    <img src=\""
    + alias3((lookupProperty(helpers,"ImagePath")||(depth0 && lookupProperty(depth0,"ImagePath"))||alias2).call(alias1,depth0,{"name":"ImagePath","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":48}}}))
    + "\" crossorigin=\"use-credentials\" />\r\n                </li>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " class=\"selected\"";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li data-value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":40}}}) : helper)))
    + "\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(data && lookupProperty(data,"key")),"in",(depths[1] != null ? lookupProperty(depths[1],"PreviousValue") : depths[1]),{"name":"Test","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":41},"end":{"line":9,"column":103}}})) != null ? stack1 : "")
    + ">"
    + alias3(container.lambda(depth0, depth0))
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"selection"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MapStructureToImages") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":62}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + "\" data-multi=\"true\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Structure") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "</ul>";
},"useData":true,"useDepths":true});

this["Templates"]["RecorditemEditor"]["Number"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"input-group\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BluetoothIsAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ScaleIsAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <input\r\n            type=\"number\"\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsNotRunningOnIOS") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "            step=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Step") || (depth0 != null ? lookupProperty(depth0,"Step") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Step","hash":{},"data":data,"loc":{"start":{"line":24,"column":18},"end":{"line":24,"column":26}}}) : helper)))
    + "\"\r\n            class=\"form-control\"\r\n            placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":26,"column":25},"end":{"line":26,"column":51}}}))
    + "\"\r\n            "
    + ((stack1 = (lookupProperty(helpers,"IfNotNull")||(depth0 && lookupProperty(depth0,"IfNotNull"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"IfNotNull","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":80}}})) != null ? stack1 : "")
    + "\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":57}}})) != null ? stack1 : "")
    + " />\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Unit") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"input-group-addon\">\r\n                <span class=\"icon-bluetooth\"></span>\r\n            </span>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"input-group-addon scale\">\r\n                <span class=\"icon-drive\"></span>\r\n            </span>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                inputmode=\"numeric\"\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                inputmode=\"text\"\r\n                pattern=\"^[\\d .,+-]+$\"\r\n                autocorrect=\"off\"\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":27,"column":48},"end":{"line":27,"column":65}}}) : helper)))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"input-group-addon\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Unit") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input\r\n        type=\"number\"\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsNotRunningOnIOS") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "        step=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Step") || (depth0 != null ? lookupProperty(depth0,"Step") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Step","hash":{},"data":data,"loc":{"start":{"line":44,"column":14},"end":{"line":44,"column":22}}}) : helper)))
    + "\"\r\n        class=\"form-control\"\r\n        placeholder=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":46,"column":21},"end":{"line":46,"column":47}}}))
    + "\"\r\n        "
    + ((stack1 = (lookupProperty(helpers,"IfNotNull")||(depth0 && lookupProperty(depth0,"IfNotNull"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"IfNotNull","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":47,"column":76}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":48,"column":53}}})) != null ? stack1 : "")
    + " />\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "            inputmode=\"numeric\"\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "            inputmode=\"text\"\r\n            pattern=\"^[\\d .,+-]+$\"\r\n            autocorrect=\"off\"\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Unit") : depth0),(depth0 != null ? lookupProperty(depth0,"BluetoothIsAvailable") : depth0),(depth0 != null ? lookupProperty(depth0,"ScaleIsAvailable") : depth0),{"name":"Or","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":49,"column":7}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["RecorditemEditor"]["Photo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " src=\""
    + container.escapeExpression((lookupProperty(helpers,"FixIOSFilepath")||(depth0 && lookupProperty(depth0,"FixIOSFilepath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"FixIOSFilepath","hash":{},"data":data,"loc":{"start":{"line":2,"column":109},"end":{"line":2,"column":141}}}))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-filename=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":2,"column":181},"end":{"line":2,"column":193}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":22,"column":33}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n    <div class=\"btn btn-default raised btn-mark-picture disabled hidden\">\r\n        <span class=\"icon-brush\"></span>\r\n        <span class=\"text\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RecorditemEditor.Photo.EditMarks",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":55}}}))
    + "\r\n        </span>\r\n    </div>\r\n\r\n    <br />\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-default raised btn-take-picture\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Overwrite") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "        </button>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.Photo.Overwrite",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":59}}}))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.Photo.TakeNew",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":57}}}))
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"btn btn-default raised btn-take-picture\">\r\n            <input type=\"file\" accept=\"image/*\" />\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Overwrite") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.Photo.SelectNew",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":59}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"recorditem-image\">\r\n    <img class=\"recorditem-value-image"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":81}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":82},"end":{"line":2,"column":149}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Filename") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":149},"end":{"line":2,"column":201}}})) != null ? stack1 : "")
    + "/>\r\n</div>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":32,"column":11}}})) != null ? stack1 : "");
},"useData":true});

this["Templates"]["RecorditemEditor"]["ScanCode"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input type=\"text\" class=\"form-control\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":57},"end":{"line":2,"column":83}}}))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":84},"end":{"line":2,"column":138}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":2,"column":138},"end":{"line":2,"column":200}}})) != null ? stack1 : "")
    + " /><br>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":9,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":2,"column":113},"end":{"line":2,"column":130}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"6":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-default raised btn-start-scanner\">\r\n            <i class=\"icon-qrcode\"></i>&nbsp;"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.StartScanner",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":85}}}))
    + "\r\n        </button>\r\n        <br />\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input type=\"text\" class=\"form-control\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":83}}}))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":84},"end":{"line":11,"column":138}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":138},"end":{"line":11,"column":183}}})) != null ? stack1 : "")
    + " />\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":29}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { return stack1; }
  else { return ''; }
},"useData":true});

this["Templates"]["RecorditemEditor"]["Signature"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"signature\">\r\n        <div class=\"tab-control\">\r\n            <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowImageUpload") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <li data-tab=\"canvas\" class=\"selected\">\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"RecorditemEditor.SignatureTabControl.Sign",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":72}}}))
    + "\r\n                </li>\r\n            </ul>\r\n        </div>\r\n    </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":10,"column":31}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li data-tab=\"file-input\">\r\n                            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.SignatureTabControl.FileInput",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":85}}}))
    + "\r\n                        </li>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " no-border";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":32,"column":19}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-tab=\"file-input\" class=\"hidden\">\r\n            <img class=\"recorditem-value-image hidden\" />\r\n\r\n            <button class=\"btn btn-default raised btn-take-picture\">\r\n                <input type=\"file\" accept=\"image/*\" />\r\n                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.Photo.SelectNew",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":59}}}))
    + "\r\n            </button>\r\n        </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"signature-image\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":37,"column":46},"end":{"line":37,"column":63}}}) : helper)))
    + "\" />\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"signature-input"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":39},"end":{"line":39,"column":73}}})) != null ? stack1 : "")
    + "\"></div>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":45,"column":23}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <hr class=\"signature-line\" />\r\n                <button class=\"btn btn-default btn-clear-signature raised\">\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Reset",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":41}}}))
    + "\r\n                </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":19,"column":32}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n<div class=\"content";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":19},"end":{"line":21,"column":87}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":33,"column":36}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n    <div data-tab=\"canvas\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"And","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":46,"column":16}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["RecorditemEditor"]["TelephoneNumber"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":108},"end":{"line":1,"column":125}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"tel\" class=\"form-control\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":78}}}))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":133}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":133},"end":{"line":1,"column":178}}})) != null ? stack1 : "")
    + " />";
},"useData":true});

this["Templates"]["RecorditemEditor"]["Users"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-raw-value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PreviousValue") || (depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":130},"end":{"line":1,"column":147}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"FormattedPreviousValue") || (depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"FormattedPreviousValue","hash":{},"data":data,"loc":{"start":{"line":1,"column":193},"end":{"line":1,"column":219}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"7":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"text\" class=\"form-control user-value\" placeholder=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.Unrecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":90}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":92},"end":{"line":1,"column":155}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FormattedPreviousValue") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":155},"end":{"line":1,"column":227}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":1,"column":227},"end":{"line":1,"column":289}}})) != null ? stack1 : "")
    + " />";
},"useData":true});

this["Templates"]["RecorditemEditor"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"description\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Description") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"Comments") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"CorrectiveActions") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)) != null ? lookupProperty(stack1,"AdditionalFiles") : stack1),(depth0 != null ? lookupProperty(depth0,"AdditionalCorrectiveActions") : depth0),{"name":"NOr","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":18,"column":28}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemAdditionalInformationSmall"),depth0,{"name":"RecorditemAdditionalInformationSmall","hash":{"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0)},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"RecorditemAdditionalInformation"),depth0,{"name":"RecorditemAdditionalInformation","hash":{"SortedFiles":(depth0 != null ? lookupProperty(depth0,"SortedFiles") : depth0),"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),"PreviousRecorditem":(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0)},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                            <button type=\"button\" class=\"btn btn-danger btn-delete flat\"><span class=\"icon-bin2\"></span></button>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-success btn-save flat\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":88},"end":{"line":39,"column":108}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal\" id=\"recorditem-editor\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</h4>\r\n            </div>\r\n\r\n            <div class=\"modal-body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Element") : depth0)) != null ? lookupProperty(stack1,"Description") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsSimplified") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n                <div class=\"row\" style=\"float:none\">\r\n                    <span>\r\n                        <button type=\"button\" class=\"btn btn-default flat btn-history is-icon\">\r\n                            <span class=\"icon-history\"></span>\r\n                        </button>\r\n                        <button type=\"button\" class=\"btn btn-default flat btn-information is-icon\">\r\n                            <span class=\"icon-info\"></span>\r\n                        </button>\r\n                    </span>\r\n                    <span style=\"float:right\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IsRecorditemDeletionAllowed")||(depth0 && lookupProperty(depth0,"IsRecorditemDeletionAllowed"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"DisableDeleteOption") : depth0),(depth0 != null ? lookupProperty(depth0,"Element") : depth0),(depth0 != null ? lookupProperty(depth0,"PreviousRecorditem") : depth0),{"name":"IsRecorditemDeletionAllowed","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":35,"column":56}}})) != null ? stack1 : "")
    + "                        <button type=\"button\" class=\"btn btn-danger btn-abort flat\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":36,"column":84},"end":{"line":36,"column":105}}}))
    + "</button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":40,"column":35}}})) != null ? stack1 : "")
    + "                    </span>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["Reports"]["IssueCell"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li\r\n        class=\"issue-cell "
    + alias4(((helper = (helper = lookupProperty(helpers,"IssueCellMarkerClass") || (depth0 != null ? lookupProperty(depth0,"IssueCellMarkerClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IssueCellMarkerClass","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":50}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsArchived") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":87}}})) != null ? stack1 : "")
    + "\"\r\n        data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":25}}}) : helper)))
    + "\"\r\n        data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":23}}}) : helper)))
    + "\"\r\n        data-issuetype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":32}}}) : helper)))
    + "\"\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AssignedFormOID") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":73}}})) != null ? stack1 : "")
    + "\r\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AssignedSchedulingOID") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":91}}})) != null ? stack1 : "")
    + "\r\n    >\r\n            <div class=\"row\">\r\n                <div class=\"type-icon\">\r\n                    "
    + alias4((lookupProperty(helpers,"IssueTypeIndicator")||(depth0 && lookupProperty(depth0,"IssueTypeIndicator"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"IssueTypeIndicator","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":47}}}))
    + "\r\n                </div>\r\n\r\n                <div class=\"col-xs-12 col-md-11 issue-content\">\r\n                    <a href=\"#issue/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":74}}})) != null ? stack1 : "")
    + "?type="
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":16,"column":80},"end":{"line":16,"column":88}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"IsInfoBoard") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":88},"end":{"line":16,"column":133}}})) != null ? stack1 : "")
    + "\" class=\"clearfix\">\r\n                        <div class=\""
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowFiles") : depth0),(lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Images") : depth0),(lookupProperty(helpers,"GetPreviewImage")||(depth0 && lookupProperty(depth0,"GetPreviewImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalData") : depth0)) != null ? lookupProperty(stack1,"CheckpointPreviewImages") : stack1),{"name":"GetPreviewImage","hash":{},"data":data,"loc":{"start":{"line":17,"column":64},"end":{"line":17,"column":120}}}),{"name":"Or","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":121}}}),{"name":"And","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.program(16, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":157}}})) != null ? stack1 : "")
    + " left-side\">\r\n                            <p class=\"title\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(20, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":25,"column":43}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsLocked") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":29,"column":39}}})) != null ? stack1 : "")
    + "                            </p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Settings") : depths[1])) != null ? lookupProperty(stack1,"ShowLocationInIssueReports") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":36,"column":35}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"in","1,2,3,5,6,7,8",{"name":"Test","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":28},"end":{"line":44,"column":37}}})) != null ? stack1 : "")
    + "\r\n                            <p>\r\n                                "
    + alias4((lookupProperty(helpers,"HighlightedProperty")||(depth0 && lookupProperty(depth0,"HighlightedProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PriorityOID") : depth0),{"name":"HighlightedProperty","hash":{},"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":67}}}))
    + "\r\n                            </p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Settings") : depths[1])) != null ? lookupProperty(stack1,"ShowCreationTimestampInIssueReports") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":28},"end":{"line":55,"column":35}}})) != null ? stack1 : "")
    + "\r\n                            <p class=\"deadline-timestamp\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":32},"end":{"line":66,"column":39}}})) != null ? stack1 : "")
    + "                            </p>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"in","1,2,3,5,6,7,8",{"name":"Test","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":28},"end":{"line":83,"column":37}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Settings") : depths[1])) != null ? lookupProperty(stack1,"ShowIdInIssueReports") : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":28},"end":{"line":95,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowFiles") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":124,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\r\n                </div>\r\n\r\n                <div class=\"col-xs-12 edit-cell screen-max-sm\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"in","2,3,6,7,8",{"name":"Test","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":20},"end":{"line":135,"column":29}}})) != null ? stack1 : "")
    + "\r\n                    <div class=\"cell chat-history\">\r\n                        <span class=\"icon-bubble\"></span>\r\n                        <span class=\"badge"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Comments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"===",0,{"name":"Test","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":42},"end":{"line":139,"column":91}}})) != null ? stack1 : "")
    + "\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Comments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span>\r\n                    </div>\r\n                </div>\r\n\r\n                <div class=\"screen-min-md edit-cell\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),"in","2,3,6,7,8",{"name":"Test","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":20},"end":{"line":150,"column":29}}})) != null ? stack1 : "")
    + "\r\n                    <div class=\"cell chat-history\">\r\n                        <span class=\"icon-bubble\"></span>\r\n                        <span class=\"badge"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Comments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"===",0,{"name":"Test","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":42},"end":{"line":154,"column":91}}})) != null ? stack1 : "")
    + "\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Comments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n    </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " is-archived";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-formoid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"AssignedFormOID") || (depth0 != null ? lookupProperty(depth0,"AssignedFormOID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AssignedFormOID","hash":{},"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":65}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-schedulingoid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"AssignedSchedulingOID") || (depth0 != null ? lookupProperty(depth0,"AssignedSchedulingOID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AssignedSchedulingOID","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":83}}}) : helper)))
    + "\"";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":52}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":16,"column":60},"end":{"line":16,"column":67}}}) : helper)));
},"12":function(container,depth0,helpers,partials,data) {
    return "&isbulletin=true";
},"14":function(container,depth0,helpers,partials,data) {
    return "col-xs-7 ";
},"16":function(container,depth0,helpers,partials,data) {
    return "col-xs-11";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"IssueReport.NoTitle",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":66}}}))
    + "\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":36},"end":{"line":24,"column":43}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <strong>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":59}}}) : helper))) != null ? stack1 : "")
    + "</strong>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                                    <span class=\"icon-lock\"></span>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p class=\"location\">\r\n                                    @ "
    + container.escapeExpression((lookupProperty(helpers,"ElementTitle")||(depth0 && lookupProperty(depth0,"ElementTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AssignedElementOID") : depth0),{"name":"ElementTitle","hash":{},"data":data,"loc":{"start":{"line":34,"column":38},"end":{"line":34,"column":73}}}))
    + "\r\n                                </p>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":32},"end":{"line":43,"column":39}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <p>\r\n                                        "
    + container.escapeExpression((lookupProperty(helpers,"PropertyBadge")||(depth0 && lookupProperty(depth0,"PropertyBadge"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"PropertyBadge","hash":{},"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":66}}}))
    + "\r\n                                    </p>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p class=\"creation-timestamp\">\r\n                                    <i class=\"icon-clock\"></i>\r\n                                    "
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"CreationTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":53,"column":68}}}))
    + "\r\n                                </p>\r\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <i class=\"icon-calendar combined-icon\">\r\n                                        <i class=\"icon-clock\"></i>\r\n                                    </i>\r\n\r\n                                    <span"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsExpired") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":41},"end":{"line":63,"column":77}}})) != null ? stack1 : "")
    + ">\r\n                                        "
    + container.escapeExpression((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"DeadlineTimestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":64,"column":40},"end":{"line":64,"column":72}}}))
    + "\r\n                                    </span>\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    return " class=\"red\"";
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Settings") : depths[1])) != null ? lookupProperty(stack1,"ShowResponsibilitiesInIssueReports") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":82,"column":39}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Users") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":36},"end":{"line":75,"column":43}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":36},"end":{"line":81,"column":43}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <p class=\"users\">\r\n                                            "
    + container.escapeExpression((lookupProperty(helpers,"UserTitleList")||(depth0 && lookupProperty(depth0,"UserTitleList"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Users") : depth0),{"name":"UserTitleList","hash":{},"data":data,"loc":{"start":{"line":73,"column":44},"end":{"line":73,"column":67}}}))
    + "\r\n                                        </p>\r\n";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <p class=\"teams\">\r\n                                            "
    + container.escapeExpression((lookupProperty(helpers,"TeamTitleList")||(depth0 && lookupProperty(depth0,"TeamTitleList"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"TeamTitleList","hash":{},"data":data,"loc":{"start":{"line":79,"column":44},"end":{"line":79,"column":67}}}))
    + "\r\n                                        </p>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p class=\"ident\">\r\n                                    <span>"
    + alias3((lookupProperty(helpers,"IssueTypeAbbreviation")||(depth0 && lookupProperty(depth0,"IssueTypeAbbreviation"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Type") : depth0),{"name":"IssueTypeAbbreviation","hash":{},"data":data,"loc":{"start":{"line":87,"column":42},"end":{"line":87,"column":72}}}))
    + alias3(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":87,"column":72},"end":{"line":87,"column":78}}}) : helper)))
    + "."
    + alias3(((helper = (helper = lookupProperty(helpers,"Revision") || (depth0 != null ? lookupProperty(depth0,"Revision") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"Revision","hash":{},"data":data,"loc":{"start":{"line":87,"column":79},"end":{"line":87,"column":91}}}) : helper)))
    + "</span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CustomID") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":36},"end":{"line":93,"column":43}}})) != null ? stack1 : "")
    + "                                </p>\r\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ID") : depth0),"!=",(depth0 != null ? lookupProperty(depth0,"CustomID") : depth0),{"name":"Test","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":40},"end":{"line":92,"column":49}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span>("
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"CustomID") || (depth0 != null ? lookupProperty(depth0,"CustomID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CustomID","hash":{},"data":data,"loc":{"start":{"line":91,"column":51},"end":{"line":91,"column":65}}}) : helper))) != null ? stack1 : "")
    + ")</span>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Images") : depth0),(depth0 != null ? lookupProperty(depth0,"HasAudioFiles") : depth0),(lookupProperty(helpers,"GetPreviewImage")||(depth0 && lookupProperty(depth0,"GetPreviewImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalData") : depth0)) != null ? lookupProperty(stack1,"CheckpointPreviewImages") : stack1),{"name":"GetPreviewImage","hash":{},"data":data,"loc":{"start":{"line":99,"column":55},"end":{"line":99,"column":111}}}),{"name":"Or","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":28},"end":{"line":123,"column":35}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"col-xs-4 col-xs-offset-1 issue-image\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Images") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":101,"column":36},"end":{"line":121,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\r\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"image additional-file pull-right\">\r\n                                            "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Images") : depth0)) != null ? lookupProperty(stack1,"0") : stack1),"image-container",false,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":103,"column":44},"end":{"line":103,"column":100}}}))
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Images") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",1,{"name":"Test","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":44},"end":{"line":107,"column":53}}})) != null ? stack1 : "")
    + "                                        </div>\r\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"additional-files-counter\">+"
    + container.escapeExpression((lookupProperty(helpers,"Calculate")||(depth0 && lookupProperty(depth0,"Calculate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Images") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"-",1,{"name":"Calculate","hash":{},"data":data,"loc":{"start":{"line":106,"column":87},"end":{"line":106,"column":120}}}))
    + "</div>\r\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"GetPreviewImage")||(depth0 && lookupProperty(depth0,"GetPreviewImage"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalData") : depth0)) != null ? lookupProperty(stack1,"CheckpointPreviewImages") : stack1),{"name":"GetPreviewImage","hash":{},"data":data,"loc":{"start":{"line":110,"column":46},"end":{"line":110,"column":102}}}),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":110,"column":40},"end":{"line":120,"column":47}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"image additional-file pull-right\">\r\n                                                "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||alias2).call(alias1,(lookupProperty(helpers,"GetPreviewImage")||(depth0 && lookupProperty(depth0,"GetPreviewImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"AdditionalData") : depth0)) != null ? lookupProperty(stack1,"CheckpointPreviewImages") : stack1),{"name":"GetPreviewImage","hash":{},"data":data,"loc":{"start":{"line":112,"column":68},"end":{"line":112,"column":124}}}),"image-container",false,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":112,"column":48},"end":{"line":112,"column":150}}}))
    + "\r\n                                            </div>\r\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"HasAudioFiles") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":44},"end":{"line":119,"column":51}}})) != null ? stack1 : "");
},"54":function(container,depth0,helpers,partials,data) {
    return "                                                <div class=\"image microphone pull-right\">\r\n                                                    <span class=\"icon-mic\"></span>\r\n                                                </div>\r\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"cell\">\r\n                            <a href=\"#issue/"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":131,"column":44},"end":{"line":131,"column":82}}})) != null ? stack1 : "")
    + "\" class=\"link\">\r\n                                <span class=\"icon-pen-angled\"></span>\r\n                            </a>\r\n                        </div>\r\n";
},"58":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":159,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});

this["Templates"]["Reports"]["IssueProgress"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <ul class=\"issues with-type-icons\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssueCell"),depth0,{"name":"IssueCell","hash":{"Settings":(depths[1] != null ? lookupProperty(depths[1],"Settings") : depths[1]),"Issue":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":44}}}))
    + "</p>\r\n        <div class=\"no-content\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"IssuePlural":(depth0 != null ? lookupProperty(depth0,"IssuePlural") : depth0),"Key":"IssueReport.NoContent"},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":92}}}))
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"issue-report issue-progress\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Reports"]["Issues"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <ul class=\"issues "
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"WithTypeIcons") : depth0),(lookupProperty(helpers,"IsNull")||(depth0 && lookupProperty(depth0,"IsNull"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"WithTypeIcons") : depth0),{"name":"IsNull","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":68}}}),{"name":"Or","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":92}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "with-type-icons";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssueCell"),depth0,{"name":"IssueCell","hash":{"IsInfoBoard":(depths[1] != null ? lookupProperty(depths[1],"IsInfoBoard") : depths[1]),"Settings":(depths[1] != null ? lookupProperty(depths[1],"Settings") : depths[1]),"Issue":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"IssuesNoIssuesAvailable"),depth0,{"name":"IssuesNoIssuesAvailable","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"issue-report\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Issues") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["Reports"]["NoIssuesAvailable"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":36}}}))
    + "</p>\r\n<div class=\"no-content\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"IssuePlural":(depth0 != null ? lookupProperty(depth0,"IssuePlural") : depth0),"Key":"IssueReport.NoContent"},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":84}}}))
    + "</div>";
},"useData":true});

this["Templates"]["ResponsibilityPicker"]["List"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":52}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " class=\"is-readonly\"";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"User") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Team") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Contact") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ContactGroup") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerSelectedUser"),depth0,{"name":"ResponsibilityPickerSelectedUser","hash":{"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0),"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"User":(depth0 != null ? lookupProperty(depth0,"User") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerSelectedTeam"),depth0,{"name":"ResponsibilityPickerSelectedTeam","hash":{"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0),"UserSelectionAllowed":(depths[1] != null ? lookupProperty(depths[1],"UserSelectionAllowed") : depths[1]),"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"Team":(depth0 != null ? lookupProperty(depth0,"Team") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerSelectedContact"),depth0,{"name":"ResponsibilityPickerSelectedContact","hash":{"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0),"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"Contact":(depth0 != null ? lookupProperty(depth0,"Contact") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerSelectedContactGroup"),depth0,{"name":"ResponsibilityPickerSelectedContactGroup","hash":{"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0),"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"ContactGroup":(depth0 != null ? lookupProperty(depth0,"ContactGroup") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ResponsibilityPicker.NoDataAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":7},"end":{"line":39,"column":54}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":40,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["ResponsibilityPicker"]["RaciSelectionMenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-checkmark\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"action-dropdown-menu\" data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Identifier") || (depth0 != null ? lookupProperty(depth0,"Identifier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Identifier","hash":{},"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":58}}}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":79}}}) : helper)))
    + "\">\r\n    <ul class=\"action-list\">\r\n        <li class=\"action-item\" data-action=\"accountable\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Accountable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":85}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsAccountable") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":85},"end":{"line":3,"column":154}}})) != null ? stack1 : "")
    + "</li>\r\n        <li class=\"action-item\" data-action=\"responsible\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Responsible",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":85}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsResponsible") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":85},"end":{"line":4,"column":154}}})) != null ? stack1 : "")
    + "</li>\r\n        <li class=\"action-item\" data-action=\"consulted\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Consulted",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":81}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsConsulted") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":81},"end":{"line":5,"column":148}}})) != null ? stack1 : "")
    + "</li>\r\n        <li class=\"action-item\" data-action=\"informed\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"RACI.Informed",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":55},"end":{"line":6,"column":79}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsInformed") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":79},"end":{"line":6,"column":145}}})) != null ? stack1 : "")
    + "</li>\r\n    </ul>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["ResponsibilityPicker"]["RaciTags"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Tag"),depth0,{"name":"Tag","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"ReadOnly") : depth0),"id":"responsible","LangKey":"RACI.Responsible"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Tag"),depth0,{"name":"Tag","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"ReadOnly") : depth0),"id":"accountable","LangKey":"RACI.Accountable"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Tag"),depth0,{"name":"Tag","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"ReadOnly") : depth0),"id":"consulted","LangKey":"RACI.Consulted"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Tag"),depth0,{"name":"Tag","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"ReadOnly") : depth0),"id":"informed","LangKey":"RACI.Informed"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tags\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsResponsible") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsAccountable") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsConsulted") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"RACI") : depth0)) != null ? lookupProperty(stack1,"IsInformed") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["SelectableItemsList"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":32,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"DataMode") : depths[1]),"===","users",{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"DataMode") : depths[1]),"===","teams",{"name":"Test","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":17,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"DataMode") : depths[1]),"===","contacts",{"name":"Test","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":24,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"DataMode") : depths[1]),"===","contactgroups",{"name":"Test","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":31,"column":21}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerUnselectedUser"),depth0,{"name":"ResponsibilityPickerUnselectedUser","hash":{"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"User":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerUnselectedTeam"),depth0,{"name":"ResponsibilityPickerUnselectedTeam","hash":{"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"UserSelectionAllowed":(depths[1] != null ? lookupProperty(depths[1],"UserSelectionAllowed") : depths[1]),"Team":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerUnselectedContact"),depth0,{"name":"ResponsibilityPickerUnselectedContact","hash":{"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"Contact":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerUnselectedContactGroup"),depth0,{"name":"ResponsibilityPickerUnselectedContactGroup","hash":{"IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadOnly") : depth0),"ContactGroup":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ResponsibilityPicker.NoDataAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":35,"column":7},"end":{"line":35,"column":54}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["ResponsibilityPicker"]["SelectedContact"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"remove icon-bin2\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Remove",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":74}}}))
    + "\"></span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"edit icon-dots-three-vertical\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ResponsibilityPicker.Edit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":59},"end":{"line":21,"column":95}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"contacts\" class=\"selected clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),"img/menu/kontakte.svg",{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1),{"name":"And","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":16}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RaciTags"),depth0,{"name":"RaciTags","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["SelectedContactGroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"remove icon-bin2\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Remove",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":74}}}))
    + "\"></span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"edit icon-dots-three-vertical\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ResponsibilityPicker.Edit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":59},"end":{"line":17,"column":95}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ContactGroup") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"contactgroups\" class=\"selected clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),"img/menu/kontaktgruppen.svg",{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ContactGroup") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n    </span>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RaciTags"),depth0,{"name":"RaciTags","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["SelectedTeam"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"remove icon-bin2\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Remove",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":74}}}))
    + "\"></span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <br /><span class=\"user-count\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " "
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"count":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"Key":"UserPicker.Member"},"data":data,"loc":{"start":{"line":11,"column":65},"end":{"line":11,"column":121}}}))
    + "</span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"edit icon-dots-three-vertical\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ResponsibilityPicker.Edit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":59},"end":{"line":21,"column":95}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"teams\" class=\"selected clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1),">",0,{"name":"Test","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":17}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RaciTags"),depth0,{"name":"RaciTags","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["SelectedUser"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"remove icon-bin2\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Remove",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":74}}}))
    + "\"></span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-lock\"></span>";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"edit icon-dots-three-vertical\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ResponsibilityPicker.Edit",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":55},"end":{"line":22,"column":91}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"users\" class=\"selected clearfix\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1),{"name":"And","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":16}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"IsLocked") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":67}}})) != null ? stack1 : "")
    + "\r\n    </span>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RaciTags"),depth0,{"name":"RaciTags","hash":{"ReadOnly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),"RACI":(depth0 != null ? lookupProperty(depth0,"RACI") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["TeamMembers"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"divider\">\r\n    <span class=\"btn icon-cheveron-left hide-team-members\" data-team-identifier=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"></span>\r\n    <strong>"
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"teamTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),"Key":"UserPicker.MembersOf"},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":68}}}))
    + "</strong>\r\n</div>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibilityPickerSelectableItemsList"),depth0,{"name":"ResponsibilityPickerSelectableItemsList","hash":{"DataMode":"users","Items":(depth0 != null ? lookupProperty(depth0,"Items") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["UnselectedContact"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"add icon-plus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Add",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":68}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"unselected clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),"img/menu/kontakte.svg",{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"Contact") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1),{"name":"And","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":16}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"HideAddButton") : depth0),{"name":"NOr","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":12}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["UnselectedContactGroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"add icon-plus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Add",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":68}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ContactGroup") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"unselected clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),"img/menu/kontaktgruppen.svg",{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ContactGroup") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n    </span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"HideAddButton") : depth0),{"name":"NOr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":12}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["UnselectedTeam"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <br /><span class=\"user-count\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " "
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"count":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"Key":"UserPicker.Member"},"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":121}}}))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"show-team-members icon-group\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.ShowTeamMembers",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":58},"end":{"line":12,"column":95}}}))
    + "\"></span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"add icon-plus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Add",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":43},"end":{"line":16,"column":68}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"unselected clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1),">",0,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"UserSelectionAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"HideAddButton") : depth0),{"name":"NOr","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":17,"column":12}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["UnselectedUser"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-lock\"></span>";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"add icon-plus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Add",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":68}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"unselected clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1),{"name":"And","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":16}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"IsLocked") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":67}}})) != null ? stack1 : "")
    + "\r\n    </span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"HideAddButton") : depth0),{"name":"NOr","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":12}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["ResponsibilityPicker"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"ResponsibleList"),depth0,{"name":"ResponsibleList","hash":{"DataMode":(depth0 != null ? lookupProperty(depth0,"CurrentView") : depth0),"Items":(depth0 != null ? lookupProperty(depth0,"Items") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"SelectableItemsMarkup") || (depth0 != null ? lookupProperty(depth0,"SelectableItemsMarkup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SelectableItemsMarkup","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":43}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"active\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal container responsibility-picker\">\r\n    <div class=\"search\"></div>\r\n\r\n    <div class=\"content\">\r\n        <div class=\"selection-list\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CurrentView") : depth0),"===","selected",{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <ul class=\"footer-navigation clearfix\">\r\n            <li data-tab=\"selected\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Selection",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":68}}}))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"CurrentView") : depth0),"===","selected",{"name":"Test","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":70},"end":{"line":19,"column":133}}})) != null ? stack1 : "")
    + "></li>\r\n            <li data-tab=\"users\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.User_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":40},"end":{"line":20,"column":67}}}))
    + "\"></li>\r\n            <li data-tab=\"teams\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Team_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":21,"column":67}}}))
    + "\"></li>\r\n            <li data-tab=\"contacts\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Contact_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":73}}}))
    + "\"><img src=\"img/menu/kontakte.svg\" height=\"24\" width=\"24\" /></li>\r\n            <li data-tab=\"contactgroups\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.ContactGroup_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":83}}}))
    + "\"><img src=\"img/menu/kontaktgruppen.svg\" height=\"24\" width=\"24\" /></li>\r\n        </ul>\r\n        <div class=\"button-bar clearfix\">\r\n            <button type=\"button\" class=\"btn btn-danger btn-abort flat hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":26,"column":79},"end":{"line":26,"column":100}}}))
    + "</button>\r\n            <button type=\"button\" class=\"btn btn-success btn-apply flat hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":80},"end":{"line":27,"column":100}}}))
    + "</button>\r\n            <button type=\"button\" class=\"btn btn-default btn-close flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":73},"end":{"line":28,"column":94}}}))
    + "</button>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["RoundedImage"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"rounded-image\" style=\"background-image: url("
    + container.escapeExpression(container.lambda(depth0, depth0))
    + ");\"></div>";
},"useData":true});

this["Templates"]["Scale"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"scale-configuration\">\r\n    <p class=\"info\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Configuration.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":44}}}))
    + "\r\n    </p>\r\n    <div>\r\n        <label for=\"auto-connect-scale\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Configuration.AutoConnect",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":82}}}))
    + "</label><input id=\"auto-connect-scale\" name=\"auto-connect-scale\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AutoReconnect") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":163},"end":{"line":6,"column":199}}})) != null ? stack1 : "")
    + "/>\r\n    </div>\r\n    <hr />\r\n    <button class=\"btn btn-default btn-request\" type=\"button\" name=\"request\"><span class=\"icon-drive\"></span> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Configuration.Request",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":110},"end":{"line":9,"column":148}}}))
    + "</button>\r\n    <div class=\"test-area\">\r\n        <b>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Configuration.Value",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":11},"end":{"line":11,"column":47}}}))
    + "</b>\r\n        <pre class=\"value\">-</pre>\r\n        <b>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Configuration.RawValue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":11},"end":{"line":13,"column":50}}}))
    + "</b>\r\n        <pre class=\"raw-data\">-</pre>\r\n    </div>\r\n    <hr />\r\n    <button class=\"btn btn-primary\" type=\"button\" name=\"add-scale\"><span class=\"icon-plus\"></span> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Configuration.NewDevice",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":99},"end":{"line":17,"column":139}}}))
    + "</button>\r\n    <hr />\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"ScaleDeviceList"),depth0,{"name":"ScaleDeviceList","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["Scale"]["DeviceEditor"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Scale.Edit.TitleNew",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":42}}}))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Scale.Edit.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":39}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material scale-device-editor\">\r\n    <div class=\"header\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsNewDevice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"main-content\">\r\n        <form>\r\n            <div class=\"form-group\">\r\n                <label for=\"device-name\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Edit.Name",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":67}}}))
    + ":</label>\r\n                <input type=\"text\" class=\"form-control\" id=\"device-name\" placeholder=\"No. 01\">\r\n            </div>\r\n            <div class=\"form-group\">\r\n                <label for=\"ip-address\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Edit.IP",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":40},"end":{"line":17,"column":64}}}))
    + ":</label>\r\n                <input type=\"text\" class=\"form-control\" id=\"ip-address\" placeholder=\"127.0.0.1\">\r\n            </div>\r\n            <div class=\"form-group\">\r\n                <label for=\"port-number\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Scale.Edit.Port",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":41},"end":{"line":21,"column":67}}}))
    + ":</label>\r\n                <input type=\"number\" class=\"form-control\" id=\"port-number\" placeholder=\"4305\" value=\"4305\">\r\n            </div>\r\n        </form>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger pull-left\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":68},"end":{"line":28,"column":89}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Save",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":59},"end":{"line":29,"column":79}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Scale"]["ScaleDeviceList"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"row\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":43}}}) : helper)))
    + "\" data-ip=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"IP") || (depth0 != null ? lookupProperty(depth0,"IP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IP","hash":{},"data":data,"loc":{"start":{"line":3,"column":54},"end":{"line":3,"column":60}}}) : helper)))
    + "\" data-port=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Port") || (depth0 != null ? lookupProperty(depth0,"Port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Port","hash":{},"data":data,"loc":{"start":{"line":3,"column":73},"end":{"line":3,"column":81}}}) : helper)))
    + "\">\r\n            <div class=\"col-xs-1 col-md-1\"><span class=\"selection-mark "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsSelected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":71},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + "\"></span></div>\r\n            <div class=\"col-xs-1 col-md-1\"><span class=\"play-stop "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsConnected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":131}}})) != null ? stack1 : "")
    + "\"></span></div>\r\n            <div class=\"col-xs-6 col-md-8\">\r\n                <div><b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":32}}}) : helper)))
    + "</b></div>\r\n                <div class=\"connection\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"IP") || (depth0 != null ? lookupProperty(depth0,"IP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IP","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":46}}}) : helper)))
    + ":"
    + alias4(((helper = (helper = lookupProperty(helpers,"Port") || (depth0 != null ? lookupProperty(depth0,"Port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Port","hash":{},"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":55}}}) : helper)))
    + "</div>\r\n            </div>\r\n            <div class=\"col-xs-2 col-md-1\"><span class=\"edit icon-pen-angled\"></span></div>\r\n            <div class=\"col-xs-2 col-md-1\"><span class=\"delete icon-bin2\"></span></div>\r\n        </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "icon-checkmark";
},"4":function(container,depth0,helpers,partials,data) {
    return "connected icon-pause2";
},"6":function(container,depth0,helpers,partials,data) {
    return "icon-play3";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"devices\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Devices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "</ul>\r\n";
},"useData":true});

this["Templates"]["Selections"]["CustomDataPicker"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":50}}}))
    + "</p>\r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\r\n                class=\"btn btn-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":190}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AdditionalClasses") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":190},"end":{"line":10,"column":244}}})) != null ? stack1 : "")
    + "\"\r\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":53}}})) != null ? stack1 : "")
    + "\r\n                "
    + ((stack1 = (lookupProperty(helpers,"IsNotNullOrUndefined")||(depth0 && lookupProperty(depth0,"IsNotNullOrUndefined"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"PropertyValue") : depth0),{"name":"IsNotNullOrUndefined","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":118}}})) != null ? stack1 : "")
    + "\r\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":61}}})) != null ? stack1 : "")
    + "\r\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"ButtonTextWrap") : depths[1]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":76}}})) != null ? stack1 : "")
    + "\r\n            >\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"UseSystemProperties") : depths[1]),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.program(24, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FontIcon") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "            </button>\r\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OID") : depth0),"in",(depths[1] != null ? lookupProperty(depths[1],"SelectedProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":107}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return " selected";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"PropertyValue") : depth0),"in",(depths[1] != null ? lookupProperty(depths[1],"SelectedProperties") : depths[1]),{"name":"Test","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":115},"end":{"line":10,"column":183}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"AdditionalClasses") || (depth0 != null ? lookupProperty(depth0,"AdditionalClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AdditionalClasses","hash":{},"data":data,"loc":{"start":{"line":10,"column":216},"end":{"line":10,"column":237}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-oid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":45}}}) : helper)))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-propertyvalue=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"PropertyValue") || (depth0 != null ? lookupProperty(depth0,"PropertyValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PropertyValue","hash":{},"data":data,"loc":{"start":{"line":12,"column":75},"end":{"line":12,"column":92}}}) : helper)))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"17":function(container,depth0,helpers,partials,data) {
    return " style=\"white-space:normal;\"";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"ShowPropertyColors") : depths[1]),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":47}}}))
    + "\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"PropertyTitle")||(depth0 && lookupProperty(depth0,"PropertyTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"PropertyTitle","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":45}}}))
    + "\r\n";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"GetCustomDataText")||(depth0 && lookupProperty(depth0,"GetCustomDataText"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depths[1] != null ? lookupProperty(depths[1],"TextSchema") : depths[1]),{"name":"GetCustomDataText","hash":{},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":60}}}))
    + "\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"icon-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"FontIcon") || (depth0 != null ? lookupProperty(depth0,"FontIcon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"FontIcon","hash":{},"data":data,"loc":{"start":{"line":27,"column":38},"end":{"line":27,"column":50}}}) : helper)))
    + "\"></span>\r\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "            <button type=\"button\" class=\"btn btn-danger flat btn-reset pull-left is-icon\">\r\n                <span class=\"icon-bin2\"></span>\r\n            </button>\r\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn btn-success btn-okay flat\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":42,"column":58},"end":{"line":42,"column":78}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"custom-data-picker\">\r\n    <div class=\"header\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":33}}}) : helper)))
    + "</div>\r\n    <div class=\"body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":30,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"footer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"HideResetButton") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "\r\n        <button class=\"btn btn-danger btn-abort flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":54},"end":{"line":39,"column":75}}}))
    + "</button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"HideConfirmationButtons") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":43,"column":19}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"useData":true,"useDepths":true});

this["Templates"]["Selections"]["DateTime"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row header\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"HeaderText") || (depth0 != null ? lookupProperty(depth0,"HeaderText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"HeaderText","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"date-picker-settings\">\r\n                <input type=\"text\" inputmode=\"date\" class=\"custom-date-input\" placeholder=\"__.__.____\" style=\"width:120px;\"/>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowPeriodSwitch") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <div class=\"col-xs-12 date-picker hidden\">\r\n                <div class=\"row head\">\r\n                    <div class=\"col-xs-2 previous-month\"><span class=\"icon-arrow-left2\"></span></div>\r\n                    <div class=\"col-xs-1 goto-current\"><span class=\"icon-calendar\"></span></div>\r\n                    <div class=\"col-xs-7 current-month\"></div>\r\n                    <div class=\"col-xs-2 next-month\"><span class=\"icon-arrow-right2\"></span></div>\r\n                </div>\r\n\r\n                <div class=\"row calendar\">\r\n                    <table>\r\n                        <thead>\r\n                            <tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Days") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":32},"end":{"line":34,"column":41}}})) != null ? stack1 : "")
    + "                            </tr>\r\n                        </thead>\r\n\r\n                        <tbody></tbody>\r\n                    </table>\r\n                </div>\r\n\r\n                <div class=\"period-input hidden\">\r\n                    <input type=\"text\" class=\"input-lower-boundary\"> - <input type=\"text\" class=\"input-upper-boundary\">\r\n                </div>\r\n            </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"period-switch\">\r\n                    <input type=\"checkbox\" class=\"change-lower-boundary\" />\r\n                </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                                    <th>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"col-xs-12 time-picker hidden\">\r\n                <label for=\"date-time-picker-time-value\"></label>\r\n                <input type=\"time\" class=\"form-control\" id=\"date-time-picker-time-value\" required=\"required\" />\r\n            </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"datetime-picker container\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"HeaderText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div class=\"row body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowDatePicker") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowTimePicker") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"row footer\">\r\n        <button class=\"btn btn-danger flat btn-clear is-icon hidden\">\r\n            <span class=\"icon-bin2\"></span>\r\n        </button>\r\n        <button class=\"btn btn-danger btn-abort flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":60,"column":54},"end":{"line":60,"column":75}}}))
    + "</button>\r\n        <button class=\"btn btn-success btn-apply flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":61,"column":55},"end":{"line":61,"column":75}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Selections"]["ElementPickerPopup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ZIndex") || (depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ZIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":91}}}) : helper)))
    + " !important;\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn flat pull-left is-icon btn-home\">\r\n                        <span class=\"icon-home3\"></span>\r\n                    </button>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " splitted";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tab-control\">\r\n                        <ul>\r\n                            <li data-tab=\"tree-structure\" class=\"selected\">\r\n                                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ElementPicker.TabControl.TreeStructure",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":19,"column":81}}}))
    + "\r\n                            </li>\r\n                            <li data-tab=\"floorplan\">\r\n                                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"ElementPicker.TabControl.FloorPlan",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":22,"column":77}}}))
    + "\r\n                            </li>\r\n                        </ul>\r\n                    </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"floor-plan-container screen-sm-max-invisible\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"EnableMarksOnFloorPlan") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":41,"column":31}}})) != null ? stack1 : "")
    + "\r\n                        <div class=\"canvas\"></div>\r\n                    </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"row\">\r\n                                <button type=\"button\" class=\"btn btn-default btn-add-default-mark icon-location\">\r\n                                </button>\r\n                            </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn btn-danger flat btn-reset pull-left is-icon\">\r\n                        <span class=\"icon-bin2\"></span>\r\n                    </button>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowQRCodeScannerButton") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":60,"column":27}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowNfcScannerButton") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":70,"column":27}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "                        <button type=\"button\" class=\"btn btn-default flat qr-code pull-left\">\r\n                            <span class=\"icon-qrcode\"></span>\r\n                        </button>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(lookupProperty(helpers,"IsNotRunningOnIOS")||(depth0 && lookupProperty(depth0,"IsNotRunningOnIOS"))||container.hooks.helperMissing).call(alias1,{"name":"IsNotRunningOnIOS","hash":{},"data":data,"loc":{"start":{"line":63,"column":34},"end":{"line":63,"column":53}}}),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":24},"end":{"line":69,"column":35}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNfcEnabled") || (depth0 != null ? lookupProperty(depth0,"IsNfcEnabled") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"IsNfcEnabled","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":28},"end":{"line":68,"column":45}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"IsNfcEnabled")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"19":function(container,depth0,helpers,partials,data) {
    return "                                <button type=\"button\" class=\"btn btn-default flat nfc pull-left\">\r\n                                    <span class=\"icon-nfc\"></span>\r\n                                </button>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(lookupProperty(helpers,"IfAPIVersionIsGreaterOrEqual")||(depth0 && lookupProperty(depth0,"IfAPIVersionIsGreaterOrEqual"))||alias2).call(alias1,20,{"name":"IfAPIVersionIsGreaterOrEqual","hash":{},"data":data,"loc":{"start":{"line":74,"column":27},"end":{"line":74,"column":60}}}),(lookupProperty(helpers,"UserHasRight")||(depth0 && lookupProperty(depth0,"UserHasRight"))||alias2).call(alias1,"REC-DELETE",true,{"name":"UserHasRight","hash":{},"data":data,"loc":{"start":{"line":74,"column":61},"end":{"line":74,"column":93}}}),{"name":"And","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":76,"column":28}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return "                        <button type=\"button\" class=\"btn btn-danger btn-delete flat\"><span class=\"icon-bin2\"></span></button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"modal fullscreen element-picker-popup\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":111}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowHomeButton") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":11,"column":51},"end":{"line":11,"column":60}}}) : helper)))
    + "</h4>\r\n            </div>\r\n\r\n            <div class=\"modal-body"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowFloorPlan") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":34},"end":{"line":14,"column":71}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowFloorPlan") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":26,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <div class=\"location screen-sm-max-visible\">\r\n                    <div class=\"search row\"></div>\r\n\r\n                    <div class=\"tree-wrapper\"></div>\r\n                </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowFloorPlan") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowResetButton") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":53,"column":23}}})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":71,"column":45}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowDeleteButton") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":16},"end":{"line":77,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <button type=\"button\" class=\"btn btn-danger btn-abort flat\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":79,"column":76},"end":{"line":79,"column":97}}}))
    + "</button>\r\n                <button type=\"button\" class=\"btn btn-success btn-ok flat\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":80,"column":74},"end":{"line":80,"column":94}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Selections"]["IndividualData"]["Image"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img data-file-identifier=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"File") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"FileSource") || (depth0 != null ? lookupProperty(depth0,"FileSource") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"FileSource","hash":{},"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":60}}}) : helper)))
    + "\" class=\"individual-entity-image\" crossorigin=\"use-credentials\" />";
},"useData":true});

this["Templates"]["Selections"]["IndividualData"]["ItemList"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":17}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li data-id=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\" class=\"clearfix"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ID") : depth0),"in",(depths[1] != null ? lookupProperty(depths[1],"PreviousValue") : depths[1]),{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":53},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + "\">\r\n                "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"SelectionTitle") : depth0), depth0)) != null ? stack1 : "")
    + "\r\n            </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " selected";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"no-data-found\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"RecorditemEditor.NoDataFound",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":51}}}))
    + "\r\n        </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"items\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "</ul>";
},"useData":true,"useDepths":true});

this["Templates"]["Selections"]["IndividualData"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn btn-default flat pull-left btn-scan-code\">\r\n                        <i class=\"icon-barcode\"></i>\r\n                    </button>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"pagination-wrapper\">\r\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"PaginationMarkup") || (depth0 != null ? lookupProperty(depth0,"PaginationMarkup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PaginationMarkup","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":42}}}) : helper))) != null ? stack1 : "")
    + "\r\n                </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(lookupProperty(helpers,"IfAPIVersionIsGreaterOrEqual")||(depth0 && lookupProperty(depth0,"IfAPIVersionIsGreaterOrEqual"))||alias2).call(alias1,20,{"name":"IfAPIVersionIsGreaterOrEqual","hash":{},"data":data,"loc":{"start":{"line":36,"column":27},"end":{"line":36,"column":60}}}),(lookupProperty(helpers,"UserHasRight")||(depth0 && lookupProperty(depth0,"UserHasRight"))||alias2).call(alias1,"REC-DELETE",true,{"name":"UserHasRight","hash":{},"data":data,"loc":{"start":{"line":36,"column":61},"end":{"line":36,"column":93}}}),{"name":"And","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":38,"column":28}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "                        <button type=\"button\" class=\"btn btn-danger btn-delete flat\"><span class=\"icon-bin2\"></span></button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"modal\" id=\"individualdata-selection\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":60}}}) : helper)))
    + "</h4>\r\n            </div>\r\n\r\n            <div class=\"search\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":13,"column":45}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\r\n\r\n            <div class=\"modal-body\">\r\n                <div class=\"content\">\r\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ListMarkup") || (depth0 != null ? lookupProperty(depth0,"ListMarkup") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ListMarkup","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\r\n                </div>\r\n            </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PaginationMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-default flat pull-left btn-toggle-visible-entities\">\r\n                    <i class=\"icon-eye combined-icon\">\r\n                        <i class=\"icon-checkmark\"></i>\r\n                    </i>\r\n                </button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowDeleteButton") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "                <button type=\"button\" class=\"btn btn-danger flat btn-abort\" data-dismiss=\"modal\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":40,"column":97},"end":{"line":40,"column":118}}}))
    + "</button>\r\n                <button type=\"button\" class=\"btn btn-success flat btn-save\">"
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":76},"end":{"line":41,"column":96}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Selections"]["ParameterSelectionWindow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"DOMPurify")||(depth0 && lookupProperty(depth0,"DOMPurify"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"DOMPurify","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":50}}}))
    + "</p>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"group-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":44}}}) : helper)))
    + "</p>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":21}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Markup") || (depth0 != null ? lookupProperty(depth0,"Markup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Markup","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":26}}}) : helper)))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"parameter-selection-window\" class=\"custom-data-picker\">\r\n    <div class=\"header\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":33}}}) : helper)))
    + "</div>\r\n    <div class=\"body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InfoText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Groups") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n    <div class=\"footer\">\r\n        <button class=\"btn btn-danger btn-abort flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":54},"end":{"line":16,"column":75}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["SelectionWindow"]["BaseWindow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ZIndex") || (depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ZIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":98},"end":{"line":1,"column":108}}}) : helper)))
    + "!important;\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tab-control"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TabControlCSSClass") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":99}}})) != null ? stack1 : "")
    + "\">\r\n                        <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Tabs") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":18,"column":37}}})) != null ? stack1 : "")
    + "                        </ul>\r\n                    </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TabControlCSSClass") || (depth0 != null ? lookupProperty(depth0,"TabControlCSSClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TabControlCSSClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":70},"end":{"line":12,"column":92}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li data-tab=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":52}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CSSClass") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":53},"end":{"line":15,"column":97}}})) != null ? stack1 : "")
    + ">\r\n                                    <span class=\"text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":16,"column":55},"end":{"line":16,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</span>\r\n                                </li>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CSSClass") || (depth0 != null ? lookupProperty(depth0,"CSSClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CSSClass","hash":{},"data":data,"loc":{"start":{"line":15,"column":77},"end":{"line":15,"column":89}}}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "screen-min-md";
},"11":function(container,depth0,helpers,partials,data) {
    return "full-width";
},"13":function(container,depth0,helpers,partials,data) {
    return " is-active";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " title=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"HoverTitle") : stack1), depth0))
    + "\"";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"btn-tree-filter"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsTreeFiltered") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":55},"end":{"line":34,"column":94}}})) != null ? stack1 : "")
    + "\">\r\n                                <span class=\"icon-filter\"></span>\r\n                            </div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"btn-hierarchy-filter screen-max-sm"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"IsActive") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":74},"end":{"line":42,"column":123}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"HoverTitle") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":124},"end":{"line":42,"column":204}}})) != null ? stack1 : "")
    + ">\r\n                                <div class=\"stacked-icon\">\r\n                                    <span class=\"type-icon "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"Icon") : stack1), depth0))
    + "\"></span>\r\n                                    <span class=\"icon-location\"></span>\r\n                                    <span class=\"icon-earth\"></span>\r\n                                </div>\r\n                            </div>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"btn-tree-filter screen-max-sm"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsTreeFiltered") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":69},"end":{"line":52,"column":108}}})) != null ? stack1 : "")
    + "\">\r\n                                <span class=\"icon-filter\"></span>\r\n                            </div>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"content\">\r\n                    </div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn btn-danger flat btn-reset pull-left is-icon\">\r\n                        <span class=\"icon-bin2\"></span>\r\n                    </button>\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn btn-default btn-custom-action flat pull-left\"></button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fullscreen selection-window\" id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"SelectionID") || (depth0 != null ? lookupProperty(depth0,"SelectionID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"SelectionID","hash":{},"data":data,"loc":{"start":{"line":1,"column":51},"end":{"line":1,"column":66}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":127}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":34}}}))
    + "\r\n                </h4>\r\n            </div>\r\n\r\n            <div class=\"modal-body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowTabControl") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <div class=\"item-tree "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowDetails") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":23,"column":38},"end":{"line":23,"column":95}}})) != null ? stack1 : "")
    + "\">\r\n                    <div class=\"filter-row screen-min-md\">\r\n                        <div class=\"btn-hierarchy-filter"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"IsActive") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":56},"end":{"line":25,"column":105}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"HoverTitle") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":106},"end":{"line":25,"column":186}}})) != null ? stack1 : "")
    + ">\r\n                            <div class=\"stacked-icon\">\r\n                                <span class=\"type-icon "
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"HierarchyFilter") : depth0)) != null ? lookupProperty(stack1,"Icon") : stack1), depth0))
    + "\"></span>\r\n                                <span class=\"icon-location\"></span>\r\n                                <span class=\"icon-earth\"></span>\r\n                            </div>\r\n                        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowTreeFilter") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":37,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n\r\n                    <div class=\"search-row\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowHierarchyFilter") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":49,"column":31}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowTreeFilter") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":55,"column":31}}})) != null ? stack1 : "")
    + "\r\n                        <div class=\"search\"></div>\r\n                    </div>\r\n\r\n                    <div class=\"tree-wrapper\">\r\n                        <ul></ul>\r\n                    </div>\r\n                </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowDetails") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":68,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowResetButton") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":16},"end":{"line":76,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowCustomActionButton") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":80,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <button type=\"button\" class=\"btn btn-danger btn-abort flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":82,"column":76},"end":{"line":82,"column":97}}}))
    + "</button>\r\n                <button type=\"button\" class=\"btn btn-success btn-apply flat disabled\" data-dismiss=\"modal\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":83,"column":107},"end":{"line":83,"column":127}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["SelectionWindow"]["Form"]["Parameterlist"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"information\">\r\n        <h4>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</h4>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"Parent") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"Parametergroups") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"hierarchy\">@ "
    + container.escapeExpression((lookupProperty(helpers,"ElementHierarchy")||(depth0 && lookupProperty(depth0,"ElementHierarchy"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),true,true,{"name":"ElementHierarchy","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":74}}}))
    + "</p>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"parameters\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Form") : depth0)) != null ? lookupProperty(stack1,"Parametergroups") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":26,"column":17}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"GroupAndAtLeastOneParameterEnabled")||(depth0 && lookupProperty(depth0,"GroupAndAtLeastOneParameterEnabled"))||container.hooks.helperMissing).call(alias1,depth0,{"name":"GroupAndAtLeastOneParameterEnabled","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":59}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p data-groupoid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":15,"column":34},"end":{"line":15,"column":41}}}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":15,"column":54},"end":{"line":15,"column":62}}}) : helper)))
    + "\" class=\"groupRow\">\r\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\r\n                </p>\r\n                <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Parameters") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Enabled") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":22,"column":31}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"DummyParameter"),depth0,{"name":"DummyParameter","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":29,"column":44}}}))
    + "</p>\r\n        <p class=\"info plain\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SelectionWindow.Forms.NoContent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":30},"end":{"line":30,"column":72}}}))
    + "</p>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":33,"column":40}}}))
    + "</p>\r\n    <p class=\"info plain\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SelectionWindow.Forms.NoFormSelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":73}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Form") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["Templates"]["SelectionWindow"]["Form"]["Unselectable"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"information\">\r\n    <p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":40}}}))
    + "</p>\r\n    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SelectionWindow.Forms.Unselectable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":49}}}))
    + "\r\n</div>";
},"useData":true});

this["Templates"]["SelectionWindow"]["NoEntrySelected"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"info\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Info",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":36}}}))
    + "</p>\r\n"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SelectionWindow.NoEntrySelected",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":42}}}));
},"useData":true});

this["Templates"]["SelectionWindow"]["Scheduling"]["Information"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SelectionWindow.Schedulings.Information.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":73}}}))
    + "</p>\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.State",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":11},"end":{"line":13,"column":32}}}))
    + "</p>\r\n        "
    + alias3((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":36}}}))
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"SelectionWindow.Schedulings.Information.Users",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":7},"end":{"line":18,"column":63}}}))
    + "</p>\r\n    <ul class=\"user-list bulleted-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Users") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"UserTitle")||(depth0 && lookupProperty(depth0,"UserTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"UserTitle","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":30}}}))
    + "\r\n        </li>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"SelectionWindow.Schedulings.Information.Teams",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":11},"end":{"line":29,"column":67}}}))
    + "</p>\r\n        <ul class=\"team-list bulleted-list stacked\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"TeamTitle")||(depth0 && lookupProperty(depth0,"TeamTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"TeamTitle","hash":{},"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":33,"column":38}}}))
    + "\r\n                </li>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"SelectionWindow.Schedulings.Information.Contacts",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":40,"column":11},"end":{"line":40,"column":70}}}))
    + "</p>\r\n        <ul class=\"contact-list bulleted-list stacked\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Contacts") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":46,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"ContactTitle")||(depth0 && lookupProperty(depth0,"ContactTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ContactTitle","hash":{},"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":44,"column":41}}}))
    + "\r\n                </li>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"SelectionWindow.Schedulings.Information.ContactGroups",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":51,"column":11},"end":{"line":51,"column":75}}}))
    + "</p>\r\n        <ul class=\"contact-group-list bulleted-list stacked\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ContactGroups") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":57,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"ContactGroupTitle")||(depth0 && lookupProperty(depth0,"ContactGroupTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"ContactGroupTitle","hash":{},"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":55,"column":46}}}))
    + "\r\n                </li>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"SelectionWindow.Schedulings.Information.Classifications",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":62,"column":11},"end":{"line":62,"column":77}}}))
    + "</p>\r\n        <ul class=\"classification-list bulleted-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Classifications") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":68,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\r\n                    "
    + container.escapeExpression((lookupProperty(helpers,"PropertyTitle")||(depth0 && lookupProperty(depth0,"PropertyTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"PropertyTitle","hash":{},"data":data,"loc":{"start":{"line":66,"column":20},"end":{"line":66,"column":42}}}))
    + "\r\n                </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"information\">\r\n    <h4>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":19}}}) : helper))) != null ? stack1 : "")
    + "</h4>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Description") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Priority",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":7},"end":{"line":9,"column":31}}}))
    + "</p>\r\n    "
    + alias3((lookupProperty(helpers,"ColoredProperty")||(depth0 && lookupProperty(depth0,"ColoredProperty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"PriorityOID") : depth0),{"name":"ColoredProperty","hash":{},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":35}}}))
    + "\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"StateOID") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Users") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Contacts") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ContactGroups") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Classifications") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":70,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["Templates"]["SessionManager"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CustomZIndex") || (depth0 != null ? lookupProperty(depth0,"CustomZIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CustomZIndex","hash":{},"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":57}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\r\n                            <td>\r\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Deletable") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":29,"column":115}}})) != null ? stack1 : "")
    + "\r\n                            </td>\r\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"Application") || (depth0 != null ? lookupProperty(depth0,"Application") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Application","hash":{},"data":data,"loc":{"start":{"line":31,"column":32},"end":{"line":31,"column":47}}}) : helper)))
    + "</td>\r\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"UserAgent") || (depth0 != null ? lookupProperty(depth0,"UserAgent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UserAgent","hash":{},"data":data,"loc":{"start":{"line":32,"column":32},"end":{"line":32,"column":45}}}) : helper)))
    + "</td>\r\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"LastAction") || (depth0 != null ? lookupProperty(depth0,"LastAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LastAction","hash":{},"data":data,"loc":{"start":{"line":33,"column":32},"end":{"line":33,"column":46}}}) : helper)))
    + "</td>\r\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"CreatedAt") || (depth0 != null ? lookupProperty(depth0,"CreatedAt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CreatedAt","hash":{},"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":34,"column":45}}}) : helper)))
    + "</td>\r\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"ExpiresAt") || (depth0 != null ? lookupProperty(depth0,"ExpiresAt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ExpiresAt","hash":{},"data":data,"loc":{"start":{"line":35,"column":32},"end":{"line":35,"column":45}}}) : helper)))
    + "</td>\r\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"IsCurrentSession") || (depth0 != null ? lookupProperty(depth0,"IsCurrentSession") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IsCurrentSession","hash":{},"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":36,"column":52}}}) : helper)))
    + "</td>\r\n                        </tr>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <input class=\"checkbox\" type=\"checkbox\" data-key="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Key") || (depth0 != null ? lookupProperty(depth0,"Key") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Key","hash":{},"data":data,"loc":{"start":{"line":29,"column":99},"end":{"line":29,"column":106}}}) : helper)))
    + "> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material session-manager\"\r\n     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CustomZIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":5},"end":{"line":2,"column":65}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":48}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n        <span>\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.ModalDescription",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":63}}}))
    + "\r\n        </span>\r\n\r\n        <div class=\"session-wrapper\">\r\n            <table>\r\n                <thead>\r\n                    <tr>\r\n                        <th>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.Select",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":69}}}))
    + "</th>\r\n                        <th>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.Application",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":74}}}))
    + "</th>\r\n                        <th>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.UserAgent",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":72}}}))
    + "</th>\r\n                        <th>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.LastAction",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":19,"column":73}}}))
    + "</th>\r\n                        <th>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.StartedAt",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":72}}}))
    + "</th>\r\n                        <th>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.ExpiresAt",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":72}}}))
    + "</th>\r\n                        <th></th>\r\n                    </tr>\r\n                </thead>\r\n                <tbody>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"SessionList") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":38,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\r\n            </table>\r\n        </div>\r\n\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-success pull-left btn-abort\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":46,"column":79},"end":{"line":46,"column":100}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-deleteAll\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.DeleteAllButton",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":47,"column":72},"end":{"line":47,"column":122}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-delete\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ManageSessions.DeleteButton",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":48,"column":69},"end":{"line":48,"column":116}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Settings"]["ApiInformationPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"APIInformation\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Categories.APIInformation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":57}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n        <div class=\"setting\">\r\n            <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.APIInformation.BaseURI",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":15},"end":{"line":9,"column":57}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"BaseURI") || (depth0 != null ? lookupProperty(depth0,"BaseURI") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"BaseURI","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":69}}}) : helper)))
    + "</p>\r\n        </div>\r\n\r\n        <div class=\"setting\">\r\n            <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.APIInformation.APIVersion",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":15},"end":{"line":13,"column":60}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"APIVersion") || (depth0 != null ? lookupProperty(depth0,"APIVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"APIVersion","hash":{},"data":data,"loc":{"start":{"line":13,"column":61},"end":{"line":13,"column":75}}}) : helper)))
    + "</p>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1), depth0))
    + "\" data-filename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImageFilename") : stack1), depth0))
    + "\" crossorigin=\"use-credentials\" />\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1), depth0))
    + "\" crossorigin=\"use-credentials\" />\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p><a href=\"mailto:"
    + alias4(((helper = (helper = lookupProperty(helpers,"EMailAddress") || (depth0 != null ? lookupProperty(depth0,"EMailAddress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EMailAddress","hash":{},"data":data,"loc":{"start":{"line":19,"column":39},"end":{"line":19,"column":55}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"EMailAddress") || (depth0 != null ? lookupProperty(depth0,"EMailAddress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EMailAddress","hash":{},"data":data,"loc":{"start":{"line":19,"column":57},"end":{"line":19,"column":73}}}) : helper)))
    + "</a></p>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"headline\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Settings.Teams",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":36},"end":{"line":28,"column":61}}}))
    + "</p>\r\n                <ul class=\"team-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":37,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>\r\n                            <div class=\"team\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),(depth0 != null ? lookupProperty(depth0,"ImagePath") : depth0),{"name":"RoundedImage","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                <span>"
    + container.escapeExpression((lookupProperty(helpers,"TeamTitle")||(depth0 && lookupProperty(depth0,"TeamTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OID") : depth0),{"name":"TeamTitle","hash":{},"data":data,"loc":{"start":{"line":34,"column":38},"end":{"line":34,"column":60}}}))
    + "</span>\r\n                            </div>\r\n                        </li>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSyncPanel"),depth0,{"name":"SettingsSyncPanel","hash":{"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"Synchronization") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ShowNfcPanel") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsApiInformationPanel"),depth0,{"name":"SettingsApiInformationPanel","hash":{"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"APIInformation") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1),"APIVersion":(depth0 != null ? lookupProperty(depth0,"APIVersion") : depth0),"BaseUri":(depth0 != null ? lookupProperty(depth0,"BaseUri") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsNfcPanel"),depth0,{"name":"SettingsNfcPanel","hash":{"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"NFC") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.helperMissing, alias5="function", alias6=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"settings\">\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-lg-6\">\r\n            <div class=\"col-xs-4 col-md-3 \">\r\n                <a class=\"file image thumbnail\" data-type=\"image\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"HasPersonalImage") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":10,"column":27}}})) != null ? stack1 : "")
    + "                </a>\r\n            </div>\r\n            <div class=\"col-xs-8 col-md-9\">\r\n                <strong>"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "</strong>\r\n\r\n                <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Client") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</p>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EMailAddress") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "\r\n                <p> "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias4).call(alias1,"Settings.Username",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":48}}}))
    + ": "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Username") : stack1), depth0))
    + "</p>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-lg-6 teams\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Teams") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n    <p class=\"headline\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias4).call(alias1,"Settings.Settings",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":52}}}))
    + "</p>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsViewPanel"),depth0,{"name":"SettingsViewPanel","hash":{"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"Views") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsIssueReportPanel"),depth0,{"name":"SettingsIssueReportPanel","hash":{"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"IssueReports") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1),"DeadlineFilterPreset":((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"DeadlineFilterPreset") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsRecordingPanel"),depth0,{"name":"SettingsRecordingPanel","hash":{"AutomaticallyOpenNextCheckpointItems":(depth0 != null ? lookupProperty(depth0,"AutomaticallyOpenNextCheckpointItems") : depth0),"AutomaticallyChangeRoomAfterRecordingItems":(depth0 != null ? lookupProperty(depth0,"AutomaticallyChangeRoomAfterRecordingItems") : depth0),"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"Recording") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias4),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":4},"end":{"line":53,"column":33}}}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsMiscPanel"),depth0,{"name":"SettingsMiscPanel","hash":{"IsCollapsed":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Settings") : depth0)) != null ? lookupProperty(stack1,"Settings") : stack1)) != null ? lookupProperty(stack1,"Misc") : stack1)) != null ? lookupProperty(stack1,"IsCollapsed") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias4),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":62,"column":33}}}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"usePartial":true,"useData":true});

this["Templates"]["Settings"]["DeadlinePreset"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"setting\">\r\n    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":62}}}))
    + "</p>\r\n\r\n    <div class=\"btn-group-vertical screen-max-sm deadline-filter-preset\">\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",0,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":98}}})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.Disabled",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":64}}}))
    + "\r\n        </button>\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",7,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":98}}})) != null ? stack1 : "")
    + "\" data-days=\"7\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.7Days",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":61}}}))
    + "\r\n        </button>\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",14,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":99}}})) != null ? stack1 : "")
    + "\" data-days=\"14\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.14Days",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":62}}}))
    + "\r\n        </button>\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",30,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":52},"end":{"line":14,"column":99}}})) != null ? stack1 : "")
    + "\" data-days=\"30\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.30Days",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":62}}}))
    + "\r\n        </button>\r\n    </div>\r\n\r\n    <div class=\"btn-group screen-min-md deadline-filter-preset\">\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",0,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":52},"end":{"line":20,"column":98}}})) != null ? stack1 : "")
    + "\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.Disabled",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":64}}}))
    + "\r\n        </button>\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",7,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":52},"end":{"line":23,"column":98}}})) != null ? stack1 : "")
    + "\" data-days=\"7\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.7Days",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":61}}}))
    + "\r\n        </button>\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",14,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":52},"end":{"line":26,"column":99}}})) != null ? stack1 : "")
    + "\" data-days=\"14\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.14Days",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":62}}}))
    + "\r\n        </button>\r\n        <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"FilterPreset") : depth0),"===",30,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":52},"end":{"line":29,"column":99}}})) != null ? stack1 : "")
    + "\" data-days=\"30\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.AutomaticDeadlineFilter.30Days",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":62}}}))
    + "\r\n        </button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["IssueReportPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"IssueReports\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Settings.Categories.IssueReports",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":55}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowAllIssuesInOneReport","LangKey":"Settings.ShowAllIssuesInOneReport.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowIdInIssueReports","LangKey":"Settings.ShowIdInIssueReports.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowCreationTimestampInIssueReports","LangKey":"Settings.ShowCreationTimestampInIssueReports.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowResponsibilitiesInIssueReports","LangKey":"Settings.ShowResponsibilitiesInIssueReports.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowLocationInIssueReports","LangKey":"Settings.ShowLocationInIssueReports.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowOnlyMyIssuesPerDefault","LangKey":"Settings.ShowOnlyMyIssuesPerDefault.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsDeadlinePresetItem"),depth0,{"name":"SettingsDeadlinePresetItem","hash":{"FilterPreset":(depth0 != null ? lookupProperty(depth0,"DeadlineFilterPreset") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbDisableProcessingStatusSmiley","LangKey":"Settings.DisableProcessingStatusSmiley.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["Settings"]["ItemPreset"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Active") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":56},"end":{"line":6,"column":84}}})) != null ? stack1 : "")
    + "\" data-value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Value") || (depth0 != null ? lookupProperty(depth0,"Value") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Value","hash":{},"data":data,"loc":{"start":{"line":6,"column":98},"end":{"line":6,"column":107}}}) : helper)))
    + "\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"TitleKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":33}}}))
    + "\r\n            </button>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"setting\">\r\n    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DescriptionLangKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":35}}}))
    + "</p>\r\n\r\n    <div class=\"btn-group-vertical screen-max-sm "
    + alias3(((helper = (helper = lookupProperty(helpers,"PresetClass") || (depth0 != null ? lookupProperty(depth0,"PresetClass") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"PresetClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":64}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":9,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"btn-group screen-min-md "
    + alias3(((helper = (helper = lookupProperty(helpers,"PresetClass") || (depth0 != null ? lookupProperty(depth0,"PresetClass") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"PresetClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":40},"end":{"line":12,"column":55}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["MiscPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbEnableDynamicTextSize","LangKey":"Settings.EnableDynamicTextSize.Description"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BluetoothSettingIsEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"CodeScannerIsAvailable") || (depth0 != null ? lookupProperty(depth0,"CodeScannerIsAvailable") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"CodeScannerIsAvailable","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":38,"column":39}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"CodeScannerIsAvailable")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"setting\">\r\n                    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Bluetooth.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":23},"end":{"line":18,"column":64}}}))
    + "</p>\r\n\r\n                    <a href=\"#bluetooth-configuration\">\r\n                        <button class=\"btn btn-default\" data-property=\"state\" data-action=\"popup\">\r\n                            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Bluetooth.GoTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":62}}}))
    + "\r\n                        </button>\r\n                    </a>\r\n                </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"setting\">\r\n                    <p>"
    + alias3((lookupProperty(helpers,"CodeScannerValues")||(depth0 && lookupProperty(depth0,"CodeScannerValues"))||alias2).call(alias1,"Name",{"name":"CodeScannerValues","hash":{},"data":data,"loc":{"start":{"line":30,"column":23},"end":{"line":30,"column":51}}}))
    + "</p>\r\n\r\n                    <a href=\"#code-scanner\">\r\n                        <button class=\"btn btn-default\" data-property=\"state\" data-action=\"popup\">\r\n                            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.CodeScanner.GoTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":34,"column":64}}}))
    + "\r\n                        </button>\r\n                    </a>\r\n                </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"IfAPIVersionIsGreaterOrEqual")||(depth0 && lookupProperty(depth0,"IfAPIVersionIsGreaterOrEqual"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),2,{"name":"IfAPIVersionIsGreaterOrEqual","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":47,"column":45}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbEnablePushNotifications","LangKey":"Settings.EnablePushNotifications.Description"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"setting\">\r\n                <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.DeleteData.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":67,"column":19},"end":{"line":67,"column":61}}}))
    + "</p>\r\n                <button type=\"button\" class=\"btn btn-danger raised"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SynchronisationInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":66},"end":{"line":68,"column":115}}})) != null ? stack1 : "")
    + "\" data-action=\"delete-data\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.DeleteData.Delete",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":68,"column":143},"end":{"line":68,"column":180}}}))
    + "</button>\r\n            </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"Misc\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Categories.Misc",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":47}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":13,"column":37}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":39,"column":37}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":48,"column":37}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n        <div class=\"setting\">\r\n            <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ChangeCredentials.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":51,"column":15},"end":{"line":51,"column":64}}}))
    + "</p>\r\n            <button type=\"button\" class=\"btn btn-default raised btn-change-credentials\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ChangeCredentials.ButtonText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":52,"column":88},"end":{"line":52,"column":136}}}))
    + "</button>\r\n        </div>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbSettingsUseAnalytics","LangKey":"Settings.GoogleAnalytics.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbSettingsAdvancedAnalytics","LangKey":"Settings.AdvancedAnalytics.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":70,"column":37}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["Settings"]["NfcInfoWindow"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal info-window\" id=\"settings-nfc-info-window\">\r\n    <div class=\"modal-dialog\">\r\n        <div class=\"modal-content\">\r\n            <div class=\"modal-header\">\r\n                <h4 class=\"modal-title\" id=\"title\">\r\n                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.WriteNfcTag.InfoWindowTitle",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":67}}}))
    + "\r\n                </h4>\r\n            </div>\r\n\r\n            <div class=\"modal-body\">\r\n                <p><strong>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.WriteNfcTag.InfoWindowText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":27},"end":{"line":11,"column":73}}}))
    + "</strong></p>\r\n                <img src=\"./img/nfc_scan.svg\" height=\"100\">\r\n            </div>\r\n\r\n            <div class=\"modal-footer\">\r\n                <button type=\"button\" class=\"btn btn-danger btn-close flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":76},"end":{"line":16,"column":97}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["NfcPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"NFC\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Categories.NFC",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":46}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n        <div class=\"setting\">\r\n            <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.WriteNfcTag.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":15},"end":{"line":9,"column":58}}}))
    + "</p>\r\n            <button type=\"button\" class=\"btn btn-default\" data-action=\"write-to-nfc-tag\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.WriteNfcTag.SelectLocation",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":62}}}))
    + "\r\n            </button>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["NumberItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CssClasses") || (depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CssClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":52}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"input-group-addon\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"UnitLangKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":44},"end":{"line":5,"column":64}}}))
    + "</span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " step=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Step") || (depth0 != null ? lookupProperty(depth0,"Step") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Step","hash":{},"data":data,"loc":{"start":{"line":8,"column":105},"end":{"line":8,"column":113}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Value") || (depth0 != null ? lookupProperty(depth0,"Value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Value","hash":{},"data":data,"loc":{"start":{"line":8,"column":142},"end":{"line":8,"column":151}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"setting"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":59}}})) != null ? stack1 : "")
    + "\">\r\n    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"LangKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":23}}}))
    + "</p>\r\n    <div class=\"input-group\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"UnitLangKey") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <input type=\"number\" inputmode=\"numeric\" class=\"form-control\" id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"InputId") || (depth0 != null ? lookupProperty(depth0,"InputId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"InputId","hash":{},"data":data,"loc":{"start":{"line":8,"column":74},"end":{"line":8,"column":85}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Step") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":86},"end":{"line":8,"column":121}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":121},"end":{"line":8,"column":159}}})) != null ? stack1 : "")
    + ">\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["RecordingPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowIssueViewerAfterTakingPhotoDuringInspection","LangKey":"Settings.ShowIssueViewerAfterTakingPhotoDuringInspection.Description"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"Recording\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Categories.Recording",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":52}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAlwaysShowAdvancedRecorditemEditor","LangKey":"Settings.AlwaysShowAdvancedRecorditemEditor.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowCheckpointToolbar","LangKey":"Settings.ShowCheckpointToolbar.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsSmartDeviceApplication","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":23,"column":37}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowTabularSubsampleByDefault","LangKey":"Settings.ShowTabularSubsampleByDefault.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbTabularSubsampleShowNumber","LangKey":"Settings.TabularSubsampleShowNumber.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbTabularSubsampleConsistentWidth","LangKey":"Settings.TabularSubsampleConsistentWidth.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsItemPreset"),depth0,{"name":"SettingsItemPreset","hash":{"Items":(depth0 != null ? lookupProperty(depth0,"AutomaticallyOpenNextCheckpointItems") : depth0),"PresetClass":"automatically-open-next-checkpoint","DescriptionLangKey":"Settings.AutomaticallyOpenNextCheckpoint.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAskIfCreateIssueAfterPhotoComment","LangKey":"Settings.AskIfCreateIssueAfterPhotoComment.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAskIfCreateIssueAfterTextComment","LangKey":"Settings.AskIfCreateIssueAfterTextComment.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbEnableTwoStateCheckbox","LangKey":"Settings.EnableTwoStateCheckbox.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbConfirmOverwriteExistingPhotos","LangKey":"Settings.ConfirmOverwriteExistingPhotos.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsItemPreset"),depth0,{"name":"SettingsItemPreset","hash":{"Items":(depth0 != null ? lookupProperty(depth0,"AutomaticallyChangeRoomAfterRecordingItems") : depth0),"PresetClass":"automatically-change-room-after-recording","DescriptionLangKey":"Settings.AutomaticallyChangeRoomAfterRecording.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbRedirectToMainMenuAfterClosingIssueView","LangKey":"Settings.RedirectToMainMenuAfterClosingIssueView.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbSidebarDefaultVisibility","LangKey":"Settings.SidebarDefaultVisibility.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowCreatorAndTimeInSidebar","LangKey":"Settings.ShowCreatorAndTimeInSidebar.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAskForFollowStateAfterCompletion","LangKey":"Settings.AskForFollowStateAfterCompletion.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowNextStateOnUnrecorded","LangKey":"Settings.ShowNextStateOnUnrecorded.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbPasteImageAsSignature","LangKey":"Settings.PasteImageAsSignature.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowElementInfoButtons","LangKey":"Settings.ShowElementInfoButtons.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n</div>";
},"usePartial":true,"useData":true});

this["Templates"]["Settings"]["SimpleItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CssClasses") || (depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CssClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":52}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TitleClasses") || (depth0 != null ? lookupProperty(depth0,"TitleClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TitleClasses","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":50}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"setting"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":59}}})) != null ? stack1 : "")
    + "\">\r\n    <p"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TitleClasses") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":58}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"LangKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":75}}}))
    + "</p>\r\n    <input type=\"checkbox\" id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"CbId") || (depth0 != null ? lookupProperty(depth0,"CbId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"CbId","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":39}}}) : helper)))
    + "\">\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["SyncPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"Synchronization\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Settings.Categories.Synchronization",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":58}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAutoSync","LangKey":"Settings.AutoSync.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsNumberItem"),depth0,{"name":"SettingsNumberItem","hash":{"Step":1,"UnitLangKey":"Settings.AutoSyncInterval.Unit","InputId":"autoSyncIntervalValue","LangKey":"Settings.AutoSyncInterval.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAllowAutoSyncOverCell","LangKey":"Settings.AllowAutoSyncOverCell.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbSyncAtApplicationStart","LangKey":"Settings.SyncAtApplicationStart.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbAskBeforeSynchronisation","LangKey":"Settings.AskBeforeSynchronisation.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbEnableSyncLog","LangKey":"Settings.EnableSyncLog.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["Settings"]["TextItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CssClasses") || (depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CssClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":52}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Value") || (depth0 != null ? lookupProperty(depth0,"Value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Value","hash":{},"data":data,"loc":{"start":{"line":4,"column":85},"end":{"line":4,"column":94}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"setting"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":59}}})) != null ? stack1 : "")
    + "\">\r\n    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"LangKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":23}}}))
    + "</p>\r\n    <div class=\"input-group\">\r\n        <input type=\"text\" class=\"form-control\" id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"InputId") || (depth0 != null ? lookupProperty(depth0,"InputId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"InputId","hash":{},"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":63}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Value") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":64},"end":{"line":4,"column":102}}})) != null ? stack1 : "")
    + ">\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Settings"]["ViewPanel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowTreeCounters","LangKey":"Settings.ShowTreeCounters.Description"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowPrioritizedFiles","LangKey":"Settings.ShowPrioritizedFiles.Description"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbEnableViewRefresh","LangKey":"Settings.EnableViewRefresh.Description"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsNumberItem"),depth0,{"name":"SettingsNumberItem","hash":{"Step":1,"UnitLangKey":"Settings.ViewRefreshTimeout.Unit","InputId":"numberSettingsViewRefreshTimeout","LangKey":"Settings.ViewRefreshTimeout.Description"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"panel collapsable panel-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsCollapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + "\" data-panel=\"Views\">\r\n    <div class=\"panel-heading\">\r\n        <span class=\"text\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.Categories.Views",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":48}}}))
    + "\r\n        </span>\r\n    </div>\r\n    <div class=\"panel-body\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowMenuItemsAsTiles","LangKey":"Settings.ShowMenuItemsAsTiles.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CssClasses":"show-treeview","CbId":"cbSettingsShowTreeView","LangKey":"Settings.ShowTreeView.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n        <div class=\"setting reset-location-picker-view\">\r\n            <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ResetLocationPickerView.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":15},"end":{"line":20,"column":70}}}))
    + "</p>\r\n            <button type=\"button\" class=\"btn btn-default raised btn-reset-location-picker-view\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ResetLocationPickerView.ButtonText",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":96},"end":{"line":21,"column":150}}}))
    + "</button>\r\n        </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfAPIVersionIsGreaterOrEqual")||(depth0 && lookupProperty(depth0,"IfAPIVersionIsGreaterOrEqual"))||alias2).call(alias1,5,{"name":"IfAPIVersionIsGreaterOrEqual","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":29,"column":41}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowColorsInLocationPicker","LangKey":"Settings.ShowColorsInLocationPicker.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbSettingsFoldParametergroups","LangKey":"Settings.FoldParametergroups.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfAPIVersionIsGreaterOrEqual")||(depth0 && lookupProperty(depth0,"IfAPIVersionIsGreaterOrEqual"))||alias2).call(alias1,2,{"name":"IfAPIVersionIsGreaterOrEqual","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":46,"column":41}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowEvaluationInElementInformation","LangKey":"Settings.ShowEvaluationInElementInformation.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbExpandTreeInSelectionWindow","LangKey":"Settings.ExpandTreeInSelectionWindow.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowOnlyAssignedForms","LangKey":"Settings.ShowOnlyAssignedForms.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SettingsSimpleItem"),depth0,{"name":"SettingsSimpleItem","hash":{"CbId":"cbShowRecordingPicturesInOverviews","LangKey":"Settings.ShowRecordingPicturesInOverviews.Description"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"IsNotSmartDeviceApplication") || (depth0 != null ? lookupProperty(depth0,"IsNotSmartDeviceApplication") : depth0)) != null ? helper : alias2),(options={"name":"IsNotSmartDeviceApplication","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":8},"end":{"line":80,"column":40}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"IsNotSmartDeviceApplication")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true});

this["Templates"]["Spinner"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"zIndex") || (depth0 != null ? lookupProperty(depth0,"zIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"zIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":51},"end":{"line":1,"column":61}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"spinner\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"zIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":69}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"content\">\r\n        <div class=\"animation\"></div>\r\n        <p class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(alias1,"Misc.PleaseWait",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":50}}}))
    + "</p>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["SpinnerActionButton"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"z-index: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ZIndex") || (depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ZIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":65},"end":{"line":1,"column":75}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CssClasses") || (depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CssClasses","hash":{},"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":55}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"spinner-action-button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ZIndex") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":83}}})) != null ? stack1 : "")
    + ">\r\n    <button class=\"btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"CssClasses") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":62}}})) != null ? stack1 : "")
    + "\" id=\"spinnerActionButton\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Text") || (depth0 != null ? lookupProperty(depth0,"Text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Text","hash":{},"data":data,"loc":{"start":{"line":2,"column":89},"end":{"line":2,"column":97}}}) : helper)))
    + "</button>\r\n</div>\r\n\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["Content"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sync-center\">\r\n    <ul class=\"with-type-icons\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Entities") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n</div>";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["Comment"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " upload-error";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.ModifiedOn",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":48}}}))
    + "&nbsp;\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":63}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"IssueID") || (depth0 != null ? lookupProperty(depth0,"IssueID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"IssueID","hash":{},"data":data,"loc":{"start":{"line":37,"column":64},"end":{"line":37,"column":75}}}) : helper)))
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":44,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":63}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"IssueOID") || (depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"IssueOID","hash":{},"data":data,"loc":{"start":{"line":39,"column":64},"end":{"line":39,"column":76}}}) : helper)))
    + "\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":44,"column":12}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":41,"column":68}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"RecorditemID") || (depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"RecorditemID","hash":{},"data":data,"loc":{"start":{"line":41,"column":69},"end":{"line":41,"column":85}}}) : helper)))
    + "\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecorditemOID") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":44,"column":12}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":68}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"RecorditemOID") || (depth0 != null ? lookupProperty(depth0,"RecorditemOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"RecorditemOID","hash":{},"data":data,"loc":{"start":{"line":43,"column":69},"end":{"line":43,"column":86}}}) : helper)))
    + "\r\n            ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":58,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n        </div>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":55,"column":72}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"RecorditemID") || (depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"RecorditemID","hash":{},"data":data,"loc":{"start":{"line":55,"column":73},"end":{"line":55,"column":89}}}) : helper)))
    + "\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecorditemOID") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":58,"column":16}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":57,"column":20},"end":{"line":57,"column":72}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"RecorditemOID") || (depth0 != null ? lookupProperty(depth0,"RecorditemOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"RecorditemOID","hash":{},"data":data,"loc":{"start":{"line":57,"column":73},"end":{"line":57,"column":90}}}) : helper)))
    + "\r\n                ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":111}}})) != null ? stack1 : "")
    + "\" data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":123},"end":{"line":1,"column":130}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":132},"end":{"line":1,"column":187}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":188},"end":{"line":1,"column":240}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":60}}})) != null ? stack1 : "")
    + "> <div class=\"type-icon\">\r\n    <span class=\"icon-bubble\"></span>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.OID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":37}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":19}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.Created",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":41}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"IfShowDatePreposition")||(depth0 && lookupProperty(depth0,"IfShowDatePreposition"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RawModificationTimestamp") : depth0),{"name":"IfShowDatePreposition","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":24,"column":38}}})) != null ? stack1 : "")
    + "\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":25}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.ModifiedBy",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":26,"column":26},"end":{"line":26,"column":58}}}))
    + " "
    + alias4((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Creator") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":26,"column":59},"end":{"line":26,"column":89}}}))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":44}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RecorditemOID") : depth0),(depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0),{"name":"Or","hash":{},"data":data,"loc":{"start":{"line":48,"column":11},"end":{"line":48,"column":42}}}),(lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"Or","hash":{},"data":data,"loc":{"start":{"line":48,"column":43},"end":{"line":48,"column":64}}}),{"name":"And","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":61,"column":12}}})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["CommentDelete"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " upload-error";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.ModifiedOn",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":31,"column":59},"end":{"line":31,"column":91}}}))
    + " ";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":63}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"IssueID") || (depth0 != null ? lookupProperty(depth0,"IssueID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"IssueID","hash":{},"data":data,"loc":{"start":{"line":42,"column":64},"end":{"line":42,"column":75}}}) : helper)))
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":53,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":44,"column":63}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"IssueOID") || (depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"IssueOID","hash":{},"data":data,"loc":{"start":{"line":44,"column":64},"end":{"line":44,"column":76}}}) : helper)))
    + "\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":53,"column":12}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":46,"column":68}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"RecorditemID") || (depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"RecorditemID","hash":{},"data":data,"loc":{"start":{"line":46,"column":69},"end":{"line":46,"column":85}}}) : helper)))
    + "\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"IsIssueComment") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + "            ";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":49,"column":67}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"AssignmentOID") || (depth0 != null ? lookupProperty(depth0,"AssignmentOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"AssignmentOID","hash":{},"data":data,"loc":{"start":{"line":49,"column":68},"end":{"line":49,"column":85}}}) : helper)))
    + "\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":72}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"AssignmentOID") || (depth0 != null ? lookupProperty(depth0,"AssignmentOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"AssignmentOID","hash":{},"data":data,"loc":{"start":{"line":51,"column":73},"end":{"line":51,"column":90}}}) : helper)))
    + "\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":63,"column":16},"end":{"line":67,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n        </div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":72}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"RecorditemID") || (depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"RecorditemID","hash":{},"data":data,"loc":{"start":{"line":64,"column":73},"end":{"line":64,"column":89}}}) : helper)))
    + "\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AssignmentOID") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":67,"column":16}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":66,"column":20},"end":{"line":66,"column":72}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"AssignmentOID") || (depth0 != null ? lookupProperty(depth0,"AssignmentOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"AssignmentOID","hash":{},"data":data,"loc":{"start":{"line":66,"column":73},"end":{"line":66,"column":90}}}) : helper)))
    + "\r\n                ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":111}}})) != null ? stack1 : "")
    + "\" data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":123},"end":{"line":1,"column":130}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":132},"end":{"line":1,"column":187}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":188},"end":{"line":1,"column":240}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":60}}})) != null ? stack1 : "")
    + "> <div class=\"type-icon\">\r\n    <img src=\"./img/crosschecked.svg\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Comment",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":61}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.OID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":37}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":19}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.Deleted",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":41}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + ((stack1 = (lookupProperty(helpers,"IfShowDatePreposition")||(depth0 && lookupProperty(depth0,"IfShowDatePreposition"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RawDeletionTimestamp") : depth0),{"name":"IfShowDatePreposition","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":118}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"DeletionTimestamp") || (depth0 != null ? lookupProperty(depth0,"DeletionTimestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DeletionTimestamp","hash":{},"data":data,"loc":{"start":{"line":31,"column":118},"end":{"line":31,"column":139}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":44}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),{"name":"Or","hash":{},"data":data,"loc":{"start":{"line":57,"column":11},"end":{"line":57,"column":32}}}),(lookupProperty(helpers,"Not")||(depth0 && lookupProperty(depth0,"Not"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsIssueComment") : depth0),{"name":"Not","hash":{},"data":data,"loc":{"start":{"line":57,"column":33},"end":{"line":57,"column":53}}}),{"name":"And","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":70,"column":12}}})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["Default"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"upload-error\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":48}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":37}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":23,"column":38},"end":{"line":23,"column":106}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n        </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"IssueID") || (depth0 != null ? lookupProperty(depth0,"IssueID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IssueID","hash":{},"data":data,"loc":{"start":{"line":23,"column":53},"end":{"line":23,"column":64}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"IssueOID") || (depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IssueOID","hash":{},"data":data,"loc":{"start":{"line":23,"column":66},"end":{"line":23,"column":78}}}) : helper)))
    + ")";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"IssueOID") || (depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IssueOID","hash":{},"data":data,"loc":{"start":{"line":23,"column":87},"end":{"line":23,"column":99}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + " class=\"no-meta\" data-filename=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":1,"column":110},"end":{"line":1,"column":122}}}) : helper)))
    + "\" data-issueoid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":154},"end":{"line":1,"column":206}}})) != null ? stack1 : "")
    + " data-oid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":60}}})) != null ? stack1 : "")
    + "> <div class=\"type-icon\">\r\n    <img src=\"./img/question-outline.svg\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.OID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":37}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":19}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"Or","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["File"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"upload-error\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Abbreviation") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":82}}})) != null ? stack1 : "")
    + "."
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":101},"end":{"line":13,"column":148}}})) != null ? stack1 : "")
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    return "0";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + ")";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":70}}})) != null ? stack1 : "")
    + "."
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":94},"end":{"line":15,"column":151}}})) != null ? stack1 : "")
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + ")";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + " class=\"no-meta\" data-filename=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":1,"column":108},"end":{"line":1,"column":120}}}) : helper)))
    + "\" data-issueoid=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":151},"end":{"line":1,"column":202}}})) != null ? stack1 : "")
    + " data-oid=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":247},"end":{"line":1,"column":303}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"type-icon\">\r\n        <img src=\"./img/file.svg\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"ol-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":44}}}))
    + ":\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["Image"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"upload-error\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Abbreviation") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":82}}})) != null ? stack1 : "")
    + "."
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":101},"end":{"line":13,"column":148}}})) != null ? stack1 : "")
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    return "0";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + ")";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":70}}})) != null ? stack1 : "")
    + "."
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":94},"end":{"line":15,"column":151}}})) != null ? stack1 : "")
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + ")";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"GetImageWithMarks")||(depth0 && lookupProperty(depth0,"GetImageWithMarks"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"FileMeta") : depth0),"",false,{"name":"GetImageWithMarks","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":47}}}))
    + "\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"FilePath") || (depth0 != null ? lookupProperty(depth0,"FilePath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"FilePath","hash":{},"data":data,"loc":{"start":{"line":25,"column":18},"end":{"line":25,"column":30}}}) : helper)))
    + "\" />\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + " data-filename=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":1,"column":92},"end":{"line":1,"column":104}}}) : helper)))
    + "\" data-issueoid=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":135},"end":{"line":1,"column":186}}})) != null ? stack1 : "")
    + " data-oid=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":231},"end":{"line":1,"column":287}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"type-icon\">\r\n        <img src=\"./img/image.svg\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"ol-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":44}}}))
    + ":\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n    <br>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FileMeta") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "</li>";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["Issue"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"upload-error\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":1,"column":141},"end":{"line":1,"column":147}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"#issue/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":27}}}) : helper)))
    + "\">\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":47}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    return "0";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.PrecedingOID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":50}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias3(((helper = (helper = lookupProperty(helpers,"PrecedingOID") || (depth0 != null ? lookupProperty(depth0,"PrecedingOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"PrecedingOID","hash":{},"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":32}}}) : helper)))
    + "\r\n            </div>\r\n        </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.FollowerOID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":44,"column":49}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias3(((helper = (helper = lookupProperty(helpers,"FollowerOID") || (depth0 != null ? lookupProperty(depth0,"FollowerOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"FollowerOID","hash":{},"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":48,"column":31}}}) : helper)))
    + "\r\n            </div>\r\n        </div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":56,"column":48}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias3(((helper = (helper = lookupProperty(helpers,"AssignedRecorditemOID") || (depth0 != null ? lookupProperty(depth0,"AssignedRecorditemOID") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"AssignedRecorditemOID","hash":{},"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":41}}}) : helper)))
    + "\r\n            </div>\r\n        </div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.Created",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":67,"column":42},"end":{"line":67,"column":71}}}));
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.Modified",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":67,"column":79},"end":{"line":67,"column":109}}}));
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.ModifiedOn",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":71,"column":67},"end":{"line":71,"column":99}}}))
    + " ";
},"25":function(container,depth0,helpers,partials,data) {
    return "    </a>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + " data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":87},"end":{"line":1,"column":94}}}) : helper)))
    + "\" data-issuetype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":1,"column":112},"end":{"line":1,"column":120}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":121},"end":{"line":1,"column":155}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":155},"end":{"line":1,"column":211}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"EditModeIsActive") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"type-icon\">\r\n            <img src=\"./img/"
    + alias4(((helper = (helper = lookupProperty(helpers,"TypeIconFilename") || (depth0 != null ? lookupProperty(depth0,"TypeIconFilename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TypeIconFilename","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":48}}}) : helper)))
    + "\" />\r\n        </div>\r\n\r\n        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"Abbrevation") || (depth0 != null ? lookupProperty(depth0,"Abbrevation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Abbrevation","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":31}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":63}}})) != null ? stack1 : "")
    + "."
    + alias4(((helper = (helper = lookupProperty(helpers,"Revision") || (depth0 != null ? lookupProperty(depth0,"Revision") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Revision","hash":{},"data":data,"loc":{"start":{"line":11,"column":64},"end":{"line":11,"column":76}}}) : helper)))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias4((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Title") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":44}}}))
    + "\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.OID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":41}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":23}}}) : helper)))
    + "\r\n            </div>\r\n        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PrecedingOID") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FollowerOID") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AssignedRecorditemOID") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Revision") : depth0),"===",1,{"name":"Test","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":67,"column":118}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + ((stack1 = (lookupProperty(helpers,"IfShowDatePreposition")||(depth0 && lookupProperty(depth0,"IfShowDatePreposition"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RawModificationTimestamp") : depth0),{"name":"IfShowDatePreposition","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":71,"column":126}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"ModificationTimestamp") || (depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ModificationTimestamp","hash":{},"data":data,"loc":{"start":{"line":71,"column":126},"end":{"line":71,"column":151}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.ModifiedBy",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":71,"column":152},"end":{"line":71,"column":184}}}))
    + " "
    + alias4((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Editor") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":71,"column":185},"end":{"line":71,"column":214}}}))
    + "\r\n            </div>\r\n        </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"EditModeIsActive") : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":76,"column":15}}})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["Recorditem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " upload-error";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.Created",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":38},"end":{"line":28,"column":67}}}));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.Modified",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":28,"column":75},"end":{"line":28,"column":105}}}));
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.ModifiedOn",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":63},"end":{"line":32,"column":95}}}))
    + " ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":48}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":37}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":43,"column":38},"end":{"line":43,"column":91}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n        </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"IssueID") || (depth0 != null ? lookupProperty(depth0,"IssueID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IssueID","hash":{},"data":data,"loc":{"start":{"line":43,"column":53},"end":{"line":43,"column":64}}}) : helper)));
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"IssueOID") || (depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IssueOID","hash":{},"data":data,"loc":{"start":{"line":43,"column":72},"end":{"line":43,"column":84}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":111}}})) != null ? stack1 : "")
    + "\" data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":123},"end":{"line":1,"column":130}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":131},"end":{"line":1,"column":185}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":185},"end":{"line":1,"column":236}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":236},"end":{"line":1,"column":292}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"type-icon\">\r\n        <img src=\"./img/pencil_write_48.png\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.OID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":37}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":19}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.ElementOID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":44}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"ElementOID") || (depth0 != null ? lookupProperty(depth0,"ElementOID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ElementOID","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":26}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Revision") : depth0),"===",1,{"name":"Test","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":114}}})) != null ? stack1 : "")
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + ((stack1 = (lookupProperty(helpers,"IfShowDatePreposition")||(depth0 && lookupProperty(depth0,"IfShowDatePreposition"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RawModificationTimestamp") : depth0),{"name":"IfShowDatePreposition","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":122}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"ModificationTimestamp") || (depth0 != null ? lookupProperty(depth0,"ModificationTimestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ModificationTimestamp","hash":{},"data":data,"loc":{"start":{"line":32,"column":122},"end":{"line":32,"column":147}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.ModifiedBy",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":148},"end":{"line":32,"column":180}}}))
    + " "
    + alias4((lookupProperty(helpers,"EscapeHTMLEntities")||(depth0 && lookupProperty(depth0,"EscapeHTMLEntities"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Editor") : depth0),{"name":"EscapeHTMLEntities","hash":{},"data":data,"loc":{"start":{"line":32,"column":181},"end":{"line":32,"column":210}}}))
    + "\r\n        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"Or","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["RecorditemDelete"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " upload-error";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"RecorditemID") || (depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"RecorditemID","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":32}}}) : helper)))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.ModifiedOn",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":34,"column":59},"end":{"line":34,"column":91}}}))
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\r\n            <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":41,"column":48}}}))
    + "\r\n            </div>\r\n\r\n            <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n                "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Issue",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":45,"column":37}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":45,"column":38},"end":{"line":45,"column":91}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n        </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"IssueID") || (depth0 != null ? lookupProperty(depth0,"IssueID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IssueID","hash":{},"data":data,"loc":{"start":{"line":45,"column":53},"end":{"line":45,"column":64}}}) : helper)));
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"IssueOID") || (depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"IssueOID","hash":{},"data":data,"loc":{"start":{"line":45,"column":72},"end":{"line":45,"column":84}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":111}}})) != null ? stack1 : "")
    + "\" data-oid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":1,"column":123},"end":{"line":1,"column":130}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":132},"end":{"line":1,"column":187}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":188},"end":{"line":1,"column":240}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":60}}})) != null ? stack1 : "")
    + "> <div class=\"type-icon\">\r\n    <img src=\"./img/crosschecked.svg\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Synchronization.SyncEntityType.Recorditem",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":64}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RecorditemID") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.OID",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":37}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"OID") || (depth0 != null ? lookupProperty(depth0,"OID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OID","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":19}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.Deleted",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":41}}}))
    + "\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n            "
    + ((stack1 = (lookupProperty(helpers,"IfShowDatePreposition")||(depth0 && lookupProperty(depth0,"IfShowDatePreposition"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"RawDeletionTimestamp") : depth0),{"name":"IfShowDatePreposition","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":118}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"DeletionTimestamp") || (depth0 != null ? lookupProperty(depth0,"DeletionTimestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DeletionTimestamp","hash":{},"data":data,"loc":{"start":{"line":34,"column":118},"end":{"line":34,"column":139}}}) : helper)))
    + "\r\n        </div>\r\n    </div>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IssueOID") : depth0),(depth0 != null ? lookupProperty(depth0,"IssueID") : depth0),{"name":"Or","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["RecorditemFileSummary"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix\">\r\n    <div class=\"type-icon\">\r\n        <img src=\"./img/image.svg\" />\r\n    </div>\r\n\r\n    "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.RecorditemFileEntityCount",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":51}}}))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"RecorditemFileCount") || (depth0 != null ? lookupProperty(depth0,"RecorditemFileCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RecorditemFileCount","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":76}}}) : helper)))
    + "\r\n</li>";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["RecorditemSummary"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix\">\r\n    <div class=\"type-icon\">\r\n        <img src=\"./img/pencil_write_48.png\" />\r\n    </div>\r\n\r\n    "
    + alias4((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.RecorditemEntityCount",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":47}}}))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"RecorditemCount") || (depth0 != null ? lookupProperty(depth0,"RecorditemCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RecorditemCount","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":68}}}) : helper)))
    + "\r\n</li>";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["ResponseRow"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            ["
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"LastModifiedDate") || (depth0 != null ? lookupProperty(depth0,"LastModifiedDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LastModifiedDate","hash":{},"data":data,"loc":{"start":{"line":11,"column":13},"end":{"line":11,"column":33}}}) : helper)))
    + "]\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            -\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":29}}}) : helper)))
    + "\" class=\"clearfix\">\r\n    <div class=\"col-xs-12 col-sm-4\">\r\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":17}}}) : helper)))
    + "\r\n    </div>\r\n\r\n    <div class=\"col-xs-12 col-sm-4\">\r\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":21}}}) : helper)))
    + "\r\n    </div>\r\n    <div class=\"col-xs-12 col-sm-4\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastModifiedDate") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n</li>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["EntityType"]["VoiceMail"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"upload-error\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-issueid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Abbreviation") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":82}}})) != null ? stack1 : "")
    + "."
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":101},"end":{"line":13,"column":148}}})) != null ? stack1 : "")
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    return "0";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + ")";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":70}}})) != null ? stack1 : "")
    + "."
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"Revision") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":94},"end":{"line":15,"column":151}}})) != null ? stack1 : "")
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Recorditem") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + ")";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"IsErroneous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + " data-filename=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"Filename") || (depth0 != null ? lookupProperty(depth0,"Filename") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Filename","hash":{},"data":data,"loc":{"start":{"line":1,"column":92},"end":{"line":1,"column":104}}}) : helper)))
    + "\" data-issueoid=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-oid=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Issue") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":180},"end":{"line":1,"column":231}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"SynchronisationProperties") : depth0)) != null ? lookupProperty(stack1,"Ignore") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":231},"end":{"line":1,"column":287}}})) != null ? stack1 : "")
    + ">\r\n    <div class=\"type-icon\">\r\n        <img src=\"./img/mic.svg\" />\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"ol-xs-12 col-sm-3 col-lg-2\">\r\n            "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.AssignedTo",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":44}}}))
    + ":\r\n        </div>\r\n\r\n        <div class=\"col-xs-12 col-sm-9 col-lg-10\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Issue") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n</li>";
},"useData":true});

this["Templates"]["SyncCenter"]["FileDownloadInfo"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"file-download-info\">\r\n    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"Key":"SyncCenter.FileDownloadInfo"},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":46}}}))
    + "\r\n\r\n    <div class=\"progress\">\r\n        <div class=\"progress-bar\" style=\"width: "
    + alias3(((helper = (helper = lookupProperty(helpers,"Percentage") || (depth0 != null ? lookupProperty(depth0,"Percentage") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"Percentage","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":62}}}) : helper)))
    + "%;\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"FormattedPercentage") || (depth0 != null ? lookupProperty(depth0,"FormattedPercentage") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"FormattedPercentage","hash":{},"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":89}}}) : helper)))
    + "%</div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["SyncCenter"]["FilesRemainingInfo"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"files-remaining-info\">\r\n    "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,{"name":"Lang","hash":{"Key":"SyncCenter.FilesRemainingInfo"},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":48}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"MissingFiles") || (depth0 != null ? lookupProperty(depth0,"MissingFiles") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"MissingFiles","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":65}}}) : helper)))
    + "\r\n</div>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["ResponseInformation"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"Markup") || (depth0 != null ? lookupProperty(depth0,"Markup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Markup","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.NoSynchronisationRecorded",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":63}}}))
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sync-center\">\r\n	<!-- TODO Funktion fixen und wieder reinnehmen (unsynchronisierte Daten wurden mitgelöscht) -->\r\n	<!--<div class=\"settings\">\r\n        <div class=\"row\">\r\n            <div class=\"col-xs-12\">\r\n                <div>\r\n                    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ResponseTimestamps.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":73}}}))
    + "</p>\r\n                    <button type=\"button\" class=\"btn btn-danger raised"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DisableResetButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":70},"end":{"line":8,"column":112}}})) != null ? stack1 : "")
    + "\" data-action=\"reset-response-timestamps\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.ResponseTimestamps.Reset",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":154},"end":{"line":8,"column":198}}}))
    + "</button>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>-->\r\n    <div class=\"settings\">\r\n        <div class=\"row\">\r\n            <div class=\"col-xs-12\">\r\n                <div>\r\n                    <p>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.IssueCache.Description",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":17,"column":23},"end":{"line":17,"column":65}}}))
    + "</p>\r\n                    <button type=\"button\" class=\"btn btn-danger raised"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DisableResetButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":70},"end":{"line":18,"column":112}}})) != null ? stack1 : "")
    + "\" data-action=\"reset-issue-cache\">\r\n                        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Settings.IssueCache.Reset",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":19,"column":60}}}))
    + "\r\n                    </button>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <ul class=\"response-information\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Markup") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["SupportMail"]["LastKnownHttpError"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Meldung: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Message") || (depth0 != null ? lookupProperty(depth0,"Message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Message","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":35}}}) : helper)))
    + "<br>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Entity-Typ: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"EntityType") || (depth0 != null ? lookupProperty(depth0,"EntityType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EntityType","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":44}}}) : helper)))
    + "<br>";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "EntityOID: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"EntityOID") || (depth0 != null ? lookupProperty(depth0,"EntityOID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EntityOID","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":41}}}) : helper)))
    + "<br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<u>Letzter bekannter Fehler</u><br>\r\nHttp-Methode: "
    + alias4(((helper = (helper = lookupProperty(helpers,"HttpMethod") || (depth0 != null ? lookupProperty(depth0,"HttpMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HttpMethod","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":28}}}) : helper)))
    + "<br>\r\nStatus-Code: "
    + alias4(((helper = (helper = lookupProperty(helpers,"StatusCode") || (depth0 != null ? lookupProperty(depth0,"StatusCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"StatusCode","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":27}}}) : helper)))
    + "<br>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":4,"column":46}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityType") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":55}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityOID") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":52}}})) != null ? stack1 : "")
    + "\r\nZeitpunkt: "
    + alias4((lookupProperty(helpers,"DateString")||(depth0 && lookupProperty(depth0,"DateString"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0),{"name":"DateString","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":35}}}))
    + "\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["SupportMail"]["Mail"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Client: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Client") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Client") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + ")<br>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "User: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Username") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + ")<br>\r\nSprache: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Language") : stack1), depth0))
    + "<br>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <br>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"LastKnownHttpErrorMarkup") || (depth0 != null ? lookupProperty(depth0,"LastKnownHttpErrorMarkup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LastKnownHttpErrorMarkup","hash":{},"data":data,"loc":{"start":{"line":24,"column":10},"end":{"line":24,"column":40}}}) : helper))) != null ? stack1 : "")
    + "<br>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <br>\r\n    <u>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"SyncCenter.ResponseTimestamps",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":29,"column":7},"end":{"line":29,"column":47}}}))
    + " (Serverzeiten)</u><br>\r\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ResponseTimestampsMarkup") || (depth0 != null ? lookupProperty(depth0,"ResponseTimestampsMarkup") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ResponseTimestampsMarkup","hash":{},"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":30,"column":34}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <br>\r\n    <br>\r\n    <u>Letzte Fehlermeldung</u><br>\r\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ErrorMessage") || (depth0 != null ? lookupProperty(depth0,"ErrorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ErrorMessage","hash":{},"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "App-Version: "
    + alias4(((helper = (helper = lookupProperty(helpers,"AppVersion") || (depth0 != null ? lookupProperty(depth0,"AppVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AppVersion","hash":{},"data":data,"loc":{"start":{"line":1,"column":13},"end":{"line":1,"column":27}}}) : helper)))
    + "<br><br>\r\n\r\nHersteller des Geräts: "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Device") : depth0)) != null ? lookupProperty(stack1,"manufacturer") : stack1), depth0))
    + "<br>\r\nModell: "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Device") : depth0)) != null ? lookupProperty(stack1,"model") : stack1), depth0))
    + "<br>\r\nOS: "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Device") : depth0)) != null ? lookupProperty(stack1,"platform") : stack1), depth0))
    + " "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"Device") : depth0)) != null ? lookupProperty(stack1,"version") : stack1), depth0))
    + "<br>\r\nVerbindungstyp: "
    + alias4(((helper = (helper = lookupProperty(helpers,"ConnectionType") || (depth0 != null ? lookupProperty(depth0,"ConnectionType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ConnectionType","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":34}}}) : helper)))
    + "<br><br>\r\n\r\nService-Adresse: "
    + alias4(((helper = (helper = lookupProperty(helpers,"ServiceURI") || (depth0 != null ? lookupProperty(depth0,"ServiceURI") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ServiceURI","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":31}}}) : helper)))
    + "<br>\r\nAPI-Version: "
    + alias4(((helper = (helper = lookupProperty(helpers,"APIVersion") || (depth0 != null ? lookupProperty(depth0,"APIVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"APIVersion","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":27}}}) : helper)))
    + "<br>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Client") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"User") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":17,"column":37}}})) != null ? stack1 : "")
    + "\r\n<br>\r\n\r\nGeräte-Zeit: "
    + alias4(((helper = (helper = lookupProperty(helpers,"DeviceTime") || (depth0 != null ? lookupProperty(depth0,"DeviceTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DeviceTime","hash":{},"data":data,"loc":{"start":{"line":20,"column":13},"end":{"line":20,"column":27}}}) : helper)))
    + "<br>\r\nZeitzone: "
    + alias4(((helper = (helper = lookupProperty(helpers,"Timezone") || (depth0 != null ? lookupProperty(depth0,"Timezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Timezone","hash":{},"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":21,"column":22}}}) : helper)))
    + " Stunden<br>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastKnownHttpErrorMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ResponseTimestampsMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":31,"column":7}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ErrorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":38,"column":7}}})) != null ? stack1 : "")
    + "<br>";
},"useData":true});

this["Templates"]["SyncCenter"]["SupportMail"]["ResponseTime"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"LastModifiedDate") || (depth0 != null ? lookupProperty(depth0,"LastModifiedDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LastModifiedDate","hash":{},"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":70}}}) : helper)))
    + ")";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":9}}}) : helper)))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"Timestamp") || (depth0 != null ? lookupProperty(depth0,"Timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Timestamp","hash":{},"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":24}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LastModifiedDate") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + "\r\n";
},"useData":true});

this["Templates"]["SyncCenter"]["UnsyncedData"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "danger";
},"3":function(container,depth0,helpers,partials,data) {
    return "success";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.SynchronisationInProgress",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":67}}}))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.StartSync",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":51}}}))
    + "\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-default pull-right"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EditModeIsActive") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":71},"end":{"line":17,"column":109}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SynchronisationInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":109},"end":{"line":17,"column":156}}})) != null ? stack1 : "")
    + "\" data-action=\"edit-sync-entities\">\r\n                    <i class=\"icon-pen-angled\"></i>\r\n                </button>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " active";
},"14":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"16":function(container,depth0,helpers,partials,data) {
    return " with-type-icons";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"EntityMarkup") || (depth0 != null ? lookupProperty(depth0,"EntityMarkup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EntityMarkup","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":34}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"info\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"SyncCenter.AllEntitiesSynced",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":27,"column":33},"end":{"line":27,"column":72}}}))
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sync-center\">\r\n    <div class=\"regular-content\">\r\n        <div class=\"clearfix sync-center-header\">\r\n            <button type=\"button\" class=\"btn btn-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":97}}})) != null ? stack1 : "")
    + " raised"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SynchronisationInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":104},"end":{"line":4,"column":153}}})) != null ? stack1 : "")
    + "\" data-action=\"start-sync\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SynchronisationInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "            </button>\r\n\r\n            <button type=\"button\" class=\"btn btn-default pull-right\" data-action=\"create-support-mail\">\r\n                <i class=\"icon-mail\"></i>\r\n            </button>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n        <ul class=\"unsynced-entities"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":36},"end":{"line":23,"column":79}}})) != null ? stack1 : "")
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EntityMarkup") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </div>\r\n\r\n    <div class=\"http-error hidden\">\r\n        <p class=\"error-text\"></p>\r\n        <p class=\"url\"></p>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Tag"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " read-only";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"remove-tag\">X</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tag"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ReadOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":48}}})) != null ? stack1 : "")
    + "\" data-id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":65}}}) : helper)))
    + "\">\r\n    <div class=\"text\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"LangKey") : depth0),{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":38}}}))
    + "</div> "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ReadOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":107}}})) != null ? stack1 : "")
    + "\r\n</div>";
},"useData":true});

this["Templates"]["Toast"]["Default"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div id=\"toaster\"></div>";
},"useData":true});

this["Templates"]["Tree"]["HideInformation"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide-info\">\r\n    <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.IsBeingHidden",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":52}}}))
    + "</span>\r\n</div>";
},"useData":true});

this["Templates"]["Tree"]["Item"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DOMClasses") || (depth0 != null ? lookupProperty(depth0,"DOMClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DOMClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":49}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " height: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Height") || (depth0 != null ? lookupProperty(depth0,"Height") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Height","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":65}}}) : helper)))
    + "px";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-identifier=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Identifier") : stack1), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-href=\"#"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Href") || (depth0 != null ? lookupProperty(depth0,"Href") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Href","hash":{},"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":37}}}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DOMStyles") || (depth0 != null ? lookupProperty(depth0,"DOMStyles") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DOMStyles","hash":{},"data":data,"loc":{"start":{"line":10,"column":37},"end":{"line":10,"column":50}}}) : helper)))
    + ";\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"HighlightedTitle") || (depth0 != null ? lookupProperty(depth0,"HighlightedTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"HighlightedTitle","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":42}}}) : helper))) != null ? stack1 : "")
    + "\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"DisplayTitle") : stack1), depth0))
    + "\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Counter") || (depth0 != null ? lookupProperty(depth0,"Counter") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Counter","hash":{},"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":42}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DOMClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":56}}})) != null ? stack1 : "")
    + "\"\r\n    data-title=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "\"\r\n    style=\"top: "
    + alias3(((helper = (helper = lookupProperty(helpers,"OffsetTop") || (depth0 != null ? lookupProperty(depth0,"OffsetTop") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"OffsetTop","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":29}}}) : helper)))
    + "px;"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Height") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":74}}})) != null ? stack1 : "")
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Identifier") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":72}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Href") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":45}}})) != null ? stack1 : "")
    + "\r\n>\r\n    <div class=\"wrapper\">\r\n        <div\r\n            class=\"item-content\"\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DOMStyles") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":59}}})) != null ? stack1 : "")
    + "\r\n        >\r\n\r\n            <span class=\"open-close\"></span>\r\n            <span class=\"color\" style=\"background-color: "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Color") : stack1), depth0))
    + "\"></span>\r\n            <div class=\"title\">\r\n                <span class=\"main\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"HighlightedTitle") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "                </span>\r\n\r\n                <div class=\"small parent-name\">@ "
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Parent") : stack1)) != null ? lookupProperty(stack1,"DisplayTitle") : stack1), depth0))
    + "</div>\r\n                <span class=\"additional-text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"AdditionalText") || (depth0 != null ? lookupProperty(depth0,"AdditionalText") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"AdditionalText","hash":{},"data":data,"loc":{"start":{"line":25,"column":46},"end":{"line":25,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</span>\r\n            </div>\r\n\r\n\r\n            <div class=\"badge counter\">\r\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Counter") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":49}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});

this["Templates"]["Tree"]["NoResults"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"item info\">\r\n    <div class=\"wrapper\">\r\n        <div class=\"item-content\">\r\n            <span class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Misc.NoResultsFound",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":62}}}))
    + "</span>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["Tree"]["SkeletonEnd"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"useData":true});

this["Templates"]["Tree"]["SkeletonStart"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " enable-checkmarks";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tree"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EnableCheckmarks") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":65}}})) != null ? stack1 : "")
    + "\" style=\"height: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Height") || (depth0 != null ? lookupProperty(depth0,"Height") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"Height","hash":{},"data":data,"loc":{"start":{"line":1,"column":82},"end":{"line":1,"column":92}}}) : helper)))
    + "px;\">";
},"useData":true});

this["Templates"]["TreeItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-identifier=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Identifier") : stack1), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-revisionoid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"RevisionOID") : stack1), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Classes") || (depth0 != null ? lookupProperty(depth0,"Classes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":38}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " href=\"#"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Href") || (depth0 != null ? lookupProperty(depth0,"Href") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Href","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":34}}}) : helper)))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"Styles") || (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Styles","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":44}}}) : helper)))
    + ";\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"color\" style=\"background-color: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Color") : stack1), depth0))
    + "\"></span>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Parent") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"small\">@ "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Parent") : stack1)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"additional-text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"AdditionalText") || (depth0 != null ? lookupProperty(depth0,"AdditionalText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AdditionalText","hash":{},"data":data,"loc":{"start":{"line":24,"column":46},"end":{"line":24,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "\"\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Identifier") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"RevisionOID") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":75}}})) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"Classes") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":46}}})) != null ? stack1 : "")
    + "\r\n>\r\n    <a"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"Href") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":42}}})) != null ? stack1 : "")
    + ">\r\n        <div\r\n            class=\"item-content\"\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"Styles") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":53}}})) != null ? stack1 : "")
    + "\r\n        >\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"ShowColor") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "\r\n            <span class=\"title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Item") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0))
    + "</span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"ShowParentTitle") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"AdditionalText") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </a>\r\n</li>";
},"useData":true});

this["Templates"]["UserPicker"]["SelectableItemsList"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":52}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " class=\"is-readonly\"";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"DataMode") : depths[1]),"===","users",{"name":"Test","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":15,"column":21}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"UserPickerUnselectedUser"),depth0,{"name":"UserPickerUnselectedUser","hash":{"HideAddButton":(depths[1] != null ? lookupProperty(depths[1],"HideAddButton") : depths[1]),"IsReadonly":(depths[1] != null ? lookupProperty(depths[1],"IsReadonly") : depths[1]),"User":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"UserPickerUnselectedTeam"),depth0,{"name":"UserPickerUnselectedTeam","hash":{"UserSelectionAllowed":(depths[1] != null ? lookupProperty(depths[1],"UserSelectionAllowed") : depths[1]),"HideAddButton":(depths[1] != null ? lookupProperty(depths[1],"HideAddButton") : depths[1]),"IsReadonly":(depths[1] != null ? lookupProperty(depths[1],"IsReadonly") : depths[1]),"Team":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.NoDataAvailable",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":7},"end":{"line":19,"column":44}}}))
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Items") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["UserPicker"]["SelectedTeam"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <br /><span class=\"user-count\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " "
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"count":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"Key":"UserPicker.Member"},"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":121}}}))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"remove icon-minus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Remove",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":75}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"teams\" class=\"clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1),">",0,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["UserPicker"]["SelectedUser"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-lock\"></span>";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"remove icon-minus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Remove",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":75}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" data-type=\"users\" class=\"clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1),{"name":"And","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":16}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"IsLocked") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":67}}})) != null ? stack1 : "")
    + "\r\n    </span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["UserPicker"]["TeamMembers"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"divider\">\r\n    <span class=\"btn icon-cheveron-left hide-team-members\" data-team-identifier=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\"></span>\r\n    <strong>"
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"teamTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1),"Key":"UserPicker.MembersOf"},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":68}}}))
    + "</strong>\r\n</div>\r\n\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"UserPickerItemList"),depth0,{"name":"UserPickerItemList","hash":{"DataMode":"users","IsReadonly":(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),"Items":(depth0 != null ? lookupProperty(depth0,"Items") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["Templates"]["UserPicker"]["UnselectedTeam"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <br /><span class=\"user-count\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " "
    + alias1((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Lang","hash":{"count":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"Key":"UserPicker.Member"},"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":121}}}))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"show-team-members icon-group\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.ShowTeamMembers",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":58},"end":{"line":12,"column":95}}}))
    + "\"></span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"add icon-plus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Add",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":43},"end":{"line":16,"column":68}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"Team") : depth0)) != null ? lookupProperty(stack1,"Users") : stack1),">",0,{"name":"Test","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "    </span>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"UserSelectionAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"HideAddButton") : depth0),{"name":"NOr","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":17,"column":12}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["UserPicker"]["UnselectedUser"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Title") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-lock\"></span>";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"add icon-plus\" title=\""
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"UserPicker.Add",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":68}}}))
    + "\"></span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-oid=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"OID") : stack1), depth0))
    + "\" class=\"clearfix\">\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"RoundedImage"),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"ImagePath") : stack1),{"name":"RoundedImage","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span>\r\n"
    + ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Firstname") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"Lastname") : stack1),{"name":"And","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":16}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"IsLocked") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":67}}})) != null ? stack1 : "")
    + "\r\n    </span>\r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),(depth0 != null ? lookupProperty(depth0,"HideAddButton") : depth0),{"name":"NOr","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":12}}})) != null ? stack1 : "")
    + "</li>";
},"usePartial":true,"useData":true});

this["Templates"]["UserPicker"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"3":function(container,depth0,helpers,partials,data) {
    return "border-top: none;";
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"is-readonly\"";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ItemType") : depth0),"===","users",{"name":"Test","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":31,"column":29}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"UserPickerSelectedUser"),depth0,{"name":"UserPickerSelectedUser","hash":{"IsReadonly":(depths[1] != null ? lookupProperty(depths[1],"IsReadonly") : depths[1]),"User":depth0},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"UserPickerSelectedTeam"),depth0,{"name":"UserPickerSelectedTeam","hash":{"IsReadonly":(depths[1] != null ? lookupProperty(depths[1],"IsReadonly") : depths[1]),"Team":depth0},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"And")||(depth0 && lookupProperty(depth0,"And"))||alias2).call(alias1,(lookupProperty(helpers,"IfAPIVersionIsGreaterOrEqual")||(depth0 && lookupProperty(depth0,"IfAPIVersionIsGreaterOrEqual"))||alias2).call(alias1,20,{"name":"IfAPIVersionIsGreaterOrEqual","hash":{},"data":data,"loc":{"start":{"line":39,"column":15},"end":{"line":39,"column":48}}}),(lookupProperty(helpers,"UserHasRight")||(depth0 && lookupProperty(depth0,"UserHasRight"))||alias2).call(alias1,"REC-DELETE",true,{"name":"UserHasRight","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":81}}}),{"name":"And","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":41,"column":16}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-danger btn-delete flat\"><span class=\"icon-bin2\"></span></button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal container user-picker\">\r\n    <div class=\"window-header\">\r\n        <ul class=\"header-navigation clearfix\">\r\n            <li data-tab=\"users\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.User_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":40},"end":{"line":4,"column":67}}}))
    + "\"></li>\r\n            <li data-tab=\"teams\" title=\""
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Team_plural",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":67}}}))
    + "\"></li>\r\n            <li data-action=\"search\"></li>\r\n            <li disabled=\"disabled\"></li>\r\n        </ul>\r\n    </div>\r\n\r\n    <div class=\"search hidden\"></div>\r\n\r\n    <div class=\"content\">\r\n        <div class=\"unselected\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"SelectableItemsMarkup") || (depth0 != null ? lookupProperty(depth0,"SelectableItemsMarkup") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"SelectableItemsMarkup","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":39}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n        <div class=\"divider"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectedItems") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":27},"end":{"line":17,"column":70}}})) != null ? stack1 : "")
    + "\" style=\""
    + ((stack1 = (lookupProperty(helpers,"Test")||(depth0 && lookupProperty(depth0,"Test"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectableItemsCount") : depth0),">",0,{"name":"Test","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":79},"end":{"line":17,"column":141}}})) != null ? stack1 : "")
    + "\">\r\n            <strong>"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"UserPicker.CurrentSelection",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":58}}}))
    + "</strong>\r\n        </div>\r\n        <div class=\"selected"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectedItems") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":71}}})) != null ? stack1 : "")
    + "\">\r\n            <ul"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":15},"end":{"line":21,"column":60}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"SelectedItems") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":32,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"footer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ShowDeleteButton") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + "        <button type=\"button\" class=\"btn btn-danger btn-abort flat hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":75},"end":{"line":43,"column":96}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn btn-success btn-apply flat hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":44,"column":76},"end":{"line":44,"column":96}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn btn-default btn-close flat\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":45,"column":69},"end":{"line":45,"column":90}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n";
},"usePartial":true,"useData":true,"useDepths":true});

this["Templates"]["VoiceRecorder"]["Window"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"recording-information\">"
    + container.escapeExpression((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"VoiceRecorder.NoRecording",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":81}}}))
    + "</p>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <audio controls preload=\"auto\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultResourcePath") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AlternativeResourcePath") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "            </audio>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <source src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DefaultResourcePath") || (depth0 != null ? lookupProperty(depth0,"DefaultResourcePath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DefaultResourcePath","hash":{},"data":data,"loc":{"start":{"line":14,"column":33},"end":{"line":14,"column":56}}}) : helper)))
    + "\" type=\"audio/mp4\" />\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <source src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"AlternativeResourcePath") || (depth0 != null ? lookupProperty(depth0,"AlternativeResourcePath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AlternativeResourcePath","hash":{},"data":data,"loc":{"start":{"line":18,"column":33},"end":{"line":18,"column":60}}}) : helper)))
    + "\" type=\"audio/ogg\" />\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <button type=\"button\" class=\"btn flat btn-default btn-start-recording icon-fiber_manual_record\"></button>\r\n            <button type=\"button\" class=\"btn flat btn-default btn-stop-playback icon-stop2\" disabled=\"disabled\"></button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-material voice-recorder\">\r\n    <div class=\"header\">\r\n        "
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"VoiceRecorder.Title",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":38}}}))
    + "\r\n    </div>\r\n\r\n    <div class=\"main-content\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultResourcePath") : depth0),(depth0 != null ? lookupProperty(depth0,"AlternativeResourcePath") : depth0),{"name":"NOr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":16}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"Or")||(depth0 && lookupProperty(depth0,"Or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultResourcePath") : depth0),(depth0 != null ? lookupProperty(depth0,"AlternativeResourcePath") : depth0),{"name":"Or","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = (lookupProperty(helpers,"NOr")||(depth0 && lookupProperty(depth0,"NOr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"DefaultResourcePath") : depth0),(depth0 != null ? lookupProperty(depth0,"AlternativeResourcePath") : depth0),{"name":"NOr","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":26,"column":16}}})) != null ? stack1 : "")
    + "    </div>\r\n\r\n    <div class=\"footer\">\r\n        <button type=\"button\" class=\"btn flat btn-danger btn-abort hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Abort",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":30,"column":75},"end":{"line":30,"column":96}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-success btn-ok hidden\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Okay",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":31,"column":73},"end":{"line":31,"column":93}}}))
    + "</button>\r\n        <button type=\"button\" class=\"btn flat btn-default btn-close\">"
    + alias3((lookupProperty(helpers,"Lang")||(depth0 && lookupProperty(depth0,"Lang"))||alias2).call(alias1,"Misc.Close",{"name":"Lang","hash":{},"data":data,"loc":{"start":{"line":32,"column":69},"end":{"line":32,"column":90}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});

this["Templates"]["WarnMessage"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-layout\">\r\n    <img src=\"./img/"
    + alias4(((helper = (helper = lookupProperty(helpers,"Image") || (depth0 != null ? lookupProperty(depth0,"Image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Image","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":29}}}) : helper)))
    + "\" class=\"alert-icon\" />\r\n    <div class=\"text-wrapper\">\r\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"Message") || (depth0 != null ? lookupProperty(depth0,"Message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Message","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":19}}}) : helper)))
    + "\r\n    </div>\r\n</div>\r\n";
},"useData":true});